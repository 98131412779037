<div class="page-container">
  <div class="inner-page-container">
    <div class="page-loader" *ngIf="_IsProgressSpinner">
      <p-progressSpinner strokeWidth="5"></p-progressSpinner>
    </div>
    <div class="white">
      <div class="page-title">
        <div class="row">
          <div class="col-md-5">
            <h3>Customer Consignment Branch</h3>
          </div>
          <div class="col-md-7 text-right">
            <div class="action-btn">
              <button
                pButton
                pRiple
                label=""
                icon="pi pi-plus"
                title="Add"
                class="p-button p-button-success p-mr-2"
                [routerLink]="['/cCustomerbranchmaster']"
              ></button>
            </div>
          </div>
        </div>
      </div>
      <div class="form-container scroll-y">
        <!-- <p-toast position="bottom-right"></p-toast> -->
        <div class="card">
          <p-toolbar styleClass="p-mb-4">
            <ng-template pTemplate="left">
              <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input
                  pInputText
                  type="text"
                  (input)="dt.filterGlobal($event.target.value, 'contains')"
                  placeholder="Search..."
                />
              </span>
            </ng-template>
            <ng-template pTemplate="right">
              <button
                type="button"
                pButton
                pRipple
                icon="pi pi-file-excel"
                title="Export Excel"
                class="p-button-success p-mr-2"
                pTooltip="XLS"
                tooltipPosition="bottom"
                (click)="exportExcel()"
              ></button>
              <button
                type="button"
                pButton
                pRipple
                icon="pi pi-file-pdf"
                title="Export PDF"
                class="p-button-warning p-mr-2"
                pTooltip="PDF"
                tooltipPosition="bottom"
                (click)="ExportToPdf()"
              ></button>
            </ng-template>
          </p-toolbar>
          <p-table
            #dt
            [value]="_Customerbranchview"
            dataKey="branchname"
            [rows]="10"
            [paginator]="true"
            [rowsPerPageOptions]="[10, 25, 50, 100]"
            [rowHover]="true"
            [globalFilterFields]="['customerbranchcode','customerbranchname','customerbranchstatus','username','modifiedby']"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [showCurrentPageReport]="true"
          >
            <ng-template pTemplate="header">
              <tr>
                <th class="text-center" style="width: 120px">Action</th>
                <th pSortableColumn="customerbranchcode">
                  Branch Code
                  <p-sortIcon field="customerbranchcode"></p-sortIcon>
                </th>
                <th pSortableColumn="customerbranchname">
                  Branch Name
                  <p-sortIcon field="customerbranchname"></p-sortIcon>
                </th>
                <th pSortableColumn="customerbranchstatus">
                  Branch Status
                  <p-sortIcon field="customerbranchstatus"> </p-sortIcon>
                </th>
                <th pSortableColumn="username">
                  Created By
                  <p-sortIcon field="username"> </p-sortIcon>
                </th>
                <th pSortableColumn="createdon">
                  Created On
                  <p-sortIcon field="customerbranchstatus"> </p-sortIcon>
                </th>
                <th pSortableColumn="modifiedby">
                  Modified By
                  <p-sortIcon field="modifiedby"> </p-sortIcon>
                </th>
                <th pSortableColumn="modifiedon">
                  Modified On
                  <p-sortIcon field="modifiedon"> </p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-_Customerbranchviews>
              <tr
                [ngClass]="{
                  'qs-row':
                    _Customerbranchviews.customerbranchstatus === 'Inactive'
                }"
              >
                <td class="text-center">
                  <button
                    pButton
                    pRipple
                    icon="las la-eye"
                    title="View"
                    class="p-button p-mr-2"
                    (click)="view(_Customerbranchviews)"
                  ></button>
                  <button
                    pButton
                    pRipple
                    icon="las la-pen"
                    title="Edit"
                    class="p-button p-button-success p-mr-2"
                    (click)="edit(_Customerbranchviews)"
                  ></button>
                  <button
                    pButton
                    pRipple
                    icon="pi pi-times"
                    title="Delete"
                    class="p-button p-button-danger"
                    (click)="Cancel(_Customerbranchviews)"
                  ></button>
                </td>
                <td>
                  {{ _Customerbranchviews.customerbranchcode }}
                </td>
                <td>
                  {{ _Customerbranchviews.customerbranchname }}
                </td>
                <td>
                  {{ _Customerbranchviews.customerbranchstatus }}
                </td>
                <td>
                  {{ _Customerbranchviews.createdby }}
                </td>
                <td>
                  {{ _Customerbranchviews.createdon | date : "dd/MM/yyyy" }}
                </td>
                <td>
                  {{ _Customerbranchviews.modifiedby }}
                </td>
                <td>
                  {{ _Customerbranchviews.modifiedon | date : "dd/MM/yyyy" }}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
      </div>
    </div>
  </div>
</div>
