import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Branch } from 'src/app/core/Models/Branch';
import { Currency } from 'src/app/core/Models/Currency';
import { Customer } from 'src/app/core/Models/Customer';
import { Employee } from 'src/app/core/Models/Employee';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Productheader } from 'src/app/core/Models/Productheader';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Productvariant } from 'src/app/core/Models/Productvariant';
import { Salesgetreferencenoview } from 'src/app/core/Models/Salesgetreferencenoview';
import { Salesinvoice } from 'src/app/core/Models/Salesinvoicepos';
import { Salesinvoicedetail } from 'src/app/core/Models/Salesinvoiceposdetail';
import { Salesproductpriceview } from 'src/app/core/Models/Salesproductpriceview';
import { SalesinvoiceposService } from 'src/app/core/Services/sales/salesinvoicepos.service';
import { vProductTypeSelect, vProductCategorySelect, vProductNameSelect, vQuantity, vCustomerNameSelect, vInvoiceDateSelect, vDeliveryModeSelect, vSelectSupplier, vPaymentModeSelect, vAddress, vCustomerName, vEmailID, vMobileNumber } from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import * as ace from "ace-builds";
import { Salesinvoiceproductpriceview } from 'src/app/core/Views/Salesinvoiceproductpriceview ';
import { Creditnote } from 'src/app/core/Models/Creditnote';
import { Salescustomerdetailsview } from 'src/app/core/Views/Salescustomerdetailsview';
import { Level } from 'src/app/core/Models/Level';
import { Accountledger } from 'src/app/core/Models/Accountledger';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { DatePipe } from '@angular/common';
import { Salesinvoicecreditdetail } from 'src/app/core/Models/salesinvoicecreditnotedetail';
import { ConfirmationService } from 'primeng/api';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { HotKeyIntegration } from 'src/assets/js/hot-key-integration';
import { KeyPress } from 'src/assets/js/KeyPress';
import { Productoverallbarcodeview } from 'src/app/core/Views/Productoverallbarcodeview';
import { SalesinvoicetaxService } from 'src/app/core/Services/sales/salesinvoicetax.service';
import { Tax } from 'src/app/core/Models/Tax';
import { HostListener } from '@angular/core';
import { Creditnoteview } from 'src/app/core/Views/Creditnoteview';
import { Observable, Subject } from 'rxjs';
import { every, max, takeUntil } from 'rxjs/operators';
import { Reward } from 'src/app/core/Models/Reward';
import { Salesinvoiceview } from 'src/app/core/Views/Salesinvoiceposview';
import { Receiptadvancereferenceview } from 'src/app/core/Views/Receiptadvancereferenceview';
import { Salesinvoiceadvancereference } from 'src/app/core/Models/Salesinvoiceadvancereference';
import { Salesinvoiceothercharge } from 'src/app/core/Models/Salesinvoiceothercharge';
import { Othercharge } from 'src/app/core/Models/Othercharges';
import { element } from 'protractor';
import { Console } from 'console';
import { Salesinvoicerewarddetail } from 'src/app/core/Models/SalesInvoiceRewardDetails';
import * as moment from 'moment';
import { Customermobiledetails } from 'src/app/core/Models/Customermobiledetails';

declare var $: any;

@Component({
  selector: 'app-c-salesinvoicepos',
  templateUrl: './c-salesinvoicepos.component.html',
  styleUrls: ['./c-salesinvoicepos.component.css']
})
export class CSalesinvoiceposComponent implements OnInit, CanDeactivate<CSalesinvoiceposComponent>, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  isSaveClicked: boolean = false;
  isHideCancelRequestButton: boolean = true;
  creditnotemodel: any = {};
  tempcreditnotebalanceamount: number = 0;
  paymentModel: any = {};
  _copyinvoiceno: string;
  _customertype: Metadatum[] = [];
  // pos dialog template start
  _advancereferences: Receiptadvancereferenceview[] = [];
  _advances: any[] = [];
  _salesinvoiceadvancereferences: Salesinvoiceadvancereference[] = [];
  posDialogNgTemplateVisible: boolean = true; // by hidden default
  isGobackdisabled: boolean = false;
  // pos dialog template end
  _ReturnCurrencies: Currency[];
  _customersubmitted: boolean = false;
  ManageDialog: boolean;
  _customerform: FormGroup;
  _salesinvoiceposform: FormGroup;
  filteredProducts: any[] = [];
  _salesproductform: FormGroup;
  _action: string = "create";
  unitprice: any = 0;
  selectedrows: any;
  selectedProductDetailsRows: any
  _selectedlevel: number;
  _customer: Salescustomerdetailsview;
  _Salesinvoicepos: Salesinvoice;
  _ProductDetails: Salesinvoiceproductpriceview[] = [];
  _productdetail: Salesinvoicedetail;
  _Customernames: Customer[];
  _Customername: Customer;
  _Salesreferences: Metadatum[];
  _SalesReferenceno: Salesgetreferencenoview[];
  _Currencies: Currency[];
  _CurrenciesTemp: Currency[];
  _Collectingbranch: Branch;
  taxes: Tax[] = [];
  _Deliverymode: Metadatum;
  _ProductTypes: Producttype[] = [];
  _ProductCategories: Productcategory[] = [];
  _ProductNames: Productoverallview[];
  _ProductNamesTemp: Productoverallview[];
  _productBarcodesearch: Productoverallbarcodeview[];
  _Variants: Productvariant[];
  _SalesInvoiceNumbers: Creditnote[];
  _CreditNoteRefernces: Creditnoteview[] = [];
  _Salesby: Employee
  _PaymentModes: Metadatum[] = [];
  _TempPaymentModes: Metadatum[] = [];
  _salesinvoiceid: number;
  _userid = sessionStorage["userid"];
  _username = sessionStorage["username"];
  _series = sessionStorage["Series"];
  _branchid = sessionStorage["currentbranchid"]
  _selectedproduct: number;
  _levelid = sessionStorage["levelid"];
  _selectedcustomer: number;
  _selectedcurrency: string;
  _UnitPrice: number;
  _selectedReferenceType: string;
  isDisabledinvoiceslip: boolean = true;
  isDisabledprint: boolean = true;
  isDisabledsendtoapproval: boolean = true;
  _Markuplevels: Level[];
  _toaccountsTemp: Accountledger[];
  _toaccounts: Accountledger[];
  _IsProgressSpinner: boolean = false;
  _submitted = false;
  _submitted1 = false;
  _SalesinvoiceposObj: any;
  productcatid: any[] = [];
  producttypeid: any[] = [];
  _SelectedVariant: number;
  _Severities: Metadatum[];
  _isDisabledseverity: true;
  isDisabledsave = false;
  isDisabledClear = false;
  _creditnotedetail: Salesinvoicecreditdetail[] = [];
  _salesinvoiceno: string;
  series: string;
  _Action: IAction;
  _OtherCurrencies
  _OtherCurrency
  _othercurrencies: Currency;
  selectedOtherCurrenciesRows: any;
  _Multicurrencies1: Currency[];
  _Multicurrencies2: Currency[];
  _quotationresult: any;
  excludeVatType = 'MSC00063';
  _branchresult: any
  _selectedProduct: any
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  showpprofit: boolean = true;
  reward: Reward = null;
  _creditnoteids: number[] = [];
  _isDeliveryRequired: boolean = false;
  _OtherCharges: Salesinvoiceothercharge[] = [];
  _Descriptions: Othercharge;
  _ChargesTemp: Othercharge[];
  _Branch: any;
  otherchargeid: any;
  filteredcustomer: any;
  event: Event;
  customer: any;
  displayBasicProductType: boolean;
  dispalyBasicScheme: boolean;
  Salesinvoiceresultjson: any;
  salesproductprice: any
  schemeRemarks: any[] = []
  _isDisabledpoints: boolean = true
  _RemainingPoints: number;
  _IsDisabledRewardPoints: boolean = false;
  _finalrewardamount: number
  _IsdisabledAdvanceRef: boolean = false;
  SalesinvoiceRewardDetailslist: Salesinvoicerewarddetail[] = [];
  dispalyBasicReward: boolean;
  earnedrewarddetails: any[] = []
  usedrewarddetails: any[] = [];
  totalearningpoints: number = 0;
  totalusedpoints: number = 0;
  totalremainingpoints: number = 0;
  IsDisabledLayBuy: boolean = false;
  //For Currency based payment:
  _TempCreditNoteRefernces: Creditnoteview[] = [];
  _Tempadvancereferences: Receiptadvancereferenceview[] = [];
  _Customermobilenumberlist: Customermobiledetails[] = [];
  mobileno: string;
  IsDisableRequestDiscount: boolean = false;
  hidequantity: boolean = false;
  _TempLayBuyadvancereferences: Receiptadvancereferenceview[] = [];
  _Tempbranch: Branch[] = [];
  _TempCustomernames: Customer[];
  _packtype: string = "MSC00365";
  _packdetails: Metadatum[] = [];


  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;

  constructor(private utility: CommonUtilities, private fb: FormBuilder, private _router: Router, private _CustomExceptionService: CustomExceptionService,
    private _SalesinvoiceposService: SalesinvoiceposService, private confirmationService: ConfirmationService, private _AccessRightsService: AccessRightsService,
    private _hotkeysService: HotKeyIntegration<void>, public keypress: KeyPress,
    private _SalesInvoiceTaxService: SalesinvoicetaxService) {
  }

  canDeactivate(component: CSalesinvoiceposComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    throw new Error('Method not implemented.');
  }

  InitializeForm() {
    this._salesinvoiceposform = this.fb.group({
      companyid: [0],
      branchid: [],
      salesinvoiceid: [0],
      salesinvoiceno: [],
      salesinvoicedate: ['', vInvoiceDateSelect],
      salesreference: [],
      salesreferenceno: [],
      deliveryrequired: [0],
      customerid: ['', vCustomerNameSelect],
      currencyreferenceid: [],
      currencyid: [],
      markuplevelid: [],
      salesby: ['', vSelectSupplier],
      applicablerewardpoints: [],
      creditnoteno: [],
      creditnoteamount: [],
      laybuyno: [],
      laybuyamount: [],
      productamount: [],
      taxamount: [],
      discountamount: [],
      otherchargesamount: [],
      advanceamount: [],
      salesinvoiceamount: [],
      paidamount: [0],
      balanceamount: [],
      purchaseamount: [],
      profitamount: [],
      amountinusd: [],
      paymenttype: [''],
      paymentreferenceno: [],
      status: [],
      statusremarks: [],
      accountingyear: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      ispossales: [1],
      rv: [],
      voucherno: [],
      mobileno: [],
      mailid: [],
      collectingbranch: [],
      applyrewardpoints: [false],
      remainingpoints: [],
      creditnoteapplicable: [false],
      netamountincustomercurrency: [0],
      creditnoteid: [],
      bankledgerid: [0],
      creditnoteinvoiceno: [],
      deliverymode: [],
      temprewardpoints: [],
      cuscurrencyid1: [],
      cuscurrencyamount1: [],
      cuscurrencyid2: [],
      cuscurrencyamount2: [],
      customerbalanceamount: [],
      appliedrewardamount: [],
      returncurrencyid: [],
      returnamount: [],
      currencyname: [],
      ispaymentmaded: [],
      gatheredrewardpoints: [],
      customeradvanceamount: [0],
      creditnotebalanceamount: [0],
      referenceorderno: [],
      eorderno: [],
      isselfpickup: [],
      isquicksales: [false],
      username: [''],
      iscopyinvoice: [false],
      copyinvoiceno: [''],
      otherchargeid: [0],
      vattype: [null],
      specificvattype: [null],
      isprintallowed: [],
      transactionincompleted: [1],
      remarks: [''],
      invoiceconvertto: [null],
      isconsignmentinvoice: [false],
      printcount: [0]
    });

    this.InitializeCustomerForm();
    this.InitializeProductForm();
    this._salesinvoiceposform.controls['salesinvoicedate'].setValue(new Date());
    this._salesinvoiceposform.controls['collectingbranch'].setValue(parseInt(this._branchid));
    this._salesinvoiceposform.controls['salesby'].setValue(parseInt(this._userid));
    this._salesinvoiceposform.controls['paymenttype'].setValue("MSC00146");
    this._salesinvoiceposform.get('applyrewardpoints').disable();
    if (this._Customernames && this._Customernames.length) {
      let userid = sessionStorage["userid"];
      let customer = this._Customernames.find(f => f.userid == userid);
      if (customer) {
        let event = { value: customer.customerid };
        this._salesinvoiceposform.get("customerid").setValue(customer.customerid);
        this.GetCustomer(event);
      }
    }
    if (this._isDeliveryRequired) {
      this._salesinvoiceposform.get("deliveryrequired").setValue(this._isDeliveryRequired);
      this.OnChangeDeliverymode({ checked: true });
    }
  }

  InitializeCustomerForm() {
    this._customerform = this.fb.group({
      customerid: [0],
      customercode: [],
      customername: ['', vCustomerName],
      currencyid: [0],
      mailid: ['', vEmailID],
      mobileno: ['', vMobileNumber],
      address: ['', vAddress],
      createdby: [],
      createdon: [],
      vatno: ['', vCustomerName],
      bpno: [],
      customertype: ['', vCustomerName]
    });
  }

  InitializeProductForm() {
    this._salesproductform = this.fb.group({
      producttypeid: [],
      productcategoryid: [],
      productid: ['', vProductNameSelect],
      variantid: [],
      quantity: [null, [Validators.required]],
      isdamaged: [false],
      remainingstock: [],
      severity: [],
      productbarcode: [''],
      stockinhand: [],
      packtype: [],
      stockqty: []
    });
  }


  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.hidequantity = true;
    this._salesinvoiceno = history.state?.salesinvoiceno ? history.state?.salesinvoiceno : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.series = history.state?.series ? history.state?.series : null;
    this._Action = this._AccessRightsService.getAccessRights(56);
    this._hotkeysService.Save(event => {
      this.onSave();
    }, this._Action).
      Clear(event => { this.reset(event); }).Navigate(event => {
        this.goBack(event);
      }, this._Action, 'left');
    this.checkprofitRights();
    this.InitializeForm();
    const data = sessionStorage.getItem('LedgerData');
    if (data) {
      const parsedData = JSON.parse(data);
      this._salesinvoiceno = parsedData.salesposinvoiceno;
      this._userid = parsedData.userid;
      this._branchid = parsedData.Branchid;
      this._action = parsedData.action;
      sessionStorage.removeItem('LedgerData');
    }
    this.paymentModel.IsPaymentSuccess = true;
    this.paymentModel.IsRetryEnable = true;
    this._IsProgressSpinner = true;
    this._SalesinvoiceposService.PageOnLoad(this._salesinvoiceno, this._userid, this._branchid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._salesinvoiceposform.get("applicablerewardpoints").disable();
      this._salesinvoiceposform.controls['salesinvoicedate'].setValue(new Date());
      this._salesinvoiceposform.controls['collectingbranch'].setValue(parseInt(this._branchid));
      this._salesinvoiceposform.controls['salesby'].setValue(parseInt(this._userid));
      this._salesinvoiceposform.controls['paymenttype'].setValue("MSC00146");
      this._Collectingbranch = resultJSON.collectingbranches;
      this._Tempbranch = resultJSON.collectingbranches;
      this._Currencies = resultJSON.currencies;
      // this._Customernames = resultJSON.customers;
      this._TempCustomernames = resultJSON.customers;
      if (this._TempCustomernames.length) {
        this._Customernames = this._TempCustomernames.filter(f => f.isconsignmentcustomer != true);
      }
      this._Customermobilenumberlist = resultJSON.customermobilenumberlist;
      this._Markuplevels = resultJSON.levels;
      // this._Salesreferences = resultJSON.salesReference;
      let salesreference = resultJSON.salesReference;
      this._Salesreferences = salesreference.filter(s => s.metasubcode != "MSC00361")
      this._Salesby = resultJSON.salesby;
      this._ProductTypes = resultJSON.producttypes;
      //this._PaymentModes = resultJSON.cashtype;
      this._TempPaymentModes = resultJSON.cashtype;
      this._CurrenciesTemp = resultJSON.currencies;
      this._Multicurrencies1 = resultJSON.currencies;
      this._Multicurrencies2 = resultJSON.currencies;
      this._ReturnCurrencies = resultJSON.currencies;
      // this._ProductNames = <Productoverallview[]>resultJSON.productsearch || [];
      // this._ProductNamesTemp = <Productoverallview[]>resultJSON.productsearch || [];
      this._Descriptions = resultJSON.othercharges;
      this._ChargesTemp = resultJSON.othercharges;
      // this._productBarcodesearch = <Productoverallbarcodeview[]>resultJSON.productbarcodesearch || [];
      this._toaccountsTemp = resultJSON.toaccounts;
      this._Deliverymode = resultJSON.deliverymode;
      //this._SalesInvoiceNumbers = resultJSON.creditnoteinvoicenos;
      this._Severities = resultJSON.severities;
      this._salesproductform.controls['isdamaged'].setValue(false);
      this._salesproductform.controls['severity'].disable();
      this._IsProgressSpinner = false;
      this.taxes = resultJSON.taxes
      this.reward = resultJSON.reward;
      this._customertype = resultJSON.customertype || [];
      this._salesinvoiceposform.get("otherchargesamount").disable();
      // this.event = this._username;
      // this.Intfiltercustomer(this.event);

      if (this._Customernames && this._Customernames.length) {
        let userid = sessionStorage["userid"];
        let customer = this._Customernames.find(f => f.userid == userid);
        if (customer && this._action == 'create') {
          let event = { value: customer.customerid };
          this._salesinvoiceposform.get("customerid").setValue(customer.customerid);
          this.GetCustomer(event);
          $("input[name='productbarcode']").focus();
        }
      }
      this._isDeliveryRequired = resultJSON.isdeliveryrequired;
      if (this._isDeliveryRequired) {
        this._salesinvoiceposform.get("deliveryrequired").setValue(this._isDeliveryRequired);
        this.OnChangeDeliverymode({ checked: true });
      }

      if (this._action != 'create') {

        this._PaymentModes = resultJSON.cashtype;
        if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
          resultJSON.salesinvoiceview.salesinvoicedate = new Date(resultJSON.salesinvoiceview.salesinvoicedate);
          delete resultJSON.salesinvoiceview.customername;
          delete resultJSON.salesinvoiceview.statusname;
          delete resultJSON.salesinvoiceview.series;
          delete resultJSON.salesinvoiceview.ispaymentmadedtext;
          //delete resultJSON.salesinvoiceview.username;
          this._toaccounts = resultJSON.toaccounts;
          this._ProductDetails = resultJSON.salesinvoiceproductspriceview;
          this._salesinvoiceposform.setValue(resultJSON.salesinvoiceview);
          for (var i = 0; i < this._ProductDetails.length; i++) {
            this._ProductDetails[i].productamount = this._ProductDetails[i].quantity * this._ProductDetails[i].unitprice;
          }
          this._CreditNoteRefernces = resultJSON.creditnotereferencenos || [];
          this._creditnotedetail = resultJSON.salesinvoicecreditdetails || [];
          this._creditnoteids = [];
          this._CreditNoteRefernces.forEach(element => {
            this._creditnoteids.push(element.creditnoteid);
          });

          this._advancereferences = resultJSON.receiptadvancereferenceviewlist || [];
          this._advances = (resultJSON.receiptadvancereferenceselectedlist || []).map(m => m.refNo);
          this._salesinvoiceadvancereferences = resultJSON.receiptadvancereferenceselectedlist || [];
          this._OtherCharges = resultJSON.salesinvoiceothercharges;
          if (this._OtherCharges.length > 0) {
            this.otherchargeid = this._OtherCharges[0].otherchargesid;
          }
          this.Multicurrencycalculation();
        }
        else {
          this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
        }

        if (this._action != 'create') {
          this._salesinvoiceposform.disable();
          this._salesproductform.disable();
          this.isDisabledsave = false;
          this.isDisabledClear = true;
          this._IsProgressSpinner = false;
        }
        if (this._action == 'view') {
          this.isSaveClicked = true;
          this.isDisabledsave = true;
        }
      }
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  ValidateCurrency() {
    let iserror = false;
    let usernetamount = parseFloat(this._salesinvoiceposform.get("netamountincustomercurrency").value || 0);
    let returncurrencyid = parseFloat(this._salesinvoiceposform.get("returncurrencyid").value || 0);
    let invoiceamt = parseFloat(this._salesinvoiceposform.get("salesinvoiceamount").value || 0);
    let creditnoteamount = parseFloat(this._salesinvoiceposform.get("creditnoteamount").value || 0);


    let usercurrencyamount = this.GetUserAmount1();

    if (usercurrencyamount < usernetamount) {
      iserror = true;
      this._CustomExceptionService.handleWarning("Paid Amount must be greater than bill amount.");
    }
    else if (usercurrencyamount > usernetamount && !returncurrencyid) {
      iserror = true;
      this._CustomExceptionService.handleWarning("Please choose return currency.");
    }
    else if (creditnoteamount > invoiceamt && !returncurrencyid) {
      iserror = true;
      this._CustomExceptionService.handleWarning("Please choose return currency.");
    }

    return iserror;
  }

  GetExchangeRate(currencyid: number) {
    let currency = this._Currencies.find(f => f.currencyid == currencyid);
    if (currency)
      return this._Currencies.find(f => f.currencyid == currencyid).exchangerate
    else
      return 1
  }


  OnUserPreferredCurrencyChange() {
    let currency = parseInt(this._salesinvoiceposform.get("currencyid").value || 0);
    this._ProductDetails = [];
    this._salesinvoiceposform.get("returncurrencyid").setValue(currency);
    if (currency == 4) {
      this._salesinvoiceposform.get("cuscurrencyid1").disable();
      this._salesinvoiceposform.get("cuscurrencyamount1").disable();
      this._salesinvoiceposform.get("cuscurrencyid2").disable();
      this._salesinvoiceposform.get("cuscurrencyamount2").disable();
      this._salesinvoiceposform.get("returncurrencyid").disable();
      this._PaymentModes = this._TempPaymentModes.filter(f => f.metasubid != 146);
      this._salesinvoiceposform.get("paymenttype").setValue("MSC00148");
    }
    else {
      //this._salesinvoiceposform.get("cuscurrencyid1").enable();
      this._salesinvoiceposform.get("cuscurrencyamount1").enable();
      this._salesinvoiceposform.get("cuscurrencyid2").enable();
      this._salesinvoiceposform.get("cuscurrencyamount2").enable();
      this._salesinvoiceposform.get("returncurrencyid").enable();

      var cusid = this._salesinvoiceposform.get("customerid").value
      let cus = (this._Customernames || []).find(f => f.customerid == cusid);

      if (currency == 1 && cus.series == "MSC00093") {  // changes for USD currecny based bank and upi will be loading.
        this._PaymentModes = this._TempPaymentModes.filter(f => f.metasubid == 146 || f.metasubid == 148 || f.metasubid == 149);
      }
      else {
        this._PaymentModes = this._TempPaymentModes.filter(f => f.metasubid == 146);
      }

      //this._PaymentModes = this._TempPaymentModes.filter(f => f.metasubid == 146);

      this._salesinvoiceposform.get("paymenttype").setValue("MSC00146");
    }
    this._salesinvoiceposform.get("cuscurrencyid2").reset();
    this._salesinvoiceposform.get("cuscurrencyamount1").reset();
    this._salesinvoiceposform.get("cuscurrencyamount2").reset();
    this.CurrencyBasedRef();
    this.OnChangePaymode();
    this.Multicurrencycalculation();
    this.OnChangeCreditNote();
    this.OnChangeAdvanceReferences();
    this._salesproductform.reset();
    this.InitializeProductForm();
    this._salesinvoiceposform.controls['cuscurrencyid1'].setValue(currency);
  }


  checkprofitRights() {
    if (!this._Action._Profit) {
      this.showpprofit = true;
    } else {
      this.showpprofit = false;
    }
  }
  GetCustomer(event): void {
    this._IsProgressSpinner = true;

    var selectedcustomer = event.value;

    if (!selectedcustomer)
      return;
    this._selectedcustomer = selectedcustomer;
    this._advancereferences = [];
    this._advances = [];
    this._salesinvoiceposform.get("creditnoteinvoiceno").reset();
    this._salesinvoiceposform.controls["creditnoteapplicable"].setValue(false);
    this._creditnoteids = [];
    this._CreditNoteRefernces = [];
    this._TempCreditNoteRefernces = [];
    this._Tempadvancereferences = [];
    this._SalesInvoiceNumbers = [];
    this._Currencies = this._Multicurrencies1 = this._Multicurrencies2 = this._ReturnCurrencies = [];
    this._IsProgressSpinner = true;
    this._SalesinvoiceposService.GetCustomerDetails(selectedcustomer, "Normal").subscribe((result: any) => {
      this._IsProgressSpinner = false;
      this.earnedrewarddetails = []
      this.usedrewarddetails = []
      this.totalearningpoints = 0
      this.totalusedpoints = 0
      this.totalremainingpoints = 0
      const resultJSON = result;
      this.earnedrewarddetails = resultJSON.earnedrewarddetails;
      this.usedrewarddetails = resultJSON.usedrewarddetails;
      this.totalearningpoints = resultJSON.totalearningpoints;
      this.totalusedpoints = resultJSON.totalusedpoints;
      this.totalremainingpoints = resultJSON.totalremainingpoints;
      let cus = (this._Customernames || []).find(f => f.customerid == selectedcustomer);
      this._Currencies = this._Multicurrencies1 = this._Multicurrencies2 = this._ReturnCurrencies = (this._CurrenciesTemp || []);
      if (cus.series == "MSC00094" && this._action != 'view') {
        this._Currencies = (this._CurrenciesTemp || []).filter(f => f.currencyid != 4) || [];
        this._Multicurrencies1 = (this._CurrenciesTemp || []).filter(f => f.currencyid != 4) || [];
        this._Multicurrencies2 = (this._CurrenciesTemp || []).filter(f => f.currencyid != 4) || [];
        this._ReturnCurrencies = (this._CurrenciesTemp || []).filter(f => f.currencyid != 4) || [];
      }
      this._salesinvoiceposform.get("currencyid").enable();
      let customer = <Salescustomerdetailsview>(resultJSON.customerdetails);
      this._customer = customer;
      this._salesinvoiceposform.get("salesreference").reset();
      this._salesinvoiceposform.get("salesreferenceno").reset();
      this._salesinvoiceposform.controls['currencyid'].setValue(customer.currencyid);
      this._salesinvoiceposform.controls['cuscurrencyid1'].setValue(this._customer.currencyid);
      //Paaventhan
      this._salesinvoiceposform.controls['returncurrencyid'].setValue(this._customer.currencyid);
      this._salesinvoiceposform.controls['vattype'].setValue(this._customer.vattype);
      this._salesinvoiceposform.controls['specificvattype'].setValue(this._customer.specificvattype);
      //
      var referenceid = this._Currencies.filter(f => f.currencyid == customer.currencyid)[0].currencyuniquerefid
      this._salesinvoiceposform.controls['currencyreferenceid'].setValue(referenceid);
      this._selectedlevel = customer.levelid;
      this._Markuplevels = resultJSON.levels;
      this._salesinvoiceposform.controls['mobileno'].setValue(customer.mobileno);
      this._salesinvoiceposform.controls['mailid'].setValue(customer.mailid);
      this._salesinvoiceposform.controls['remainingpoints'].setValue(customer.rewardpoints);
      this._RemainingPoints = customer.rewardpoints;
      this._salesinvoiceposform.controls['markuplevelid'].setValue(customer.levelid);
      // this._SalesInvoiceNumbers = resultJSON.creditnoteinvoicenos;
      // this._CreditNoteRefernces = <Creditnoteview[]>resultJSON.creditnotereferenceno;
      // this._TempCreditNoteRefernces = <Creditnoteview[]>resultJSON.creditnotereferenceno;
      this._advancereferences = resultJSON.receiptadvancereferenceviewlist || [];
      this._Tempadvancereferences = resultJSON.receiptadvancereferenceviewlist || [];
      this._TempLayBuyadvancereferences = resultJSON.receiptadvancereferenceviewlist || [];
      //lay buy changes S
      this._advancereferences = this._Tempadvancereferences.filter(f => f.type == 1);
      this._Tempadvancereferences = this._Tempadvancereferences.filter(f => f.type == 1);
      //lay buy changes E

      if (customer.userid > 0) {
        this._IsDisabledRewardPoints = true
        this._salesinvoiceposform.get("applyrewardpoints").disable()
        this._salesinvoiceposform.get("applicablerewardpoints").disable()
        this._salesinvoiceposform.get("remainingpoints").disable()
      } else {
        if (this._selectedlevel == 1 || this._selectedlevel == 2)//RETAIL 1 AND RETAIL 2
        {
          this._IsDisabledRewardPoints = false
          this._salesinvoiceposform.get("applyrewardpoints").enable()
          this._salesinvoiceposform.get("applicablerewardpoints").disable()
          this._salesinvoiceposform.get("remainingpoints").disable()
        }
        else {
          this._IsDisabledRewardPoints = true
          this._salesinvoiceposform.get("applyrewardpoints").disable()
          this._salesinvoiceposform.get("applicablerewardpoints").disable()
          this._salesinvoiceposform.get("remainingpoints").disable()
        }
      }

      $("input[name='productbarcode']").focus();
      this.CurrencyBasedRef();
      this._IsProgressSpinner = false;
      if (this._action != 'view')
        this.OnUserPreferredCurrencyChange();
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  GetCreditNoteDetails() {
    let isApplicable: boolean = <boolean>this._salesinvoiceposform.get("creditnoteapplicable").value;
    if (!isApplicable) {
      return;
    }
    let customerid = this._salesinvoiceposform.get("customerid").value;
    if (!customerid) {
      this._CustomExceptionService.handleError("Please Select Customer");
      return;
    }
    this._TempCreditNoteRefernces = [];
    this._CreditNoteRefernces = [];
    this._SalesInvoiceNumbers = [];
    this._IsProgressSpinner = true;
    this._SalesinvoiceposService.GetCustomerBasedCreditNoteDetails(customerid).subscribe((result: any) => {
      this._IsProgressSpinner = false;
      const resultJSON = result;
      this._SalesInvoiceNumbers = resultJSON.creditnoteinvoicenos;
      this._CreditNoteRefernces = <Creditnoteview[]>resultJSON.creditnotereferenceno;
      this._TempCreditNoteRefernces = <Creditnoteview[]>resultJSON.creditnotereferenceno;
      this.CurrencyBasedRef();
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  //Credit / Advance reference loading based on currency:
  CurrencyBasedRef() {
    this._advancereferences = [];
    this._advances = [];
    this._salesinvoiceposform.get("creditnoteinvoiceno").reset();
    this._creditnoteids = [];
    this._CreditNoteRefernces = [];
    var currencyid = this._salesinvoiceposform.get("currencyid").value;
    this._CreditNoteRefernces = this._TempCreditNoteRefernces.filter(f => f.currentcurrencyid == currencyid) || [];
    this._advancereferences = this._Tempadvancereferences.filter(f => f.currencyid == currencyid) || [];
    this._salesinvoiceposform.get("cuscurrencyid1").disable();
    this._salesinvoiceposform.get("cuscurrencyid2").reset();
  }

  GetReferenceNo(event) {
    this._selectedReferenceType = event.value;
    this._salesinvoiceposform.get("currencyid").enable();
    this._salesinvoiceposform.controls["salesreferenceno"].reset();
    this._ProductDetails = [];
    if (!this._selectedReferenceType) {
      this.Multicurrencycalculation();
      return;
    }
    this._selectedcustomer = this._salesinvoiceposform.get("customerid").value;
    if (!this._selectedcustomer) {
      this._salesinvoiceposform.controls["salesreference"].reset();
      this._CustomExceptionService.handleWarning("Please select customer");
      return;
    }


    this._IsProgressSpinner = true;
    this._SalesinvoiceposService.GetReferenceNo(this._selectedReferenceType, this._userid, this._selectedcustomer).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._SalesReferenceno = resultJSON.referenceno;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  disableLaybuyOptions() {
    // this._salesinvoiceposform.get("paidamount").enable();
    //this._salesinvoiceposform.get("cuscurrencyid1").enable();
    this._salesinvoiceposform.get("cuscurrencyid2").enable();
    // this._salesinvoiceposform.get('customerbalanceamount').enable();
    this._salesinvoiceposform.get('cuscurrencyamount1').enable();
    this._salesinvoiceposform.get('cuscurrencyamount2').enable();

    this._salesinvoiceposform.get("paymenttype").enable();
    this._salesinvoiceposform.get("bankledgerid").enable();
    this._salesinvoiceposform.get("paymentreferenceno").enable();

    this._salesinvoiceposform.get("creditnoteapplicable").enable();

    if (this._selectedlevel == 1 || this._selectedlevel == 2)//RETAIL 1 AND RETAIL 2
    {
      this._salesinvoiceposform.get("applyrewardpoints").enable();
    }
    else {
      this._salesinvoiceposform.get("applyrewardpoints").disable();
    }


    if (this._salesinvoiceposform.get("salesreference").value == "MSC00221") {

      this._salesinvoiceposform.get("cuscurrencyid1").reset();
      this._salesinvoiceposform.get("cuscurrencyid1").disable();

      this._salesinvoiceposform.get("cuscurrencyid2").reset();
      this._salesinvoiceposform.get("cuscurrencyid2").disable();

      this._salesinvoiceposform.get('cuscurrencyamount1').reset();
      this._salesinvoiceposform.get('cuscurrencyamount1').disable();

      this._salesinvoiceposform.get('cuscurrencyamount2').reset();
      this._salesinvoiceposform.get('cuscurrencyamount2').disable();

      this._salesinvoiceposform.get("paymenttype").disable();
      // this._salesinvoiceposform.get("paymenttype").reset();

      this._salesinvoiceposform.get("bankledgerid").disable();
      this._salesinvoiceposform.get("bankledgerid").reset();

      this._salesinvoiceposform.get("paymentreferenceno").disable();
      this._salesinvoiceposform.get("paymentreferenceno").reset();

      this._salesinvoiceposform.get("creditnoteapplicable").reset();
      this._salesinvoiceposform.get("creditnoteapplicable").disable();

      this._salesinvoiceposform.get("applyrewardpoints").reset();
      this._salesinvoiceposform.get("salesinvoiceno").disable();
      this._salesinvoiceposform.get("customerid").disable();
      this._salesinvoiceposform.get("salesinvoicedate").disable();
      this._salesinvoiceposform.get("currencyid").disable();
      this._salesinvoiceposform.get("mobileno").disable();
      this._salesinvoiceposform.get("referenceorderno").disable();
      this._salesinvoiceposform.get("collectingbranch").disable();
      this._salesinvoiceposform.get("salesreference").disable();
      this._salesinvoiceposform.get("salesreferenceno").disable();
      this._salesinvoiceposform.get("customeradvanceamount").disable();
      this._salesinvoiceposform.get("applyrewardpoints").disable();
      this._salesinvoiceposform.get("applicablerewardpoints").disable();
      this._salesinvoiceposform.get("remainingpoints").disable();
      this._salesinvoiceposform.get("creditnoteapplicable").disable();
      this._salesinvoiceposform.get("creditnoteinvoiceno").disable();
      this._salesinvoiceposform.get("creditnoteid").disable();
      this._salesinvoiceposform.get("creditnoteamount").disable();
      this._salesinvoiceposform.get("otherchargesamount").disable();
      this._salesinvoiceposform.get("productamount").disable();
      this._salesinvoiceposform.get("taxamount").disable();
      this._salesinvoiceposform.get("amountinusd").disable();
      this._salesinvoiceposform.get("profitamount").disable();
      this._salesinvoiceposform.get("netamountincustomercurrency").disable();
      this._salesinvoiceposform.get("paidamount").disable();
      this._salesinvoiceposform.get("customerbalanceamount").disable();
      this._salesinvoiceposform.get("balanceamount").disable();
      this._salesinvoiceposform.get("deliveryrequired").disable();
      this._salesinvoiceposform.get("deliverymode").disable();
      this._salesinvoiceposform.get("salesby").disable();
      this._salesinvoiceposform.get("paymenttype").disable();
      this._salesinvoiceposform.get("bankledgerid").disable();
      this._salesinvoiceposform.get("paymentreferenceno").disable();
      this._salesinvoiceposform.get("cuscurrencyid1").disable();
      this._salesinvoiceposform.get("cuscurrencyamount1").disable();
      this._salesinvoiceposform.get("cuscurrencyid2").disable();
      this._salesinvoiceposform.get("cuscurrencyamount2").disable();
      this._salesinvoiceposform.get("returncurrencyid").disable();
      this._salesinvoiceposform.get("returnamount").disable();
      this._salesinvoiceposform.get("creditnotebalanceamount").disable();
      this._salesinvoiceposform.get("returncurrencyid").disable();
      this._salesinvoiceposform.get("cuscurrencyamount2").disable();
      this._salesinvoiceposform.get("paidamount").disable();
      this._salesinvoiceposform.get("cuscurrencyamount2").disable();
      this._salesproductform.get("isdamaged").disable();
      this._salesproductform.get("severity").disable();
      this._salesproductform.get("productbarcode").disable();
      this._salesproductform.get("producttypeid").disable();
      this._salesproductform.get("variantid").disable();
      this._salesproductform.get("quantity").disable();
      this._salesproductform.get("stockinhand").disable();
    }
  }

  OnChangeDamagedProduct() {
    let isApplicable = this._salesproductform.get("isdamaged").value;
    if (isApplicable) {
      this._salesproductform.controls['severity'].enable();
      this._salesproductform.controls['severity'].setValue("MSC00197");
    }
    else {
      this._salesproductform.controls['severity'].reset();
      this._salesproductform.controls['severity'].disable();
    }
    this.GetVariantName();
  }

  OnChangeSeverity() {
    this.GetVariantName();
  }

  GetReferenceDetails(event) {
    var salesreferenceno = event.value;
    this._ProductDetails = [];
    this._salesinvoiceposform.get("currencyid").enable();
    if (!salesreferenceno) {
      this.Multicurrencycalculation();
      return;
    }
    if (this._selectedReferenceType == 'MSC00081') {
      this.IsDisableRequestDiscount = true;
    }
    else {
      this.IsDisableRequestDiscount = false;
    }
    //this._IsProgressSpinner = true;
    this._SalesinvoiceposService.GetReferenceDetails(this._selectedReferenceType, salesreferenceno, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      let salesinvoicereferencedetails = resultJSON.salesinvoicereferencedetails[0];
      this._quotationresult = resultJSON.salesinvoicereferenceproductdetail;

      if (salesinvoicereferencedetails) {
        this._Currencies = resultJSON.currencyreference;
        this._ReturnCurrencies = resultJSON.currencyreference;
        this._salesinvoiceposform.get("currencyid").setValue(salesinvoicereferencedetails.currencyid);
        this._salesinvoiceposform.get("currencyreferenceid").setValue(salesinvoicereferencedetails.currencyreferenceid);
        this._salesinvoiceposform.get("currencyid").disable();

        this.OnUserPreferredCurrencyChange();

      }

      for (var i = 0; i < this._quotationresult.length; i++) {
        if (this._quotationresult[i].quantity > this._quotationresult[i].stocktemp) {
          this._salesinvoiceposform.get("deliveryrequired").setValue(true);
          if (this._quotationresult[i].disallownegativeqty == true) {
            this._CustomExceptionService.handleWarning("stock not available");
            return;
          }
        }
      }
      this.AddProductToGrid(resultJSON, 'salesinvoicereferenceproductdetail');
      if (this._selectedReferenceType == "MSC00221") {

        // this._salesinvoiceposform.controls["cuscurrencyamount1"].setValue(salesinvoicereferencedetails.finalamount);
        // this._salesinvoiceposform.controls["paidamount"].setValue(salesinvoicereferencedetails.finalamount);
        this._salesinvoiceposform.get("cuscurrencyid1").setValue(salesinvoicereferencedetails.currencyid);
        // this._salesinvoiceposform.disable();
        // this._salesproductform.disable();
        //lay buy changes S
        var currencyid = this._salesinvoiceposform.get("currencyid").value;
        this._advancereferences = this._TempLayBuyadvancereferences.filter(f => f.currencyid == currencyid && f.type == 2) || [];
        this._advances = (this._TempLayBuyadvancereferences.filter(f => f.laybuyno == salesreferenceno && f.type == 2) || []).map(m => m.refNo);
        this.OnChangeAdvanceReferences();
        //lay buy changes E
        this._IsdisabledAdvanceRef = true;
        this.IsDisabledLayBuy = true;
        this._salesinvoiceposform.get("salesreferenceno").enable();
      }
      this._salesinvoiceposform.controls['markuplevelid'].setValue(this._customer.levelid);
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  GetProductCategory(event) {
    var selectedProductType = event.value;
    if (this._selectedcustomer == undefined) {
      this._CustomExceptionService.handleWarning("Please select customer");
      this._salesproductform.controls["producttypeid"].reset();
      this.productcatid = [];
      this._ProductCategories = [];
      return;
    }

    if (selectedProductType.length == 0 || selectedProductType == null || selectedProductType == undefined) {
      this._CustomExceptionService.handleWarning("Please select Product type");
      this.productcatid = []
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": this._branchid || 0 };
      this._SalesinvoiceposService.GetProductCategory(this._ProductTypeObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._ProductCategories = resultJSON.productcategories;
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }

  GetProductName(event) {
    var selectedProductCategory = event.value;
    var isdamagedproduct = this._salesproductform.get("isdamaged").value || false;
    this._ProductNames = this._ProductNamesTemp;
    if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductCategoryObj = { "isdemageproduct": isdamagedproduct, "productcategory": selectedProductCategory || [], "branchid": this._branchid || 0 };
      this._SalesinvoiceposService.GetProductName(this._ProductCategoryObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._ProductNames = <Productoverallview[]>resultJSON.productsearch || [];
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }

  GetProductPrice(event) {
    this._submitted1 = true;

    if (!this._salesproductform.valid) {
      this._salesproductform.markAllAsTouched();
      return
    }
    let packtype = this._salesproductform.get("packtype").value || this._packtype;

    var quantity = 0;
    let existproduct = this._ProductDetails.filter(f => f.productid == this._salesproductform.get("productid").value.productid && f.packtype == packtype);
    quantity = (this._salesproductform.get("quantity").value || 0);
    if (existproduct.length > 0) {
      quantity = existproduct[0].quantity + (this._salesproductform.get("quantity").value || 0)
    }

    let productid = this._salesproductform.get("productid").value.productid;
    let variant = this._salesproductform.get("variantid").value || 0;
    var IsDamaged = this._salesproductform.get("isdamaged").value || false;
    var selectedbranch = this._salesinvoiceposform.get("collectingbranch").value;
    var remainingatock = this._salesproductform.get("stockinhand").value;
    let markuplevelid = sessionStorage["levelid"];
    let id = this._salesinvoiceposform.get("currencyid").value;
    var severity = this._salesproductform.get("severity").value || null;
    //let customerid = this._salesinvoiceposform.get("customerid").value || 0;
    let selectedCurrencyList = (this._Currencies || []).filter(f => f.currencyid == id);
    let errorMsg = '';

    if (IsDamaged) {
      this._ProductDetails.every(element => {
        if (element.productid == productid && element.severity != severity) {
          errorMsg = 'Same Product Different Severity NOT Allowed';
        }
      });
    }

    this._ProductDetails.every(element => {
      if (element.isdamaged != IsDamaged) {
        errorMsg = 'Should NOT Allowed Damaged and OK Product in One Invoice';
      }
    });

    if (!quantity) {
      errorMsg = 'Quantity Required'
    }
    else if (quantity < 0) {
      errorMsg = 'Quantity should not be in negative.'
    }

    if (!productid) {
      errorMsg = 'Choose Product'
    }
    if (!this._selectedcustomer) {
      errorMsg = 'Choose Customer'
    }
    if (!selectedCurrencyList.length)
      errorMsg = 'Please select currency'
    if (remainingatock < quantity && selectedbranch != this._branchid)
      errorMsg = 'Your Collecting Branch Stock Not In a Hand'
    if (this.ShowErrorMsg(errorMsg))
      return;
    var IsDamaged = this._salesproductform.get("isdamaged").value || false;
    let roleid = sessionStorage["currentRole"];
    this._IsProgressSpinner = true;
    this._SalesinvoiceposService.GetProductPrice(productid, variant, quantity, selectedbranch, this._selectedcustomer, this._userid, markuplevelid, IsDamaged, severity, roleid, this._branchid, packtype).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        // this.AddProductToGrid(resultJSON, 'salesproductprice');
        this._salesinvoiceposform.controls['markuplevelid'].setValue(resultJSON.salesproductprice[0].markuplevel);
        if (resultJSON.schemeremarks.length > 0) {
          this.dispalyBasicScheme = true
          this.Salesinvoiceresultjson = resultJSON;
          this.salesproductprice = 'salesproductprice';
          this.schemeRemarks = resultJSON.schemeremarks ? resultJSON.schemeremarks.map(m => m.SCHEMEREMARKS || []) : [""];
          this.CloseProductTypeDialog(this.dispalyBasicScheme, this.Salesinvoiceresultjson, this.salesproductprice);
          this.schemeRemarks.length > 0 ? this.dispalyBasicScheme = true : this.dispalyBasicScheme = false;
          return;
        } else {
          this.schemeRemarks = [""];
        }
        let producttypeid = this._ProductTypes.filter(f => (this.producttypeid || []).includes(f.producttypeid)).map(m => m.producttypeid);
        if (producttypeid.some(s => s == 17)) {
          this.displayBasicProductType = true;
          this.Salesinvoiceresultjson = resultJSON;
          this.salesproductprice = 'salesproductprice';
          this.CloseProductTypeDialog(this.displayBasicProductType, this.Salesinvoiceresultjson, this.salesproductprice);
        } else {
          this._advances = [];
          this._salesinvoiceposform.controls["creditnoteapplicable"].setValue(false);
          this._salesinvoiceposform.controls["creditnoteamount"].setValue(0);
          this._salesinvoiceposform.controls["customeradvanceamount"].setValue(0);
          this._salesinvoiceposform.controls["creditnoteapplicable"].enable();
          this._creditnoteids = [];
          this._creditnotedetail = []
          this._IsdisabledAdvanceRef = false;
          this._salesinvoiceposform.get("creditnoteinvoiceno").reset();
          //this._salesproductform.get("quantity").setValue(resultJSON.salesproductprice[0].quantity);
          this.AddProductToGrid(resultJSON, 'salesproductprice');
          this._packdetails = [];
        }
        this._submitted1 = false;
        this._IsProgressSpinner = false;
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
        this._IsProgressSpinner = false;
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  GetVariantName() {
    var selectedproduct = this._salesproductform.get("productid").value.productid;
    this._selectedproduct = selectedproduct;
    this._salesproductform.controls["variantid"].reset();
    this._Variants = [];
    let isDamaged = (this._salesproductform.get("isdamaged").value == true ||
      this._salesproductform.get("isdamaged").value == 1);
    let severity = this._salesproductform.get("severity").value;
    if (!selectedproduct) {
      return;
    }
    this._IsProgressSpinner = true;
    let product = this.filteredProducts.filter(f => f.productid == selectedproduct)[0];
    this.producttypeid = [];
    this.producttypeid.push(product.producttypeid);
    this.productcatid = [];
    this.productcatid.push(product.productcategoryid);
    var uom = product.salesuom;
    this._salesproductform.get("quantity").reset();
    if (uom == "MSC00037") {
      this.hidequantity = true;
    }
    else {
      this.hidequantity = false
    }
    this._salesproductform.controls['producttypeid'].setValue(this.producttypeid);
    this._salesproductform.controls['productcategoryid'].setValue(this.productcatid);
    var selectedbranch = this._salesinvoiceposform.get("collectingbranch").value;
    this._SalesinvoiceposService.GetVariant(selectedproduct, selectedbranch, isDamaged, severity).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      if (isDamaged) {
        this._salesproductform.controls['stockinhand'].setValue(0);
        if (resultJSON.collectingBranchDamagedStock) {
          this._salesproductform.controls['stockinhand'].setValue(resultJSON.collectingBranchDamagedStock.damagedstock);
        }
      } else {
        if (!resultJSON.collectingBranchStock) {
          this._salesproductform.controls['stockinhand'].setValue(0);
        }
        else {
          this._Branch = resultJSON.collectingBranchStock.stock1 || 0;
          this._salesproductform.controls['stockinhand'].setValue(this._Branch);
        }
      }
      this._Variants = resultJSON.productvariants;
      this._ProductCategories = resultJSON.productcategories;
      this._packdetails = resultJSON.packdetails;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  GetVariants(event) {
    var selectedvariant = event.value;
    this._SelectedVariant = selectedvariant || 0;
  }

  CloseProductTypeDialog(displayBasicProductType, Salesinvoiceresultjson: any, salesproductprice: string) {
    if (displayBasicProductType) {
    } else {
      this.displayBasicProductType = false;
      this.dispalyBasicScheme = false;
      this.AddProductToGrid(Salesinvoiceresultjson, 'salesproductprice');
    }
  }

  AddProductToGrid(resultJSON: any, prop: string) {
    this._advances = [];
    this._salesinvoiceadvancereferences = [];
    this._salesinvoiceposform.controls["customeradvanceamount"].setValue(0);
    let errorMsg = '';
    let customerwisediscount = null;
    this._salesinvoiceposform.controls["applicablerewardpoints"].reset();
    this._salesinvoiceposform.controls["applicablerewardpoints"].disable();
    this._salesinvoiceposform.controls["applyrewardpoints"].reset();
    let currencyid = this._salesinvoiceposform.get("currencyid").value;
    this._salesinvoiceposform.controls["remainingpoints"].setValue(this._RemainingPoints);
    if (resultJSON.customerwisediscounts && resultJSON.customerwisediscounts.length) {
      customerwisediscount = resultJSON.customerwisediscounts[0];
    }
    let c_maxdiscountper = 0;
    if (customerwisediscount) {
      // c_maxdiscountper = customerwisediscount.discount || 0;
      c_maxdiscountper = currencyid == 1 ? customerwisediscount.usddiscount : currencyid == 2 ? customerwisediscount.randdiscount :
        currencyid == 3 ? customerwisediscount.bonddiscount : currencyid == 4 ? customerwisediscount.bankdiscount : 0;
    }

    let Productdetails = (<Salesinvoiceproductpriceview[]>resultJSON[prop]) || [];
    let p_quantity = this._salesproductform.get("quantity").value || 0;
    let reference = this._salesinvoiceposform.get("salesreference").value;
    let referenceno = this._salesinvoiceposform.get("salesreferenceno").value;

    let userSelectedCurrency = this._Currencies.find(f => f.currencyid == currencyid);
    let userselectedExchangeRate = userSelectedCurrency.exchangerate;
    let customerid = this._salesinvoiceposform.get("customerid").value;
    let customer = this._Customernames.find(f => f.customerid == customerid);
    let vatType = customer.vattype;

    if (this.ShowErrorMsg(errorMsg))
      return;
    for (var p = 0; p < resultJSON[prop].length; p++) {
      let product = Productdetails[p];
      let quantity = (p_quantity || product.quantity);
      let unitPrice = 0;
      if (!referenceno)
        if (product.vattype == "MSC00062") {
          var unitPrice1 = product.unitprice;
          unitPrice = currencyid == 1 ? product.usd : currencyid == 2 ? product.rand : currencyid == 3 ? product.bond : currencyid == 4 ? product.bank : userselectedExchangeRate * product.usd;
        }
        else {
          unitPrice = currencyid == 1 ? product.usd : currencyid == 2 ? product.rand : currencyid == 3 ? product.bond : currencyid == 4 ? product.bank : userselectedExchangeRate * product.usd;
        }
      else {
        unitPrice = product.usd;
      }
      var unitpriceWithoutTax = 0;
      var productAmountWithoutTax = 0;
      var productAmount = 0;
      if (product.vattype == "MSC00062") {
        product.tempunitprice = unitPrice1;
        unitpriceWithoutTax = product.unitprice;
        productAmountWithoutTax = quantity * unitpriceWithoutTax;
        productAmount = parseFloat((quantity * unitPrice).toFixed(2));
      }
      else {
        product.tempunitprice = unitPrice;
        unitpriceWithoutTax = product.unitprice;
        productAmountWithoutTax = quantity * unitpriceWithoutTax;
        productAmount = quantity * unitPrice;
      }
      var taxAmount = 0;
      if (!referenceno) {
        if (product.vatapplicable) {
          taxAmount = userselectedExchangeRate * (productAmountWithoutTax * (product.taxpercentage / 100));
        }
      }
      else {
        if (product.vatapplicable) {
          taxAmount = product.taxamount;
        }
      }

      let app_discountamount = (productAmount * (product.customerdiscountpercentage / 100)) || 0;
      let def_discountamount = (productAmount * (product.discountpercentage / 100)) || 0;
      if ((product.iscshemeavailable)) {
        def_discountamount = 0;
      }
      let finalAmount = (productAmount) - (def_discountamount + app_discountamount)
      let existingProductIndex = this._ProductDetails.findIndex(f => f.productid == product.productid && f.variantid == product.variantid && f.producttype == product.producttype && f.packtype == product.packtype);
      //Same Product Update the Property Start
      if (existingProductIndex != -1) {
        if (this._ProductDetails[existingProductIndex].producttype == 'Free Product') {
          this._ProductDetails[existingProductIndex].quantity = parseFloat(product.quantity + '' || '0');
        } else {
          this._ProductDetails[existingProductIndex].quantity += (quantity);
          this._ProductDetails[existingProductIndex].quantity = parseFloat((this._ProductDetails[existingProductIndex].quantity).toFixed(3))
        }
        this._ProductDetails[existingProductIndex].productamount += productAmount;
        //new Update discount or tax
        product.customerdiscountpercentage = 0;
        product.customerdiscountamount = 0;
        app_discountamount = 0
        def_discountamount = 0
        finalAmount = this._ProductDetails[existingProductIndex].productamount - (def_discountamount + app_discountamount)
        productAmountWithoutTax = this._ProductDetails[existingProductIndex].quantity * unitpriceWithoutTax;
        taxAmount = userselectedExchangeRate * (productAmountWithoutTax * (product.taxpercentage / 100));
        //End

        if (product.vattype == "MSC00062") {
          this._ProductDetails[existingProductIndex].taxamount = taxAmount;
          this._ProductDetails[existingProductIndex].finalamount = finalAmount;
          this._ProductDetails[existingProductIndex].unitprice = unitPrice;
        }
        else {
          this._ProductDetails[existingProductIndex].taxamount = taxAmount || 0;
          this._ProductDetails[existingProductIndex].finalamount = finalAmount + taxAmount || 0;
          this._ProductDetails[existingProductIndex].unitprice = unitPrice;
        }

        product.productamount = this._ProductDetails[existingProductIndex].productamount;
        product.taxamount = this._ProductDetails[existingProductIndex].taxamount;
        product.finalamount = this._ProductDetails[existingProductIndex].finalamount
        product.quantity = this._ProductDetails[existingProductIndex].quantity;
        this._ProductDetails[existingProductIndex].customerdiscountpercentage = 0;
        this._ProductDetails[existingProductIndex].customerdiscountamount = 0;
        product = this._ProductDetails[existingProductIndex]
        product.stockqty = product.quantity * product.quantityperpack;
      }
      //Same Product Update the Property End
      else {
        if ((product.stock - (product.quantity + quantity)) < 0 && !reference) {
        }
        product.customerdiscountamount = product.customerdiscountamount || 0;
        product.approveddiscountamount = product.approveddiscountamount || 0;
        product.approveddiscountpercentage = product.approveddiscountpercentage || 0;

        if (product.vattype == "MSC00062") {
          this.unitprice = product.unitprice;
          product.unitprice = unitPrice;
        }
        else {
          product.unitprice = unitPrice;
        }

        if (!referenceno) {
          product.taxamount = taxAmount;
        }
        if (product.vatapplicable)
          product.taxpercentage = Productdetails[p].taxpercentage;
        else
          product.taxpercentage = 0;
        product.productamount = product.unitprice * quantity;
        product.discountamount = def_discountamount;
        product.approveddiscountamount = 0;
        product.approveddiscountpercentage = product.approveddiscountpercentage || 0;
        product.disableFreeProductFeild = (product.producttype == "Free Product") || (product.iscshemeavailable);
        product.stocktemp = product.stock;
        product.isdamaged = this._salesproductform.get("isdamaged").value;
        product.severity = this._salesproductform.get("severity").value;
        product.cus_dispercentage = c_maxdiscountper;
        product.purchasepriceinusd = product.purchaserateinusd || 0;
        product.packtype = product.packtype;
        product.quantityperpack = product.quantityperpack;
        product.stockqty = product.quantity * product.quantityperpack;

        if (!referenceno) {
          product.finalamount = finalAmount;
          if (customer.vattype == "MSC00063") {
            product.finalamount += taxAmount || 0;
          }
        }
        this._ProductDetails.push(product);
      }
      let producttype = product.producttype.toLowerCase();
      //Is This Product Scheme
      if (product.iscshemeavailable && producttype != 'free product') {
        this.allocatequantity(product);
      }
      //Other Wise  
      else {
        this.CalculateRewardPoints(product);
      }
      //Customer Wise Discount
      if (product.cus_dispercentage > 0) {
        product.customerdiscountpercentage = 0;
        product.disableFreeProductFeild = true;
        this.allocatequantity(product);
      }
      //Paaventhan
      else {
        this.allocatequantity(product);
      }
      product.disableunitprice = true;
    }

    this.TotalCalculation();
    this.InitializeProductForm();

    this.Clear(null);
    $("input[name='productbarcode']").focus();
    this.disableLaybuyOptions();
  }

  CalculateRewardPoints(product: Salesinvoiceproductpriceview) {
    if (!product || !this.reward)
      return;

    let rewardPoint = 0;
    if (this.reward && this.reward.rewardsvalue) {
      rewardPoint = this.reward.rewardsvalue;
    }
    let currencyid = this._salesinvoiceposform.get("currencyid").value;
    let usdCurrency = (this._Currencies || []).find(f => f.currencyid == 1);
    let cusCurrency = (this._Currencies || []).find(f => f.currencyid == currencyid);
    let exrate = 1;
    if (currencyid != 1) {
      exrate = (usdCurrency.exchangerate / cusCurrency.exchangerate);
    }

    // let sellingprice = (exrate * product.finalamount);
    let sellingprice: number = 0;
    let customerincludeorexclude: boolean = this._Customernames.some(f => f.customerid == this._salesinvoiceposform.get("customerid").value && f.vattype == "MSC00062");
    if (customerincludeorexclude) {
      sellingprice = (exrate * (product.productamount - (product.taxamount || 0)));
    } else {
      sellingprice = (exrate * (product.productamount));
    }

    let costprice = (exrate * (product.purchaserate * product.quantity));
    let profit = (sellingprice - costprice);
    let margin = Math.round((profit / costprice) * 100);
    let point = 0;
    if (margin >= 30) {
      point = (profit * (margin / 1000));
    }

    let existingProductIndex = this._ProductDetails.findIndex(f => f.productid == product.productid && f.variantid == product.variantid && f.producttype == product.producttype);
    if (existingProductIndex != -1) {
      this._ProductDetails[existingProductIndex].rewardpointsapplied = (point * rewardPoint);
      product.rewardpointsapplied = (point * rewardPoint);
    } else {
      product.rewardpointsapplied = (point * rewardPoint);
    }
  }

  RemoveProduct(pProduct: Salesinvoiceproductpriceview) {
    var index = this._ProductDetails.indexOf(pProduct);
    this._ProductDetails.splice(index, 1);
    this._salesinvoiceposform.get("cuscurrencyid2").reset();
    this._salesinvoiceposform.get("cuscurrencyamount1").reset();
    this._salesinvoiceposform.get("cuscurrencyamount2").reset();
    this._advances = [];
    this._salesinvoiceposform.controls["creditnoteapplicable"].setValue(false);
    this._salesinvoiceposform.controls["creditnoteamount"].setValue(0);
    this._salesinvoiceposform.controls["customeradvanceamount"].setValue(0);
    this._salesinvoiceposform.controls["creditnoteapplicable"].enable();
    this._creditnoteids = [];
    this._IsdisabledAdvanceRef = false;
    this._salesinvoiceposform.get("creditnoteinvoiceno").reset();
    this.Multicurrencycalculation();
  }

  CheckCollectingBranchStock() {
    var selectedbranch = this._salesinvoiceposform.get("collectingbranch").value;
    if (selectedbranch != this._branchid) {
      this._salesinvoiceposform.get("deliveryrequired").setValue(true);
      this._salesinvoiceposform.controls['deliverymode'].setValue(205);
    } else {
      this._salesinvoiceposform.get("deliveryrequired").setValue(this._isDeliveryRequired);
      this.OnChangeDeliverymode({ checked: this._isDeliveryRequired });
    }


    let variant = this._salesproductform.get("variantid").value || 0;
    this._selectedProduct = this._salesproductform.get("productid").value.productid || 0;
    if (this._ProductDetails.length >= 1) {
      this.confirmationService.confirm({
        message: ' Existing products will clear once you change the collecting branch',
        accept: () => {

          this._IsProgressSpinner = true;
          this._SalesInvoiceTaxService.CheckCollectingBranchStock(this._selectedProduct, selectedbranch, variant).subscribe((result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            //this._salesinvoiceposform.get("deliveryrequired").setValue(true);
            this._ProductDetails = [];
            console.log("collecting branch", resultJSON);
            this._branchresult = resultJSON.collectingBranchStock;
            this._IsProgressSpinner = false;
            if (!this._branchresult || !this._branchresult.length) {
              // this._CustomExceptionService.handleWarning("There is no stock in that branch");
              return;
            }
            else {
              for (var i = 0; i < this._branchresult.length; i++) {
                if (this._salesproductform.get("quantity").value > this._branchresult[i].stock1 || 0) {
                  this._CustomExceptionService.handleWarning("Available Stock is " + this._branchresult[i].stock1);
                  return;
                }

              }
            }
          },
            error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
        },
        reject: () => {

        }

      });
    }
  }
  Multicurrencycalculation() {

    let usernetamount = parseFloat(this._salesinvoiceposform.get("netamountincustomercurrency").value || 0);
    let id = this._salesinvoiceposform.get("currencyid").value;
    let cuscurrencyid1 = this._salesinvoiceposform.get("cuscurrencyid1").value || 0;
    let cuscurrencyid2 = this._salesinvoiceposform.get("cuscurrencyid2").value || 0;

    if (!cuscurrencyid1)
      return;

    //let cutomerselectedcurrency1 = this.GetExchangeRate(currencyid1);
    //var netamountinCustomerinusd = this._salesinvoiceposform.get('netamountincustomercurrency').value;

    //var CustomerCurrencyRate1 = netamountinCustomerinusd * cutomerselectedcurrency1;

    let usercurrencyamount = this.GetUserAmount();

    let balanceamount = (usercurrencyamount - usernetamount);
    this._salesinvoiceposform.controls["paidamount"].setValue(usercurrencyamount.toFixed(2));

    this._salesinvoiceposform.controls["balanceamount"].setValue(balanceamount.toFixed(2));
    this._salesinvoiceposform.controls["customerbalanceamount"].setValue(balanceamount.toFixed(2));

    this.TotalCalculation();


    this._ReturnCurrencies = [];
    if (cuscurrencyid1) {
      let currency: Currency = this._Currencies.find(f => f.currencyid == cuscurrencyid1);
      if (currency) {
        let hascurrency = this._ReturnCurrencies.some(s => s.currencyid == currency.currencyid);
        if (!hascurrency)
          this._ReturnCurrencies.push(currency);
      }
    }
    if (cuscurrencyid2) {
      let currency: Currency = this._Currencies.find(f => f.currencyid == cuscurrencyid2);
      if (currency) {
        let hascurrency = this._ReturnCurrencies.some(s => s.currencyid == currency.currencyid);
        if (!hascurrency)
          this._ReturnCurrencies.push(currency);
      }
    }
  }
  ChangeReturnCurrency($event) {
    let balanceamount = parseFloat(this._salesinvoiceposform.get("balanceamount").value || 0);
    let creditbalanceamount = this.tempcreditnotebalanceamount; // parseFloat(this._salesinvoiceposform.get("creditnotebalanceamount").value || 0);
    let id = this._salesinvoiceposform.get("currencyid").value;
    let returncurrencyid = this._salesinvoiceposform.get("returncurrencyid").value;

    // if (id == 4) return;

    this._salesinvoiceposform.get("returnamount").setValue(balanceamount);
    this._salesinvoiceposform.get("creditnotebalanceamount").setValue(creditbalanceamount);

    if (id != returncurrencyid) {
      let rerate1 = 0;
      let exrate1 = 0;
      let returnCurrency = (this._Currencies || []).find(f => f.currencyid == returncurrencyid);
      let currency = (this._Currencies || []).find(f => f.currencyid == id);

      if (currency)
        exrate1 = currency.exchangerate;
      if (returnCurrency)
        rerate1 = returnCurrency.exchangerate;

      if (balanceamount > 0) {
        balanceamount = ((rerate1 / exrate1) * balanceamount);
        this._salesinvoiceposform.get("returnamount").setValue(balanceamount.toFixed(3));
      }
      else {
        creditbalanceamount = ((rerate1 / exrate1) * creditbalanceamount);
        this._salesinvoiceposform.get("creditnotebalanceamount").setValue(creditbalanceamount.toFixed(3));
      }
    }
  }

  OnSelectCurrency1() {
    this.Multicurrencycalculation();
  }


  CalculateCurrencyAmount1() {
    this.Multicurrencycalculation();
  }
  OnSelectCurrency2() {
    this._salesinvoiceposform.get("cuscurrencyid2").reset();
    this._salesinvoiceposform.get("cuscurrencyamount2").reset();
    this.Multicurrencycalculation();
  }
  CalculateCurrencyAmount2() {
    this.Multicurrencycalculation();
  }
  // QuotationBasedCalculate() {
  //   for (var i in this._ProductDetails) {
  //     this.row = this._ProductDetails[i];
  //     this.allocatequantity(this.row);
  //   }
  // }

  TotalCalculation() {
    let customerid = this._salesinvoiceposform.get("customerid").value;
    let customer = this._Customernames.find(f => f.customerid == customerid);
    let tax = this.taxes.find(f => f.taxid == 1);

    let finalAmountInUC = 0;
    let id = this._salesinvoiceposform.get("currencyid").value;
    let creditAmount = parseFloat(this._salesinvoiceposform.get("creditnoteamount").value || 0);
    let paidamount = parseFloat(this._salesinvoiceposform.get("paidamount").value || 0);
    let advanceamount = parseFloat(this._salesinvoiceposform.get("customeradvanceamount").value || 0);
    let creditnotebalanceamount = 0;
    let usdCurrency = (this._Currencies || []).find(f => f.currencyid == 1);
    let cusCurrency = (this._Currencies || []).find(f => f.currencyid == id);
    // let usdCurrency = usdCurrencyList[0];

    let otherCharges = parseFloat(this._salesinvoiceposform.get("otherchargesamount").value || 0),
      TotalAmount = 0, FinalTotalAmount = 0, FinalTaxAmout = 0, balance = 0, profitamountUSD = 0, purchaseAmountUSD = 0;
    let totaldiscountamount = 0;
    let totalrewardpoints = 0;
    (this._ProductDetails || []).forEach((item) => {
      TotalAmount += (item.finalamount);
      FinalTotalAmount += item.finalamount;
      FinalTaxAmout += item.taxamount;
      purchaseAmountUSD += ((item.quantity || 0) * item.purchaserateinusd);
      // totaldiscountamount += parseFloat(item.totaldiscountamountapplied + '' || '0');
      totaldiscountamount += item.totaldiscountamountapplied || 0;
      totalrewardpoints += item.rewardpointsapplied || 0;
    });
    //totalrewardpoints = (totalrewardpoints * 2);
    // if (customer) {
    //   if (customer.vattype == "MSC00063") // exclude
    //   {
    //     let taxAmount = (TotalAmount * (tax.taxpercentage / 100));
    //     FinalTaxAmout = taxAmount;
    //     FinalTotalAmount += FinalTaxAmout;
    //   }
    // }


    //FinalTotalAmount -= creditAmount;
    this._finalrewardamount = 0;
    let netamountUSD = (usdCurrency.exchangerate / cusCurrency.exchangerate) * (FinalTotalAmount);
    let rewardsValue = this.GetrewardsValue(netamountUSD);
    var applicablerewardpoints = this._salesinvoiceposform.get("applicablerewardpoints").value;
    if (this.reward && applicablerewardpoints) {
      let applicablerewardamount = Math.floor(applicablerewardpoints / rewardsValue);
      this._finalrewardamount = applicablerewardamount * cusCurrency.exchangerate;
    } FinalTotalAmount += otherCharges;

    creditnotebalanceamount = this.Fixed2(creditAmount - FinalTotalAmount);

    finalAmountInUC = FinalTotalAmount - (creditAmount + this._finalrewardamount + advanceamount);
    if (id != 4) {
      //this._salesinvoiceposform.get('cuscurrencyid1').enable();
      this._salesinvoiceposform.get('cuscurrencyamount1').enable();
      this._salesinvoiceposform.get('cuscurrencyid2').enable();
      this._salesinvoiceposform.get('cuscurrencyamount2').enable();
    }
    if (finalAmountInUC <= 0) {
      finalAmountInUC = 0;
      this._salesinvoiceposform.get('cuscurrencyid1').disable();
      this._salesinvoiceposform.get('cuscurrencyamount1').disable();
      this._salesinvoiceposform.get('cuscurrencyid2').disable();
      this._salesinvoiceposform.get('cuscurrencyamount2').disable();
    }

    var NetAmountInUSD = (usdCurrency.exchangerate / this.GetExchangeRate(id)) * (finalAmountInUC);
    balance = paidamount - finalAmountInUC;

    let netamnt = (usdCurrency.exchangerate / this.GetExchangeRate(id)) * (FinalTotalAmount);

    profitamountUSD = (netamnt - purchaseAmountUSD);

    this._salesinvoiceposform.controls['salesinvoiceamount'].setValue(FinalTotalAmount.toFixed(2));
    this._salesinvoiceposform.get('productamount').setValue((FinalTotalAmount - FinalTaxAmout).toFixed(2)); // Gross Amount
    this._salesinvoiceposform.get('taxamount').setValue(FinalTaxAmout.toFixed(2));
    // this._salesinvoiceposform.controls['otherchargesamount'].setValue(otherCharges);
    this._salesinvoiceposform.get('purchaseamount').setValue(purchaseAmountUSD.toFixed(2));
    this._salesinvoiceposform.get('profitamount').setValue(profitamountUSD.toFixed(2));
    this._salesinvoiceposform.get('amountinusd').setValue(NetAmountInUSD.toFixed(2));
    this._salesinvoiceposform.get('netamountincustomercurrency').setValue(finalAmountInUC.toFixed(2));
    this._salesinvoiceposform.get("discountamount").setValue(totaldiscountamount);

    // let existrewardValue = parseFloat(this._salesinvoiceposform.get("gatheredrewardpoints").value || 0);

    this._salesinvoiceposform.get("gatheredrewardpoints").setValue((totalrewardpoints));
    this._salesinvoiceposform.get("creditnotebalanceamount").setValue(0);

    if (id == 4) {
      this._salesinvoiceposform.get('returncurrencyid').disable();
    }

    this.tempcreditnotebalanceamount = 0;
    if (creditnotebalanceamount > 0 && creditAmount > 0) {
      if (this._action != 'view') {
        this._CustomExceptionService.handleWarning("Please ensure to pay credit note balance amount to customer.");
      }
      this._salesinvoiceposform.get("creditnotebalanceamount").setValue(creditnotebalanceamount);
      this.tempcreditnotebalanceamount = creditnotebalanceamount;
      this._salesinvoiceposform.get('returncurrencyid').enable();
    }

    if (this._salesinvoiceposform.get("salesreference").value == "MSC00221") {
      balance = 0;
    }



    this._salesinvoiceposform.controls['balanceamount'].setValue(balance.toFixed(2));
    this._salesinvoiceposform.controls['customerbalanceamount'].setValue(balance.toFixed(2));
    this.isDisabledinvoiceslip = true;
    this.isDisabledprint = true;
    this.isDisabledsendtoapproval = true;
    // let isRequestAmountGreater = this._ProductDetails.some(f => f.customerdiscountpercentage > f.userdiscountpercentage);
    // if (isRequestAmountGreater) {
    //   this.isDisabledsendtoapproval = false;
    // }

    this._salesinvoiceposform.get("returnamount").setValue(Math.abs(balance).toFixed(2));
    this.ChangeReturnCurrency(null);
  }

  Fixed2(value: number): number {
    if (!value) return 0;

    return parseFloat(value.toFixed(2));
  }

  Clear(event: any) {
    // this._submitted1 = false;
    this._salesproductform.reset();
    this._ProductCategories = [];
    this.productcatid = [];
    this.InitializeProductForm();
  }


  onRowSelect(event) {
    console.log("Selected Suppliers");
    console.log(this.selectedrows);
  }
  onRowUnselect(event) {
    console.log("eventUnfired");
    console.log(event.data);
    console.log(this.selectedrows);
  }

  OnChangeDeliverymode(event) {
    if (event.checked) {
      this._salesinvoiceposform.controls['deliverymode'].setValue(205);
    } else {
      this._salesinvoiceposform.controls['deliverymode'].setValue(null);
    }

  }

  OnChangeAdvanceReferences() {
    this._salesinvoiceposform.get("customeradvanceamount").setValue(0);
    this._salesinvoiceposform.get("customeradvanceamount").setValue(0);
    this._salesinvoiceadvancereferences = [];
    let id = this._salesinvoiceposform.get("currencyid").value;
    this._IsProgressSpinner = true;
    this.Multicurrencycalculation();
    this.DisabledAdvanceOrCredit();
    this._IsProgressSpinner = false;
    if (!this._advances.length) return;
    this._IsProgressSpinner = true;
    let selectedreferences = this._advancereferences.filter(f => this._advances.includes(f.refNo));
    let vouchers = [];
    selectedreferences.forEach(element => {
      vouchers.push(element.voucherno);
    });
    let data = {
      isCreditnote: false,
      currencyid: id,
      refnos: (vouchers || [])
    };
    this._salesinvoiceadvancereferences = [];
    this._SalesinvoiceposService.GetConversion(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._salesinvoiceadvancereferences = [];
        selectedreferences.forEach(element => {
          let amount = parseFloat(resultJSON.exchangeRate[element.voucherno]) * element.remainingamount;
          let _advancereference = new Salesinvoiceadvancereference();
          _advancereference.refNo = element.refNo;
          _advancereference.remainingamount = amount;
          _advancereference.voucherno = element.voucherno;
          _advancereference.currencyrefid = element.currencyuniquerefid;
          this._salesinvoiceadvancereferences.push(_advancereference);
        });
        this._salesinvoiceposform.get("customeradvanceamount").setValue(resultJSON.converionAmount);
        this.Multicurrencycalculation();
        this.DisabledAdvanceOrCredit();
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
      }
      this._IsProgressSpinner = false;
      this.isSaveClicked = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; this.isSaveClicked = false; });
  }

  onSave() {
    if (this.isSaveClicked) return;
    this._submitted = true;
    let customercurncyamount = parseFloat(this._salesinvoiceposform.get('netamountincustomercurrency').value || 0)
    let currencyid = this._salesinvoiceposform.get("currencyid").value;
    let refCode = this._salesinvoiceposform.get("salesreference").value;
    let customerpaidamount = parseFloat(this._salesinvoiceposform.get("paidamount").value || 0);
    let returnAmount = parseFloat(this._salesinvoiceposform.get("returnamount").value || 0);
    let returncurrencyid = this._salesinvoiceposform.get("returncurrencyid").value;
    let paymodecode = this._salesinvoiceposform.get("paymenttype").value;
    let branchid = parseFloat(sessionStorage["currentbranchid"]);
    let salesAmount = parseFloat(this._salesinvoiceposform.get("netamountincustomercurrency").value);
    //gayathri --start
    // let customernetamount = parseFloat(this._salesinvoiceposform.get("netamountincustomercurrency").value || 0);
    // if (this._advancereferences.length) {
    //   if (customernetamount > 0) {
    //     if (this._salesinvoiceadvancereferences.length == 0) {
    //       this._CustomExceptionService.handleWarning("This customer having Advance Amount, Kindly use it first");
    //       return;
    //     }
    //   }
    //   if (customernetamount > 0) {
    //     let totaladvanceamount: number = 0,
    //       selectedadvanceamount: number = 0
    //     for (var i = 0; i < this._advancereferences.length; i++) {
    //       totaladvanceamount += parseFloat(this._advancereferences[i].remainingamount.toFixed(2));
    //     }
    //     if (totaladvanceamount < customernetamount && customernetamount > 0 && this._advancereferences.length != this._salesinvoiceadvancereferences.length) {
    //       this._CustomExceptionService.handleWarning("Kindly Choose Another Advance");
    //       return;
    //     }
    //     if (totaladvanceamount > customernetamount && customernetamount > 0 && this._advancereferences.length != this._salesinvoiceadvancereferences.length) {
    //       this._CustomExceptionService.handleWarning("Kindly Choose Another Advance");
    //       return;
    //     }
    //   }
    // }
    //gayathri --end
    if (paymodecode != "MSC00146" && refCode != "MSC00221") {
      var bankledgerid = this._salesinvoiceposform.get("bankledgerid").value;
      if (!bankledgerid && customercurncyamount > 0) {
        this._CustomExceptionService.handleWarning("Please select bank");
        return;
      }
    }
    if (paymodecode == "MSC00251") {
      if (!this._advances || !this._advances.length) {
        this._CustomExceptionService.handleWarning("In Cheque/Online Process should select advance reference no.");
        return;
      }
      if (customercurncyamount > 0) {
        this._CustomExceptionService.handleWarning("In Cheque/Online Process \"Amount in Customer Currency\" should be zero.");
        return;
      }
    }
    // if ((returnAmount > 0 && !returncurrencyid && currencyid != 4) || (this.tempcreditnotebalanceamount > 0 && !returncurrencyid)) {
    //   this._CustomExceptionService.handleWarning("Please select return currency.");
    //   return;
    // }
    if (paymodecode != "MSC00146" && refCode != "MSC00221") {
      var selectedpaymentcode = this._salesinvoiceposform.get("bankledgerid").value;
      if (!selectedpaymentcode && customercurncyamount > 0) {
        this._CustomExceptionService.handleWarning("Please select bank");
        return;
      }

      if (currencyid == 4)// ZiG Currency
      {
        var currency = this._CurrenciesTemp.find(c => c.currencyid == currencyid);
        if (currency.currencystatus == "MSC00343") {
          this._CustomExceptionService.handleError("404 Error Found.....");
          return;
        }

        var ziglimitinusd = 0
        var ziglimitvalue = 0;

        ziglimitinusd = this._Tempbranch.find(f => f.branchid == branchid).ziglimitinusd;
        ziglimitvalue = ziglimitinusd * currency.exchangerate;

        if (ziglimitvalue < salesAmount && ziglimitinusd > 0) {
          this._CustomExceptionService.handleError("Zig Transaction Failed, please try again after some times");
          return;
        }
      }
    }
    if (paymodecode == "MSC00146" && currencyid != 4) {
      if (customercurncyamount > 0 && customerpaidamount <= 0) {
        this._CustomExceptionService.handleWarning("customer paid amount should be greater than 0");
        return;
      }
    }

    if (this._salesinvoiceposform.valid || this._action != 'create') {
      if (currencyid != 4 && (currencyid == 1 && paymodecode == "MSC00146")) {
        var AmountinUsd = parseInt(this._salesinvoiceposform.get("amountinusd").value);
        var PaidAmount = parseInt(this._salesinvoiceposform.get("paidamount").value);

        if (AmountinUsd > PaidAmount && refCode != "MSC00221") {
          this._CustomExceptionService.handleWarning("Customer paid amount should be greater than net amount");
          return;
        }
        if (this._ProductDetails.length <= 0) {
          this._CustomExceptionService.handleWarning("Please add atleast one product");
          return;
        }

        if (this.ValidateCurrency())
          return;
      }
      if (currencyid == 4 || (currencyid == 1 && (paymodecode == "MSC00148" || paymodecode == "MSC00149"))) // for bank currency.
      {
        let invoiceamt = parseFloat(this._salesinvoiceposform.get("salesinvoiceamount").value || 0);
        let creditnoteamount = parseFloat(this._salesinvoiceposform.get("creditnoteamount").value || 0);
        if (creditnoteamount > invoiceamt && !returncurrencyid) {
          this._CustomExceptionService.handleWarning("Please choose return currency.");
          return;
        }
        let amount = this._salesinvoiceposform.get("netamountincustomercurrency").value
        this._salesinvoiceposform.get("paidamount").setValue(amount);
      }

      var SalesInvoiceDate = this._salesinvoiceposform.get("salesinvoicedate").value;
      var datePipe = new DatePipe("en-US");
      var pSalesIvoiceDate = datePipe.transform(SalesInvoiceDate, 'yyyy-MM-dd');
      //For Model and Form Mapping
      this._Salesinvoicepos = this._salesinvoiceposform.getRawValue();
      this._Salesinvoicepos.salesinvoicedate = (new Date(pSalesIvoiceDate));
      let creditNoteArray = (this._salesinvoiceposform.get("creditnoteid").value || []);

      //if (this._action == 'create') {
      this._Salesinvoicepos.createdby = sessionStorage["userid"];
      this._Salesinvoicepos.createdon = new Date();
      this._Salesinvoicepos.modifiedby = sessionStorage["userid"];
      this._Salesinvoicepos.modifiedon = new Date();
      this.SalesinvoiceRewardDetailslist = [];
      if (this._Salesinvoicepos.applyrewardpoints && this._Salesinvoicepos.applicablerewardpoints > 0) {
        let salesinvoiceRewardDetails = new Salesinvoicerewarddetail();
        salesinvoiceRewardDetails.salesinvoicerewardid = 0
        salesinvoiceRewardDetails.salesinvoiceid = this._Salesinvoicepos.salesinvoiceid;
        salesinvoiceRewardDetails.salesinvoicedate = this._Salesinvoicepos.salesinvoicedate;
        salesinvoiceRewardDetails.customerid = this._salesinvoiceposform.get("customerid").value;
        salesinvoiceRewardDetails.effectivefrom = this._Salesinvoicepos.salesinvoicedate;
        salesinvoiceRewardDetails.effectiveto = new Date(moment(this._Salesinvoicepos.salesinvoicedate).add(30, 'days').format('YYYY-MM-DD HH:mm:ss'));
        salesinvoiceRewardDetails.expirydate = salesinvoiceRewardDetails.effectiveto
        salesinvoiceRewardDetails.rewardpoints = this._Salesinvoicepos.applicablerewardpoints;
        salesinvoiceRewardDetails.createdby = sessionStorage["userid"];
        salesinvoiceRewardDetails.createdon = new Date();
        salesinvoiceRewardDetails.rewardstatus = "MSC00302";
        this.SalesinvoiceRewardDetailslist.push(salesinvoiceRewardDetails);
        this._Salesinvoicepos.gatheredrewardpoints = 0
      }
      if (this._Salesinvoicepos.gatheredrewardpoints) {
        let salesinvoiceRewardDetails = new Salesinvoicerewarddetail();
        this.SalesinvoiceRewardDetailslist = [];
        salesinvoiceRewardDetails.salesinvoicerewardid = 0
        salesinvoiceRewardDetails.salesinvoiceid = this._Salesinvoicepos.salesinvoiceid;
        salesinvoiceRewardDetails.salesinvoicedate = this._Salesinvoicepos.salesinvoicedate;
        salesinvoiceRewardDetails.customerid = this._salesinvoiceposform.get("customerid").value;
        salesinvoiceRewardDetails.effectivefrom = this._Salesinvoicepos.salesinvoicedate;
        salesinvoiceRewardDetails.effectiveto = new Date(moment(this._Salesinvoicepos.salesinvoicedate).add(30, 'days').format('YYYY-MM-DD HH:mm:ss'));
        salesinvoiceRewardDetails.expirydate = salesinvoiceRewardDetails.effectiveto
        salesinvoiceRewardDetails.rewardpoints = this._salesinvoiceposform.get("gatheredrewardpoints").value;
        salesinvoiceRewardDetails.createdby = sessionStorage["userid"];
        salesinvoiceRewardDetails.createdon = new Date();
        salesinvoiceRewardDetails.rewardstatus = "MSC00301";
        this.SalesinvoiceRewardDetailslist.push(salesinvoiceRewardDetails);
      }
      if (this._IsDisabledRewardPoints) {
        this._Salesinvoicepos.gatheredrewardpoints = 0
      }
      this._Salesinvoicepos.accountingyear = new Date().getFullYear();
      this._Salesinvoicepos.branchid = sessionStorage["currentbranchid"];
      let userdiscountpercentage = parseFloat(sessionStorage["userdiscountpercentage"] || 0);
      var collectingbranch = this._salesinvoiceposform.get("collectingbranch").value;
      //customer discount approval logic here.
      let isRequestAmountGreater = this._ProductDetails
        .some(f =>
          f.customerdiscountpercentage > 0
          && (f.customerdiscountpercentage > this.GetMax(f.cus_dispercentage, userdiscountpercentage)));
      let customerwisediscount = this._ProductDetails
        .some(f => f.customerdiscountpercentage > 0 && f.cus_dispercentage > 0);
      let schemediscount = this._ProductDetails
        .some(f => f.customerdiscountpercentage > 0 && f.iscshemeapplied);
      let hasDamagedProduct = this._ProductDetails.some(s => s.isdamaged == true);

      if ((isRequestAmountGreater && (!this._Salesinvoicepos.status || this._Salesinvoicepos.status != "MSC00047") || hasDamagedProduct || customerwisediscount || schemediscount)
        || ((parseInt(collectingbranch) != parseInt(sessionStorage["currentbranchid"])) && this._action == 'create')) {
        this._Salesinvoicepos.ispaymentmaded = (this._action == 'edit');
        // this._Salesinvoicepos.status = "MSC00046";
        this._action == 'edit' ? this._Salesinvoicepos.status = this._salesinvoiceposform.get("status").value : this._Salesinvoicepos.status = "MSC00046";
      }
      else {
        this._Salesinvoicepos.status = "MSC00047";
        if (currencyid == 4 && this._Salesinvoicepos.paymenttype != "MSC00251" && customercurncyamount > 0 || (currencyid == 1 && this._Salesinvoicepos.paymenttype != "MSC00146" && customercurncyamount > 0))
          this._Salesinvoicepos.ispaymentmaded = false;
        else
          this._Salesinvoicepos.ispaymentmaded = true;
      }


      if (currencyid == 4 && customercurncyamount > 0 && this._Salesinvoicepos.paymenttype == "MSC00299") {
        this._Salesinvoicepos.ispaymentmaded = false;
      }

      this._Salesinvoicepos.currencyreferenceid = this._salesinvoiceposform.get("currencyreferenceid").value;
      let grossamount = parseFloat(this._Salesinvoicepos.productamount + '' || '0');

      if (grossamount <= 0) {
        this._CustomExceptionService.handleWarning("gross amount should not be in negative or 0.");
        return;
      }

      if (!this.paymentModel.IsPaymentSuccess)
        return;

      if (this._creditnotedetail.length > 0 && parseFloat(this._salesinvoiceposform.get("creditnoteamount").value || 0) == 0) {
        this._CustomExceptionService.handleWarning("Credit Note Amount should be greater than 0");
        return;
      }

      this._Salesinvoicepos.paidamount = parseFloat(this._Salesinvoicepos.paidamount + "") ?? 0;
      this._Salesinvoicepos.balanceamount = parseFloat(this._Salesinvoicepos.balanceamount + "") ?? 0;
      this.isSaveClicked = true;
      let balanceCreditnoteamount = this._Salesinvoicepos.creditnotebalanceamount;
      if (balanceCreditnoteamount > 0) {

        this.creditnotemodel.showdialog = true;
        //this._Salesinvoicepos.returncurrencyid = this._Salesinvoicepos.currencyid;
        this._Salesinvoicepos.returnamount = balanceCreditnoteamount;
        let currencyname = this._Currencies.find(f => f.currencyid == this._Salesinvoicepos.returncurrencyid).currencyname;
        this.creditnotemodel.message = "Did you paid credit note balance amount of <b>(" + currencyname + " - " + balanceCreditnoteamount + ")</b> ? ";
        this.creditnotemodel.Yes = () => {
          this.paymentModel.IsPaymentSuccess = true;
          this.OnSaveDbCall();
          this.creditnotemodel.showdialog = false;
        }
        this.creditnotemodel.Cancel = () => {
          this.creditnotemodel.showdialog = false;
          this.isSaveClicked = false;
        }
      }

      else {
        this.OnSaveDbCall();
      }
    }
  }

  OnSaveDbCall() {
    let otherCharges = this._OtherCharges.filter(f => f.otherchargesamount > 0) || [];
    this._IsProgressSpinner = true;

    if (this._selectedlevel == 1 || this._selectedlevel == 2)//RETAIL 1 AND RETAIL 2
    {
      this.SalesinvoiceRewardDetailslist = this.SalesinvoiceRewardDetailslist || [];
    }
    else {
      this.SalesinvoiceRewardDetailslist = [];
    }

    this._SalesinvoiceposObj = {
      "Salesinvoice": this._Salesinvoicepos, 
      "Salesinvoicedetail": this._ProductDetails, 
      "Salesinvoiceothercharge": otherCharges || [],
      "Salesinvoicecreditdetail": this._creditnotedetail || [], 
      "SalesInvoiceAdvanceReferences": this._salesinvoiceadvancereferences || [],
      "SalesInvoiceRewardDetails": this.SalesinvoiceRewardDetailslist || []
    };
    this._SalesinvoiceposService.create(this._SalesinvoiceposObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
        this._salesinvoiceid = resultJSON.salesinvoiceid;
        this._salesinvoiceno = resultJSON.salesinvoiceno;
        this._salesinvoiceposform.get("salesinvoiceid").setValue(this._salesinvoiceid);
        this._salesinvoiceposform.get("salesinvoiceno").setValue(this._salesinvoiceno);
        this._salesinvoiceposform.get("rv").setValue(resultJSON.rv);
        this._Salesinvoicepos.status = resultJSON.salesinvoicestatus;

        if (this._Salesinvoicepos.status == "MSC00047") {
          // window.open("revmax-service:$" + this._salesinvoiceno);
          this.posPrint();
        }
        else {
          this.reset(null);
        }

        this._IsProgressSpinner = false;
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
      } this._IsProgressSpinner = false;
      this.isSaveClicked = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; this.isSaveClicked = false; });
  }


  GetMax(x: number, y: number): number {
    return x > y ? x : y;
  }

  //Debug
  onEd() {
    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._SalesinvoiceposObj;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);
  }

  goBack(event) {
    if (this.isGobackdisabled) return;
    this._router.navigate(['/vSalesinvoicepos']);
  }

  reset(event) {
    if (!this.paymentModel.IsPaymentSuccess)
      return;
    this._submitted = false;
    this._SalesReferenceno = [];
    this._salesinvoiceposform.reset();
    this._salesproductform.reset();
    this._salesinvoiceposform.enable();
    this._salesproductform.enable();
    this._salesinvoiceposform.get("returnamount").disable();
    this._salesinvoiceposform.get("creditnotebalanceamount").disable();
    this._salesinvoiceposform.get("mobileno").disable();
    this._salesinvoiceposform.get("salesinvoiceno").disable();
    this._salesinvoiceposform.get("creditnoteamount").disable();
    this._salesinvoiceposform.get("customeradvanceamount").disable();
    this._salesinvoiceposform.get("productamount").disable();
    this._salesinvoiceposform.get("taxamount").disable();
    this._salesinvoiceposform.get("amountinusd").disable();
    this._salesinvoiceposform.get("profitamount").disable();
    this._salesinvoiceposform.get("netamountincustomercurrency").disable();
    this._salesinvoiceposform.get("paidamount").disable();
    this._salesinvoiceposform.get("customerbalanceamount").disable();
    this._salesinvoiceposform.get("balanceamount").disable();
    this._salesproductform.get("severity").disable();
    this._salesproductform.get("stockinhand").disable();
    this._salesinvoiceposform.get('cuscurrencyid1').enable();
    this._salesinvoiceposform.get('cuscurrencyamount1').enable();
    this._salesinvoiceposform.get('cuscurrencyid2').enable();
    this._salesinvoiceposform.get('cuscurrencyamount2').enable();
    this._salesinvoiceposform.get("applicablerewardpoints").disable()
    this._salesinvoiceposform.get("remainingpoints").disable()
    this.InitializeForm();
    this._ProductDetails = [];
    this.isDisabledClear = false;
    this.isGobackdisabled = false;
    this.paymentModel = {};
    this.paymentModel.IsPaymentSuccess = true;
    this._creditnoteids = [];
    this._advancereferences = [];
    this._advances = [];
    this._CreditNoteRefernces = [];
    this._SalesInvoiceNumbers = [];
    this.otherchargeid = null;
    this.isSaveClicked = false;
    this.tempcreditnotebalanceamount = 0;
    this._action = "create";
    this.creditnotemodel = {}
  }

  ShowErrorMsg(errMsg: string) {
    if (!errMsg)
      return false;
    this._CustomExceptionService.handleError(errMsg);
    return true;
  }

  OnChangeCreditNote() {

    let id = this._salesinvoiceposform.get("currencyid").value;
    let creditNoteArray = this._creditnoteids || [];
    let amount = 0;
    this._creditnotedetail = [];
    this._salesinvoiceposform.get("creditnoteamount").setValue(0);
    this.Multicurrencycalculation();
    this.DisabledAdvanceOrCredit();
    if (!creditNoteArray.length) return;
    let selectedCreditnotes = this._CreditNoteRefernces.filter(f => this._creditnoteids.includes(f.creditnoteid));
    let vouchers = [];
    selectedCreditnotes.forEach(element => {
      vouchers.push(element.voucherno);
    });

    let data = {
      isCreditnote: true,
      currencyid: id,
      creditnotenos: (vouchers || [])
    };
    this._IsProgressSpinner = true;
    this._SalesinvoiceposService.GetConversion(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._IsProgressSpinner = false;

        selectedCreditnotes.forEach(element => {
          var l_creditnote = new Salesinvoicecreditdetail();
          l_creditnote.creditnoteid = element.creditnoteid;
          l_creditnote.customerid = this._salesinvoiceposform.get("customerid").value || [];
          l_creditnote.salesinvoicecreditdetailid = 0;
          l_creditnote.creditnoteamount = parseFloat(resultJSON.exchangeRate[element.voucherno] || 0) * element.creditamount;
          l_creditnote.salesinvoiceid = this._salesinvoiceposform.get("salesinvoiceid").value;
          l_creditnote.cnvoucherno = element.voucherno;
          l_creditnote.currencyrefid = element.currencyuniquerefid;
          this._creditnotedetail.push(l_creditnote);
        });
        this._salesinvoiceposform.get("creditnoteamount").setValue(this.Fixed2(resultJSON.converionAmount));
        this.Multicurrencycalculation();
        this.DisabledAdvanceOrCredit();
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
      } this._IsProgressSpinner = false;
      this.isSaveClicked = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; this.isSaveClicked = false; });
  }

  OnChangeCreditNoteApplicable() {
    let isApplicable: boolean = <boolean>this._salesinvoiceposform.get("creditnoteapplicable").value;
    if (!isApplicable) {
      this._salesinvoiceposform.get("creditnoteid").reset();
      this._salesinvoiceposform.get("creditnoteamount").reset();
      this._salesinvoiceposform.get("creditnoteinvoiceno").reset();
    }
    this.DisabledAdvanceOrCredit();
    this.TotalCalculation();
  }
  OnChangePaymode() {
    this._toaccounts = [];
    let paymodecode = this._salesinvoiceposform.get("paymenttype").value;
    this._salesinvoiceposform.get("bankledgerid").reset();
    if (paymodecode !== "MSC00146") {
      //this._toaccounts = this._toaccountsTemp;
      this._toaccounts = this._toaccountsTemp.filter(f => f.accountgroupid == 6);
    }
  }
  // else {
  //   this._toaccounts = this._toaccountsTemp.filter(f => f.accountgroupid == 31 && f.partycode == parseInt(sessionStorage["currentbranchid"]));
  // }

  OnchangeOtherCharge(event: any) {
    this._OtherCharges = [];
    if (event.value) {
      var otherchargeamount = (this._ChargesTemp || []).filter(f => f.otherchargeid == event.value)[0].otherchargeamount || 0;
      var l_othercharge = new Salesinvoiceothercharge();
      l_othercharge.salesinvoiceid = this._salesinvoiceposform.get("salesinvoiceid").value;
      l_othercharge.salesinvoiceotherchargesdetailsid = 0;
      l_othercharge.otherchargesid = event.value;
      l_othercharge.otherchargesamount = otherchargeamount;
      l_othercharge.createdby = sessionStorage["userid"];
      l_othercharge.modifiedby = sessionStorage["userid"];
      l_othercharge.createdon = new Date();
      l_othercharge.modifiedon = new Date();
      l_othercharge.accountingyear = new Date().getFullYear();
      this._OtherCharges.push(l_othercharge);

      this._salesinvoiceposform.controls['otherchargesamount'].setValue(parseFloat(otherchargeamount.toFixed(2)));
      this._salesinvoiceposform.get("otherchargesamount").enable();
    } else {
      this._salesinvoiceposform.controls['otherchargesamount'].setValue(0);
      this._salesinvoiceposform.get("otherchargesamount").disable();
    }
    this.TotalCalculation();
  }
  oneditothercharge() {
    // let amunt = this._salesinvoiceposform.get("otherchargesamount").value.replace(/[^0-9][^.]/g, '');
    // let amunt = this._salesinvoiceposform.get("otherchargesamount").value;
    // if(amunt.length < 10){
    //   this._salesinvoiceposform.controls['otherchargesamount'].setValue(amunt);
    //   this.TotalCalculation();
    // }else{

    // }
    this.TotalCalculation();
  }

  OnChangeSalesInvoiceNo(event) {
    this._CreditNoteRefernces = [];
    this._SalesinvoiceposService.GetCreditNoteNos(event.value).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      //this._CreditNoteRefernces = resultJSON.creditnotereferenceno;
      this._CreditNoteRefernces = <Creditnoteview[]>resultJSON.creditnotereferenceno;
      this._TempCreditNoteRefernces = <Creditnoteview[]>resultJSON.creditnotereferenceno;
      var currencyid = this._salesinvoiceposform.get("currencyid").value;
      this._CreditNoteRefernces = this._TempCreditNoteRefernces.filter(f => f.currentcurrencyid == currencyid) || [];
    });
  }

  //Calculate Balance Amount
  CalculateBalanceAmount() {
    this.TotalCalculation()
  }

  filterProducts(event: { query: string; }) {
    this._IsProgressSpinner = true;
    let branchid = sessionStorage["currentbranchid"];
    let categoryids = this.productcatid || null;
    let isDamaged = (this._salesproductform.get("isdamaged").value == true ||
      this._salesproductform.get("isdamaged").value == 1);
    let severity = this._salesproductform.get("severity").value;
    let currency = this._salesinvoiceposform.get("currencyid").value;
    //this.filteredProducts = [];
    //this._IsProgressSpinner = true;
    let data = {
      product: event.query,
      branchid: branchid,
      category: [],
      isDamagedProduct: isDamaged,
      severity: severity,
      currency: currency,
      postax: 0,
      quicksales: 0
    }

    // if(event.query){
    this._SalesInvoiceTaxService.GetProduct(data).subscribe((result: any) => {
      this.filteredProducts = <Productoverallview[]>result.productsearch || [];
      this._ProductCategories = result.productcategories;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  CalculateRequestdiscount(row: Salesinvoiceproductpriceview) {
    row.discountamount = 0;
    let taxAmount = (row.productamount * (row.taxpercentage / 100));
    let finalAmount = row.productamount;
    var userdiscount = parseInt(sessionStorage["userdiscountpercentage"]);
    if (row.customerdiscountpercentage > row.maximumdiscountpercentage) {
      this._CustomExceptionService.handleWarning("Customer discount percentage should not greater than product discount percentage");
      row.customerdiscountpercentage = 0;
      return;
    }

    if (row.customerdiscountpercentage > row.discountpercentage || row.customerdiscountpercentage > userdiscount) {

      row.customerdiscountamount = (row.productamount * (row.customerdiscountpercentage / 100));
      finalAmount = (row.productamount) - row.customerdiscountamount;

      row.finalamount = finalAmount;
      this.TotalCalculation();
      //errorMsg = 'Request discount not exceeds to User applicable markup level --> ' + row.maximumdiscountpercentage;
      this.confirmationService.confirm({
        message: 'Your discount percentage exceeds allocated percentage.so this document go for approval<b>"' + row.customerdiscountpercentage + '"</b> ?',
        accept: () => {
          //row.productdiscountpercentage = 0;
          row.customerdiscountamount = (row.productamount * (row.customerdiscountpercentage / 100));
          finalAmount = (row.productamount) - row.customerdiscountamount;
          row.finalamount = finalAmount;
          this.TotalCalculation();
        },
        reject: () => {
          row.customerdiscountpercentage = 0;
          row.customerdiscountamount = (row.productamount * (row.customerdiscountpercentage / 100));
          finalAmount = (row.productamount) - row.customerdiscountamount;
          row.finalamount = finalAmount;
          this.TotalCalculation();

          if (row.customerdiscountpercentage <= 0) {
            row.customerdiscountamount = 0;
            row.discountamount = (row.productamount * (row.discountpercentage / 100));
            finalAmount = (row.productamount) - row.discountamount;

            row.finalamount = finalAmount;
            this.TotalCalculation();
          }
        }
      });
    }

    row.customerdiscountamount = (row.productamount * (row.customerdiscountpercentage / 100));
    finalAmount = (row.productamount) - row.customerdiscountamount;
    row.finalamount = finalAmount;
    this.TotalCalculation();

    if (row.customerdiscountpercentage <= 0) {
      row.discountamount = (row.productamount * (row.discountpercentage / 100));
      finalAmount = (row.productamount) - row.discountamount;

      row.finalamount = finalAmount;
      this.TotalCalculation();
    }
  }

  OnChangeUnitPrice(_ProductDetail: Salesinvoiceproductpriceview) {
    let currencyid = this._salesinvoiceposform.get("currencyid").value;
    let referenceno = this._salesinvoiceposform.get("salesreferenceno").value;
    _ProductDetail.unitprice = parseFloat(_ProductDetail.unitprice + '');
    if (_ProductDetail.unitprice < _ProductDetail.tempunitprice) {
      this._CustomExceptionService.handleWarning("Unit price should not less than actual price.");
      _ProductDetail.unitprice = _ProductDetail.tempunitprice;
    }

    if (!referenceno) {
      switch (currencyid) {
        case 2:
          _ProductDetail.rand = _ProductDetail.unitprice;
          break;
        case 3:
          _ProductDetail.bond = _ProductDetail.unitprice;
          break
        case 4:
          _ProductDetail.bank = _ProductDetail.unitprice;
          break;
        default:
        case 1:
          _ProductDetail.usd = _ProductDetail.unitprice;
          break
      }
    }

    this.allocatequantity(_ProductDetail);
  }

  allocatequantity(row: Salesinvoiceproductpriceview) {
    if (row.customerdiscountpercentage < 0) {
      row.customerdiscountpercentage = 0;
      return;
    }
    let referenceno = this._salesinvoiceposform.get("salesreferenceno").value;
    let userdiscountpercentage = parseFloat(sessionStorage["userdiscountpercentage"] || 0);
    let customerid = this._salesinvoiceposform.get("customerid").value;
    let customer = this._Customernames.find(f => f.customerid == customerid);

    if (row.disallownegativeqty && row.quantity > row.stocktemp) {
      this._CustomExceptionService.handleWarning("Available stock at this branch " + row.stocktemp);
      row.quantity = row.stocktemp;
    }
    if (row.quantity > row.stocktemp) {
      this._salesinvoiceposform.get("deliveryrequired").setValue(true);
    }
    let currencyid = this._salesinvoiceposform.get("currencyid").value;
    let userSelectedCurrency = this._Currencies.find(f => f.currencyid == currencyid);
    let userselectedExchangeRate = userSelectedCurrency.exchangerate;
    var unitPrice = 0;
    if (!referenceno)
      if (row.vattype == "MSC00062") {
        var unitPrice1 = userselectedExchangeRate * row.tempunitprice;
        unitPrice = currencyid == 1 ? row.usd : currencyid == 2 ? row.rand : currencyid == 3 ? row.bond : currencyid == 4 ? row.bank : userselectedExchangeRate * row.usd;
      }
      else {
        unitPrice = currencyid == 1 ? row.usd : currencyid == 2 ? row.rand : currencyid == 3 ? row.bond : currencyid == 4 ? row.bank : userselectedExchangeRate * row.usd;
      }
    else {
      unitPrice = row.usd;
    }
    row.rewardpointsapplied = 0;
    let scheme_discount = 0;
    let cus_dispercentage = 0;
    let applyScheme = false;
    //Scheme
    if (row.iscshemeavailable) {
      // if (row.minimumqty > 0 && row.maximumqty > 0) {
      //   if (row.quantity >= row.minimumqty && row.quantity <= row.maximumqty)
      //     applyScheme = true;
      // } else if (row.minimumqty > 0) {
      //   if (row.quantity >= row.minimumqty)
      //     applyScheme = true;
      // } else if (row.maximumqty > 0) {
      //   if (row.quantity <= row.maximumqty)
      //     applyScheme = true;
      // }
      if (row.minimumqty > 0) {
        if (row.quantity >= row.minimumqty)
          applyScheme = true;
      }
      if (applyScheme) {
        scheme_discount = row.sch_discountpercentage;
        row.rewardpointsapplied = row.rewardpoints;
      }
      row.iscshemeapplied = applyScheme;
    }
    //Not a Scheme
    if (!row.iscshemeavailable || !applyScheme) {
      if (row.customerdiscountpercentage > row.maximumdiscountpercentage) {
        row.customerdiscountpercentage = 0;
        this._CustomExceptionService.handleWarning("Not a applicable discount.");
      } else {
        let maxdiscount = row.cus_dispercentage > userdiscountpercentage ? row.cus_dispercentage : userdiscountpercentage;
        //User DiscountPercentage
        if ((row.customerdiscountpercentage > userdiscountpercentage)) {
          this._CustomExceptionService.handleWarning("This document sent for approval since requested discount greater that user discount.")
        }
        // else if (row.cus_dispercentage > 0 && row.customerdiscountpercentage > row.cus_dispercentage) {
        //   this._CustomExceptionService.handleWarning("This document sent for approval since requested discount greater that customer discount.")
        // }
        //Customer Wise Discount
        else if (row.cus_dispercentage > 0) {
          // this._CustomExceptionService.handleWarning("This Document Sent for Approval Since the requested Customer Discount is grater than Product Discount");
          row.customerdiscountpercentage = row.cus_dispercentage;
        }
        //Manual Enter Discount
        else {
          cus_dispercentage = row.customerdiscountpercentage
        }
        cus_dispercentage = row.customerdiscountpercentage;
      }
    }
    let customerwisedis = 0;
    if (!cus_dispercentage) {
      customerwisedis = row.discountpercentage;
    }
    if (row.vattype == "MSC00062") {
      var productAmount1 = row.quantity * unitPrice1;
      var productAmount = row.quantity * unitPrice;
    }
    else {
      productAmount = row.quantity * unitPrice;
    }
    let app_discountamount = (productAmount * (cus_dispercentage / 100)) || 0;
    let cuswise_discountamount = (productAmount * (customerwisedis / 100)) || 0;
    let def_discountamount = 0;
    let sch_discountamount = (productAmount * (scheme_discount / 100)) || 0;
    let totalDiscounts = 0;

    //Scheme Discounts applicable created by rajesh
    if ((row.iscshemeavailable || applyScheme) && scheme_discount > 0 && (row.sch_discountpercentage > row.cus_dispercentage)) {
      totalDiscounts = sch_discountamount;
      row.customerdiscountamount = sch_discountamount;
      row.customerdiscountpercentage = row.sch_discountpercentage;
      this._CustomExceptionService.handleWarning("This Document Sent for Approval Since the applied Scheme Discount Greater than Product Discount");
    }
    //Customer Wise Discount
    else if (row.cus_dispercentage > 0 && (row.cus_dispercentage > row.sch_discountpercentage)) {
      totalDiscounts = (productAmount * (row.cus_dispercentage / 100)) || 0;
      row.customerdiscountamount = totalDiscounts
      row.customerdiscountpercentage = row.cus_dispercentage;
      row.iscshemeapplied = false;
      this._CustomExceptionService.handleWarning("This Document Sent for Approval Since the requested Customer Discount is grater than Product Discount");
    }
    // else if(cus_dispercentage>0){
    //   totalDiscounts = (productAmount * (cus_dispercentage / 100)) || 0;
    //   row.customerdiscountamount = totalDiscounts
    // }
    //Manual Discount
    else if (!row.iscshemeavailable) {
      totalDiscounts = (def_discountamount + app_discountamount + sch_discountamount + cuswise_discountamount)
      row.customerdiscountamount = totalDiscounts
    }
    // VAT applicable based on product
    var taxAmount = 0;
    if (row.vattype == "MSC00062") {
      var productwithDiscountAmount1 = productAmount1 - totalDiscounts;
      var productwithDiscountAmount = productAmount - totalDiscounts;
      if (row.vatapplicable)
        if (!referenceno)
          taxAmount = (productwithDiscountAmount1 * (row.taxpercentage / 100));
        else
          taxAmount = row.taxamount;
    }
    else {
      productwithDiscountAmount = productAmount - totalDiscounts;
      if (row.vatapplicable)
        if (!referenceno)
          taxAmount = (productwithDiscountAmount * (row.taxpercentage / 100));
        else
          taxAmount = row.taxamount;
    }
    row.stock = (row.stocktemp - row.quantity);
    row.productamount = productAmount;
    row.discountamount = def_discountamount;

    // row.customerdiscountamount = app_discountamount;
    row.taxamount = taxAmount;
    let finalAmount = (productAmount) - totalDiscounts;
    row.finalamount = finalAmount;
    row.totaldiscountamountapplied = totalDiscounts;
    if (customer.vattype == "MSC00063") {
      row.finalamount += taxAmount;
    }
    if (!applyScheme) {
      this.CalculateRewardPoints(row);
    }
    //Update the row object in main List
    let existingProductIndex = this._ProductDetails.findIndex(f => f.productid == row.productid && f.variantid == row.variantid && f.producttype == row.producttype && f.packtype == row.packtype);
    this._ProductDetails[existingProductIndex] = row;
    this.TotalCalculation();
  }

  confirm(message: string, isRetryHidden: boolean = true) {
    this.paymentModel.visiblePaymentPopup = true;
    this.paymentModel.Message = message;
    this.paymentModel.IsPaymentSuccess = false;
    this.paymentModel.IsRetryEnable = isRetryHidden;
    this.paymentModel.isHideCancelRequestButton = true;

    this.paymentModel.Retry = () => {
      this.paymentModel.isHideCancelRequestButton = true;
      this.posPrint();
    };

    this.paymentModel.CancelInvoice = () => {
      this.paymentModel.isHideCancelRequestButton = true;
      this.CancelInvoice();
      this.paymentModel.IsPaymentSuccess = true;
      this.paymentModel.visiblePaymentPopup = false;
    };

    this.paymentModel.CancelRequest = () => {
      //this.paymentModel.isHideCancelRequestButton = false;
      this.CancelPendingRequestPaymentGateWay();
      this._IsProgressSpinner = false;
      this.confirm('Request Cancelled by user.', false);
    };

    if (isRetryHidden) {
      setTimeout(() => {
        this.paymentModel.isHideCancelRequestButton = false;
      }, 10000);
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  onWindowClose(event: any): void {
    if (!this.paymentModel.IsPaymentSuccess) {
      event.returnValue = false;
      event.preventDefault();
    }
  }

  CancelPendingRequestPaymentGateWay() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  CancelInvoice() {

    let branchid = this._salesinvoiceposform.get("collectingbranch").value;
    let salesinvoiceno = this._salesinvoiceposform.get("salesinvoiceno").value;
    let salesinvoiceid = this._salesinvoiceposform.get("salesinvoiceid").value;

    if (!salesinvoiceno) return;

    this._IsProgressSpinner = true;
    this._SalesinvoiceposService.Cancel(salesinvoiceno, branchid, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._CustomExceptionService.handleSuccess(usererrors.Cancel_Success_00);
      // this._salesinvoiceposform.get("salesinvoiceid").reset();
      // this._salesinvoiceposform.get("salesinvoiceno").reset();
      // this._salesinvoiceposform.get("rv").reset();
      this.reset(null);
      this.paymentModel.IsPaymentSuccess = true; // when its true then only put new invoice.
      this._IsProgressSpinner = false;
    });
  }

  // posPrint() {
  //   let salesAmount = this._salesinvoiceposform.get("netamountincustomercurrency").value;

  //   let paymentType = this._salesinvoiceposform.get("paymenttype").value;
  //   let isBank = (paymentType != "MSC00146" && paymentType != "MSC00251");
  //   let userid = sessionStorage["userid"];
  //   let username = sessionStorage["username"];
  //   this.paymentModel.IsPaymentSuccess = true;
  //   if (isBank && salesAmount > 0) {
  //     let isUPI = (paymentType == "MSC00149");
  //     this.paymentModel.IsPaymentSuccess = false;
  //     let usdcurrencyExr = this._Currencies.find(f => f.currencyid == 1).exchangerate;
  //     salesAmount = (usdcurrencyExr * salesAmount).toString();

  //     let doller = 0, cent = 0;

  //     if (salesAmount.indexOf('.') != -1) {
  //       doller = parseInt(salesAmount.split('.')[0]);
  //       cent = parseInt((<string>salesAmount.split('.')[1]).substr(0, 2));
  //     }
  //     else {
  //       doller = parseInt(salesAmount);
  //     }

  //     let cents = (doller * 100) + cent;

  //     let obj = {
  //       saleAmount: cents.toString(),
  //       cashBack: "0",
  //       posUser: username,
  //       tenderType: "SWIPE",
  //       currency: "RTGS",
  //       transactionId: this._salesinvoiceno
  //     };
  //     if (isUPI) // for eco cash.
  //     {
  //       obj.tenderType = "MOBILE";
  //     }
  //     console.log("Swiping Card Request: ", obj);
  //     this._IsProgressSpinner = true;

  //     let message = '<i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i> Waiting for response. please dont close tab or browser.';
  //     this.posDialogNgTemplateVisible = true;
  //     //this.confirm(message);

  //     // this._SalesinvoiceposService.GetPaymentApiDetails(obj).pipe(takeUntil(this.ngUnsubscribe)).subscribe((p_result: any) => {
  //     //   console.log(p_result);
  //     //   let json = JSON.stringify(p_result);
  //       let data = {
  //         salesinvoiceno: this._salesinvoiceno,
  //         paymentstatus: false,
  //         code: '00',
  //         jsonData: '',//JSON.stringify(p_result),
  //         ref: ''
  //       }
  //       //if (p_result && p_result.code == "00") {
  //         data.paymentstatus = true;
  //       //}
  //       this._SalesinvoiceposService.UpdatePaymentStatus(data).subscribe((result: any) => {
  //         // if (data.paymentstatus) {
  //         //   if (isUPI) {
  //         //     this._CustomExceptionService.handleSuccess("IMEI :" + p_result.imei + "\n" + p_result.description);
  //         //   } else {
  //         //     this._CustomExceptionService.handleSuccess("PAN :" + p_result.pan + "\n" + p_result.description);
  //         //   }
  //         //   window.open("pos-printer-service:$" + this._salesinvoiceid + "$" + this._salesinvoiceno + "$" + userid + "$" + json);
  //         //   this.paymentModel.IsPaymentSuccess = true;
  //         //   this.reset(null);
  //         // } else {
  //         //   message = '<i class="pi pi-times" style="font-size: 2rem"></i> Payment failed with ' + p_result.description + '.';
  //         //   this.confirm(message, false);
  //         //   this._CustomExceptionService.handleError(p_result.description);
  //         // }
  //         this._IsProgressSpinner = false;
  //       });
  //     // }, error => {
  //     //   let data = {
  //     //     salesinvoiceno: this._salesinvoiceno,
  //     //     paymentstatus: false,
  //     //     code: "",
  //     //     jsonData: JSON.stringify(error),
  //     //     ref: ""
  //     //   }
  //     //   this._SalesinvoiceposService.UpdatePaymentStatus(data).subscribe((result: any) => {
  //     //     this.paymentModel.IsPaymentSuccess = false;
  //     //     this._CustomExceptionService.handleError(error);
  //     //     this._IsProgressSpinner = false;
  //     //     //this.reset(null);
  //     //     message = '<i class="pi pi-times" style="font-size: 2rem"></i> Payment failed.';
  //     //     this.confirmationService.close();
  //     //     this.confirm(message, false);
  //     //   });
  //     // });
  //   } else {
  //     //<salesinvoiceid>$<salesinvoiceno>$<userid>$<json>
  //     // let result = {
  //     //   code : "00",
  //     //   description : "Transaction Approved.",
  //     //   pan : "66474363xxxxxxx6647",
  //     //   transactionId : "SIT-00083"
  //     // };
  //     // let json = JSON.stringify(result);
  //     window.open("pos-printer-service:$" + this._salesinvoiceid + "$" + this._salesinvoiceno + "$" + userid);
  //     this.reset(null);
  //   }
  // }

  posPrint() {
    let salesAmount = this._salesinvoiceposform.get("netamountincustomercurrency").value;

    let paymentType = this._salesinvoiceposform.get("paymenttype").value;
    let isBank = (paymentType != "MSC00146" && paymentType != "MSC00251");
    let userid = sessionStorage["userid"];
    let username = sessionStorage["username"];
    this.paymentModel.IsPaymentSuccess = true;


    if (this._Salesinvoicepos.paymenttype == "MSC00299") {
      this._router.navigate(['/salesMultiSwipe'], {
        state:
        {
          branchid: this._branchid,
          salesinvoiceno: this._salesinvoiceno
        }
      });
      return
    };

    if (isBank && salesAmount > 0) {
      let isUPI = (paymentType == "MSC00149");
      this.paymentModel.IsPaymentSuccess = false;
      let usdcurrencyExr = this._Currencies.find(f => f.currencyid == 1).exchangerate;
      salesAmount = (usdcurrencyExr * salesAmount).toString();

      let doller = 0, cent = 0;

      // if (salesAmount.indexOf('.') != -1) {
      //   doller = parseInt(salesAmount.split('.')[0]);
      //   cent = parseInt((<string>salesAmount.split('.')[1]).substr(0, 2));
      // }
      // else {
      //   doller = parseInt(salesAmount);
      // }

      doller = salesAmount;

      let cents = (doller * 100).toFixed(0);

      var currencys = this._salesinvoiceposform.get("currencyid").value;
      var type: string = ""
      if (currencys == 1) {
        type = "USD"
      }
      else {
        type = "ZWG"
      }


      let obj = {
        saleAmount: cents.toString(),
        cashBack: "0",
        posUser: username,
        tenderType: "SWIPE",
        currency: type,
        transactionId: this._salesinvoiceno
      };
      if (isUPI) // for eco cash.
      {
        obj.tenderType = "MOBILE";
      }
      this._IsProgressSpinner = true;

      let message = '<i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i> Waiting for response. please dont close tab or browser.';
      this.posDialogNgTemplateVisible = true;
      this.confirm(message);
      this._SalesinvoiceposService.GetPaymentApiDetails(obj).pipe(takeUntil(this.ngUnsubscribe)).subscribe((p_result: any) => {
        let json = JSON.stringify(p_result);
        let data = {
          salesinvoiceno: this._salesinvoiceno,
          paymentstatus: false,
          code: p_result.code,
          jsonData: JSON.stringify(p_result),
          ref: p_result.ref
        }
        if (p_result && p_result.code == "00") {
          data.paymentstatus = true;
        }
        this._SalesinvoiceposService.UpdatePaymentStatus(data).subscribe((result: any) => {
          if (data.paymentstatus) {
            if (isUPI) {
              this._CustomExceptionService.handleSuccess("IMEI :" + p_result.imei + "\n" + p_result.description);
            } else {
              this._CustomExceptionService.handleSuccess("PAN :" + p_result.pan + "\n" + p_result.description);
            }
            var Url = window.open("pos-printer-service:$" + this._salesinvoiceid + "$" + this._salesinvoiceno + "$" + userid + "$" + json);
            setTimeout(() => {
              Url.close();
            }, 10000);
            this.paymentModel.IsPaymentSuccess = true;
            this.reset(null);
          } else {
            message = '<i class="pi pi-times" style="font-size: 2rem"></i> Payment failed with ' + p_result.description + '.';
            this.confirm(message, false);
            this._CustomExceptionService.handleError(p_result.description);
          }
          this._IsProgressSpinner = false;
        });
      }, error => {
        let data = {
          salesinvoiceno: this._salesinvoiceno,
          paymentstatus: false,
          code: "",
          jsonData: JSON.stringify(error),
          ref: ""
        }
        this._SalesinvoiceposService.UpdatePaymentStatus(data).subscribe((result: any) => {
          this.paymentModel.IsPaymentSuccess = false;
          this._CustomExceptionService.handleError(error);
          this._IsProgressSpinner = false;
          //this.reset(null);
          message = '<i class="pi pi-times" style="font-size: 2rem"></i> Payment failed.';
          this.confirmationService.close();
          this.confirm(message, false);
        });
      });
    } else {
      //<salesinvoiceid>$<salesinvoiceno>$<userid>$<json>
      // let result = {
      //   code : "00",
      //   description : "Transaction Approved.",
      //   pan : "66474363xxxxxxx6647",
      //   transactionId : "SIT-00083"
      // };
      // let json = JSON.stringify(result);
      var Url = window.open("pos-printer-service:$" + this._salesinvoiceid + "$" + this._salesinvoiceno + "$" + userid);
      setTimeout(() => {
        Url.close();
      }, 10000);
      this.reset(null);
    }
  }

  FetchProductUsingBarcode() {

    if (this.IsDisabledLayBuy) return;
    this._Variants = [];
    setTimeout(() => {
      let barcodevalue = this._salesproductform.get("productbarcode").value;
      if (!barcodevalue) {
        this._salesproductform.get("productbarcode").reset();
        return;
      }
      barcodevalue = barcodevalue.trim();

      let isnormalbarcode: string = barcodevalue.substring(0, 2)

      if (isnormalbarcode != "99")// Normal Product
      {
        var selectedbranch = this._salesinvoiceposform.get("collectingbranch").value;
        let severity = this._salesproductform.get("severity").value;
        let isDamaged = (this._salesproductform.get("isdamaged").value == true ||
          this._salesproductform.get("isdamaged").value == 1);
        this._SalesInvoiceTaxService.GetBarCodeBasedProductDetails({ "barcode": barcodevalue, "branchid": this._branchid, "Collectingbranchid": selectedbranch, "severity": severity, postax: 0 }).subscribe((result: any) => {

          this._productBarcodesearch = result.productbarcodesearch || [];
          if (isDamaged) {
            this._salesproductform.controls['stockinhand'].setValue(0);
            if (result.collectingBranchDamagedStock) {
              this._salesproductform.controls['stockinhand'].setValue(result.collectingBranchDamagedStock.damagedstock);
            }
          } else {
            if (!result.collectingBranchStock) {
              this._salesproductform.controls['stockinhand'].setValue(0);
            }
            else {
              this._Branch = result.collectingBranchStock.stock1 || 0;
              this._salesproductform.controls['stockinhand'].setValue(this._Branch);
            }
          }
          let product: any = this._productBarcodesearch.find(f => f.barcode == barcodevalue);
          if (product) {
            this._salesproductform.get("productid").setValue(product);
            let productcategory: Productcategory = new Productcategory();
            productcategory.productcategoryid = product.productcategoryid;
            productcategory.producttypeid = product.producttypeid + '';
            productcategory.categoryname = product.categoryname;
            this._ProductCategories.push(productcategory);

            this.filteredProducts.push(product);
            this.GetVariantName();
            this._salesproductform.get("productbarcode").reset();
            this._salesproductform.controls["quantity"].setValue(1);
            this._packtype = product.packtype;
            this.GetProductPrice(1)
            $("input[name='productbarcode']").focus();
            this._packtype = "MSC00365";
          }
          else {
            this._CustomExceptionService.handleWarning("Product may not available at this branch");
            this._salesproductform.get("productbarcode").reset();
          }
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
      else {
        this.weighingproductBarcode();
      }
    }, 400)
  }

  showBasicDialog() {
    this.ManageDialog = true;
  }

  //Customers Quick save
  Save() {
    this._customersubmitted = true;
    if (this._customerform.valid) {
      let _Customer = this._customerform.value;
      if (!_Customer.customername || (_Customer.customername.trim()) === "") {
        this._CustomExceptionService.handleWarning("Customer Name Cannot Be Empty.Please Enter Customer Name")
        return
      }
      _Customer.customerid = 0;
      _Customer.currencyid = 1;
      _Customer.createdon = new Date();
      _Customer.createdby = sessionStorage["userid"];
      _Customer.levelid = sessionStorage["levelid"];
      _Customer.branchid = sessionStorage["currentbranchid"];

      //this.onEd();
      this._IsProgressSpinner = true;
      this._SalesInvoiceTaxService.customercreate(_Customer).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._Customernames = resultJSON.customers;
        if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
          this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
          this.Cancel();
          this._IsProgressSpinner = false;
        }
        else {
          this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          this._IsProgressSpinner = false;
        }
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  Cancel() {
    this._customersubmitted = false;
    this.ManageDialog = false;
    this._customerform.reset();
    this.InitializeCustomerForm();
  }

  GetUserAmount(): number {

    if (this._action == 'create') {
      this.currency2calculation();
    }

    let currencyamnt2: number = 0, currencyamnt1: number = 0;

    let currencyid = this._salesinvoiceposform.get("currencyid").value;
    let currencyid1 = this._salesinvoiceposform.get("cuscurrencyid1").value;
    let currencyid2 = this._salesinvoiceposform.get("cuscurrencyid2").value;
    currencyamnt1 = this._salesinvoiceposform.get("cuscurrencyamount1").value;
    currencyamnt2 = this._salesinvoiceposform.get("cuscurrencyamount2").value;

    let usercurrencyamount = 0;
    if (currencyid == currencyid1) {
      usercurrencyamount += currencyamnt1;
    }
    else {
      usercurrencyamount += parseFloat(((this.GetExchangeRate(currencyid) / this.GetExchangeRate(currencyid1)) * currencyamnt1).toFixed(2));
    }

    if (currencyid2 && currencyamnt2) {
      if (currencyid == currencyid2) {
        usercurrencyamount += currencyamnt2;
      }
      else {
        usercurrencyamount += parseFloat(((this.GetExchangeRate(currencyid) / this.GetExchangeRate(currencyid2)) * currencyamnt2).toFixed(2));
      }
    }

    return usercurrencyamount;
  }


  async CopyInvoice() {

    if (!this._copyinvoiceno) {
      this._CustomExceptionService.handleWarning("Please enter invoice no to copy.")
      return;
    };
    this.reset(null);

    let data = {
      userid: sessionStorage["userid"],
      branchid: sessionStorage["currentbranchid"],
      invoiceno: this._copyinvoiceno
    }

    this._IsProgressSpinner = true;
    this._SalesInvoiceTaxService.CopyInvoice(data).subscribe(async (result: any) => {
      if (result && result.tranStatus.result == true) {
        let salesinvoice = (<Salesinvoiceview>result.salesinvoiceview);
        this.GetCustomerForCopyInvoice(salesinvoice.customerid);
        salesinvoice.salesinvoicedate = new Date(salesinvoice.salesinvoicedate);
        delete salesinvoice.customername;
        delete salesinvoice.statusname;
        delete salesinvoice.series;
        delete salesinvoice.ispaymentmadedtext;
        this._toaccounts = result.toaccounts;
        this._Descriptions = result.othercharges;
        this._salesinvoiceposform.setValue(salesinvoice);
        this.otherchargeid = salesinvoice.otherchargeid;

        if (salesinvoice.salesreferenceno) {
          this.GetReferenceNo({ value: salesinvoice.salesreference });
        }
        var selectedbranch = this._salesinvoiceposform.get("collectingbranch").value;
        let markuplevelid = sessionStorage["levelid"];

        let customer = this._salesinvoiceposform.get("customerid").value;

        if (!salesinvoice.salesreferenceno) {
          let response = [];
          let roleid = sessionStorage["currentRole"];
          for (var i = 0; i < (result.salesinvoiceproductspriceview || []).length; i++) {
            let product = <Salesinvoiceproductpriceview>result.salesinvoiceproductspriceview[i];
            response[i] = this._SalesinvoiceposService.GetProductPrice(product.productid, product.variantid, product.quantity, selectedbranch, customer, this._userid, markuplevelid, product.isdamaged, (product.severity || null), roleid, this._branchid, this._packtype).toPromise();
          }
          response.forEach((element: Promise<any>) => {
            this.GetProductPriceForCopyInvoice(element);
          });
        } else {
          this._salesinvoiceposform.get("salesreferenceno").setValue(salesinvoice.salesreferenceno);
          this.GetReferenceDetails({ value: salesinvoice.salesreferenceno });
        }
        if (salesinvoice.currencyid == 4) {
          this._PaymentModes = this._TempPaymentModes.filter(f => f.metasubid != 146)
        }
        else {

          this._PaymentModes = this._TempPaymentModes.filter(f => f.metasubid == 146);
        }
        this._copyinvoiceno = "";
      }
      else {
        this._CustomExceptionService.handleError(result.tranStatus.lstErrorItem[0].message);
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  GetProductPriceForCopyInvoice(response: Promise<any>) {
    this._IsProgressSpinner = true;
    response.then((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        //this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
        this.AddProductToGrid(resultJSON, 'salesproductprice');
        this._salesinvoiceposform.controls['markuplevelid'].setValue(resultJSON.salesproductprice[0].markuplevel);
        this._IsProgressSpinner = false;
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
        this._IsProgressSpinner = false;
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  async GetCustomerForCopyInvoice(customerid) {
    await this._SalesInvoiceTaxService.GetCustomerDetails(customerid).toPromise().then((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      this._salesinvoiceposform.get("currencyid").enable();
      let customer = <Salescustomerdetailsview>(resultJSON.customerdetails);
      this._customer = customer;
      // this._salesinvoiceposform.controls['currencyid'].setValue(customer.currencyid);
      // this._salesinvoiceposform.controls['cuscurrencyid1'].setValue(this._customer.currencyid);
      // var referenceid = this._Currencies.filter(f => f.currencyid == customer.currencyid)[0].currencyuniquerefid
      // this._salesinvoiceposform.controls['currencyreferenceid'].setValue(referenceid);
      this._selectedlevel = customer.levelid;
      this._Markuplevels = resultJSON.levels;
      this._salesinvoiceposform.controls['markuplevelid'].setValue(customer.levelid);
      this._salesinvoiceposform.controls['mobileno'].setValue(customer.mobileno);
      this._salesinvoiceposform.controls['mailid'].setValue(customer.mailid);
      this._salesinvoiceposform.controls['remainingpoints'].setValue(customer.rewardpoints);
      this._RemainingPoints = customer.rewardpoints;
      this._advancereferences = resultJSON.receiptadvancereferenceviewlist || [];
      this._IsProgressSpinner = false;
    }).catch((error: any) => {
      this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false;
    });
  }


  //customer filter:
  filtercustomer(event) {
    if (event.query == "" || event.query == undefined) {
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._SalesinvoiceposService.GetCustomer(event.query, this._userid).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._Customernames = <Customer[]>resultJSON.customers || [];
        let query = (<string>event.query).toLowerCase();
        this.filteredcustomer = this._Customernames.filter(f => f.customername.toLowerCase().indexOf(query) > -1) || [];
        this._IsProgressSpinner = false;
        if (this._Customernames && this._Customernames.length) {
          let userid = sessionStorage["userid"];
          let customer = this._Customernames.find(f => f.userid == userid);
          if (customer && this._action == 'create') {
            let events = { value: customer.customerid };
            this._salesinvoiceposform.get("customerid").setValue(customer);
            this.GetCustomer(events);
          }
        }
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }

  //customer filter:
  Intfiltercustomer(event) {
    if (event == "" || event == undefined) {
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._SalesinvoiceposService.GetCustomer(event, this._userid).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._Customernames = <Customer[]>resultJSON.customers || [];
        let query = (<string>event).toLowerCase();
        this.filteredcustomer = this._Customernames.filter(f => f.customername.toLowerCase().indexOf(query) > -1) || [];
        this._IsProgressSpinner = false;
        if (this._Customernames && this._Customernames.length) {
          let userid = sessionStorage["userid"];
          let customer = this._Customernames.find(f => f.userid == userid);

          if (customer && this._action == 'create') {
            let events = { value: customer.customerid };
            this._salesinvoiceposform.get("customerid").setValue(customer);
            this.GetCustomer(events);
          }
        }
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }

  public ngOnDestroy(): void {
    this.CancelPendingRequestPaymentGateWay();
  }

  //Reward Ponits Applicable
  OnChangeRewardPointApplicable(event: { checked: boolean; }) {

    if (event.checked == true) {
      this._salesinvoiceposform.get("applicablerewardpoints").enable();
      if (this.totalremainingpoints) {
        this.dispalyBasicReward = true;
      }
    }
    else if (event.checked == false) {
      this._salesinvoiceposform.get("applicablerewardpoints").disable();
      this._salesinvoiceposform.get("applicablerewardpoints").reset();
      this._salesinvoiceposform.controls["remainingpoints"].setValue(this._RemainingPoints.toFixed(2));
    }
    this.TotalCalculation();
  }

  // For Reward Point Calculation:
  allocateRewardPoints(event: any) {
    var applicablerewardpoints = this._salesinvoiceposform.get("applicablerewardpoints").value;
    this._salesinvoiceposform.get("applicablerewardpoints").setValue(0);
    this.TotalCalculation();
    this._salesinvoiceposform.get("applicablerewardpoints").setValue(applicablerewardpoints);
    var netamountinusd = parseFloat(this._salesinvoiceposform.get('amountinusd').value || 0);
    if (applicablerewardpoints > this._RemainingPoints) {
      this._CustomExceptionService.handleWarning("Applicable Reward Points Should be Less Than Remaining Points");
      this._salesinvoiceposform.get('applicablerewardpoints').reset();
      this._salesinvoiceposform.controls['remainingpoints'].setValue(this._RemainingPoints);
    }
    else {
      this._salesinvoiceposform.controls['remainingpoints'].setValue(this._RemainingPoints);
      if (this.reward) {
        let rewardsValue = this.GetrewardsValue(netamountinusd);
        let applicablenetamount = (netamountinusd * (30 / 100)); // 112.5
        let rewardamount = applicablerewardpoints * rewardsValue;
        if (rewardamount > netamountinusd || rewardamount > applicablenetamount) {
          this._salesinvoiceposform.get("applicablerewardpoints").setValue(0);
          if (applicablenetamount > this._RemainingPoints) {
            this._salesinvoiceposform.get("applicablerewardpoints").setValue(this._RemainingPoints);
            this._CustomExceptionService.handleWarning("Applicable Reward Points " + this._RemainingPoints);
          } else {
            this._salesinvoiceposform.get("applicablerewardpoints").setValue(applicablenetamount.toFixed(2));
            this._CustomExceptionService.handleWarning("Applicable Reward Points " + applicablenetamount);
            var RewardPoints = this._RemainingPoints - applicablenetamount;
            this._salesinvoiceposform.controls['remainingpoints'].setValue(RewardPoints.toFixed(2));
          }
        } else {
          var RewardPoints = this._RemainingPoints - applicablerewardpoints;
          this._salesinvoiceposform.controls['remainingpoints'].setValue(RewardPoints.toFixed(2));
        }
      } else {
        this._salesinvoiceposform.get("applicablerewardpoints").setValue(0);
      }
    }
    this.TotalCalculation();
  }

  GetrewardsValue(netmount: number): number {
    let id = this._salesinvoiceposform.get("currencyid").value;
    let rewardsValue = 0;
    if (this.reward) {
      let netamountUSD = (this.GetExchangeRate(1) / this.GetExchangeRate(id)) * (netmount);
      if (this.reward.minimumamount > 0 && this.reward.maximumamount > 0) {
        if (netmount >= this.reward.minimumamount && netmount <= this.reward.maximumamount) {
          if (this.reward.rewardsvalue)
            rewardsValue = this.reward.rewardsvalue;
        }
        else if (this.reward.maximumamount > 0 && netmount <= this.reward.maximumamount) {
          if (this.reward.rewardsvalue)
            rewardsValue = this.reward.rewardsvalue;
        }
        else if (this.reward.minimumamount > 0 && netmount >= this.reward.minimumamount) {
          if (this.reward.rewardsvalue)
            rewardsValue = this.reward.rewardsvalue;
        }
      }
    }
    return rewardsValue;
  }

  modifyqty() {
    $("input[name='quantity']").focus();
    this.displayBasicProductType = false;
    this.dispalyBasicScheme = false;
  }

  DisabledAdvanceOrCredit() {
    this._salesinvoiceposform.get("cuscurrencyamount1").reset();
    this._salesinvoiceposform.get("cuscurrencyamount2").reset();

    this._IsdisabledAdvanceRef = false;
    this._salesinvoiceposform.get("creditnoteapplicable").enable();
    let customeradvance: number = this._salesinvoiceposform.get("customeradvanceamount").value;
    let salesinvoiceamt: number = this._salesinvoiceposform.get("salesinvoiceamount").value;
    let creditamount: number = this._salesinvoiceposform.get("creditnoteamount").value;
    if (this._advances && this._advances.length > 0 && customeradvance >= salesinvoiceamt) {
      this._IsdisabledAdvanceRef = false;
      if (this._creditnoteids && this._creditnoteids.length > 0) {
        this._salesinvoiceposform.get("creditnoteapplicable").enable()
      } else {
        this._salesinvoiceposform.get("creditnoteapplicable").disable()
      }
    }
    else if (this._salesinvoiceposform.get("creditnoteapplicable").value && this._creditnoteids && this._creditnoteids.length > 0 && creditamount >= salesinvoiceamt) {

      if (this._advances && this._advances.length > 0) {
        this._IsdisabledAdvanceRef = false;
      } else {
        this._IsdisabledAdvanceRef = true;
      }
      this._salesinvoiceposform.get("creditnoteapplicable").enable()
    }
    else {
      this._IsdisabledAdvanceRef = false;
    }
  }

  GetCustomerWithMobileNumber() {

    var mobileno = this._salesinvoiceposform.get("mobileno").value || "";
    var selectedcustomer = (this._Customermobilenumberlist || []).find(f => f.mobileno == mobileno);

    if (mobileno == "") {
      this.InitializeForm();
      this.InitializeProductForm();
      this.InitializeCustomerForm();
      this._ProductDetails = [];
      return
    }

    if (!selectedcustomer)
      return;

    this._IsProgressSpinner = true;
    this._selectedcustomer = selectedcustomer.customerid;
    this._advancereferences = [];
    this._advances = [];
    this._salesinvoiceposform.get("creditnoteinvoiceno").reset();
    this._salesinvoiceposform.controls["creditnoteapplicable"].setValue(false);
    this._creditnoteids = [];
    this._CreditNoteRefernces = [];
    this._TempCreditNoteRefernces = [];
    this._Tempadvancereferences = [];
    this._SalesInvoiceNumbers = [];
    this._Currencies = this._Multicurrencies1 = this._Multicurrencies2 = this._ReturnCurrencies = [];
    this._IsProgressSpinner = true;
    this._SalesinvoiceposService.GetCustomerDetails(selectedcustomer.customerid, "Normal").subscribe((result: any) => {
      this._IsProgressSpinner = false;
      this.earnedrewarddetails = []
      this.usedrewarddetails = []
      this.totalearningpoints = 0
      this.totalusedpoints = 0
      this.totalremainingpoints = 0
      const resultJSON = result;
      this.earnedrewarddetails = resultJSON.earnedrewarddetails;
      this.usedrewarddetails = resultJSON.usedrewarddetails;
      this.totalearningpoints = resultJSON.totalearningpoints;
      this.totalusedpoints = resultJSON.totalusedpoints;
      this.totalremainingpoints = resultJSON.totalremainingpoints;
      let cus = (this._Customernames || []).find(f => f.customerid == selectedcustomer.customerid);
      this._Currencies = this._Multicurrencies1 = this._Multicurrencies2 = this._ReturnCurrencies = (this._CurrenciesTemp || []);
      if (cus.series == "MSC00094" && this._action != 'view') {
        this._Currencies = (this._CurrenciesTemp || []).filter(f => f.currencyid != 4) || [];
        this._Multicurrencies1 = (this._CurrenciesTemp || []).filter(f => f.currencyid != 4) || [];
        this._Multicurrencies2 = (this._CurrenciesTemp || []).filter(f => f.currencyid != 4) || [];
        this._ReturnCurrencies = (this._CurrenciesTemp || []).filter(f => f.currencyid != 4) || [];
      }
      this._salesinvoiceposform.get("currencyid").enable();
      let customer = <Salescustomerdetailsview>(resultJSON.customerdetails);
      this._customer = customer;
      this._salesinvoiceposform.get("salesreference").reset();
      this._salesinvoiceposform.get("salesreferenceno").reset();
      this._salesinvoiceposform.controls['currencyid'].setValue(customer.currencyid);
      this._salesinvoiceposform.controls['cuscurrencyid1'].setValue(this._customer.currencyid);
      var referenceid = this._Currencies.filter(f => f.currencyid == customer.currencyid)[0].currencyuniquerefid
      this._salesinvoiceposform.controls['currencyreferenceid'].setValue(referenceid);
      this._selectedlevel = customer.levelid;
      this._Markuplevels = resultJSON.levels;
      this._salesinvoiceposform.controls['customerid'].setValue(customer.customerid);
      //this._salesinvoiceposform.controls['mobileno'].setValue(customer.mobileno);
      this._salesinvoiceposform.controls['mailid'].setValue(customer.mailid);
      this._salesinvoiceposform.controls['remainingpoints'].setValue(customer.rewardpoints);
      this._RemainingPoints = customer.rewardpoints;
      this._salesinvoiceposform.controls['markuplevelid'].setValue(customer.levelid);
      // this._SalesInvoiceNumbers = resultJSON.creditnoteinvoicenos;
      // this._CreditNoteRefernces = <Creditnoteview[]>resultJSON.creditnotereferenceno;
      // this._TempCreditNoteRefernces = <Creditnoteview[]>resultJSON.creditnotereferenceno;
      this._advancereferences = resultJSON.receiptadvancereferenceviewlist || [];
      this._Tempadvancereferences = resultJSON.receiptadvancereferenceviewlist || [];

      if (customer.userid > 0) {
        this._IsDisabledRewardPoints = true
        this._salesinvoiceposform.get("applyrewardpoints").disable()
        this._salesinvoiceposform.get("applicablerewardpoints").disable()
        this._salesinvoiceposform.get("remainingpoints").disable()
      } else {
        if (this._selectedlevel == 1 || this._selectedlevel == 2)//RETAIL 1 AND RETAIL 2
        {
          this._IsDisabledRewardPoints = false
          this._salesinvoiceposform.get("applyrewardpoints").enable()
          this._salesinvoiceposform.get("applicablerewardpoints").disable()
          this._salesinvoiceposform.get("remainingpoints").disable()
        }
        else {
          this._IsDisabledRewardPoints = true
          this._salesinvoiceposform.get("applyrewardpoints").disable()
          this._salesinvoiceposform.get("applicablerewardpoints").disable()
          this._salesinvoiceposform.get("remainingpoints").disable()
        }
      }

      $("input[name='productbarcode']").focus();
      this.CurrencyBasedRef();
      this._IsProgressSpinner = false;
      if (this._action != 'view')
        this.OnUserPreferredCurrencyChange();
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  currency2calculation() {
    //currency 2 Automatic Entry 
    let currencyid1 = this._salesinvoiceposform.get("cuscurrencyid1").value;
    let currencyid2 = this._salesinvoiceposform.get("cuscurrencyid2").value;
    let cuscurrencyamnt1 = this._salesinvoiceposform.get("cuscurrencyamount1").value;
    let cuscurrencyamnt2 = this._salesinvoiceposform.get("cuscurrencyamount2").value || 0;

    if (currencyid2) {
      let netamountincustomercurrency: number = 0,
        finalnetamountincustomercurrency: number = 0,
        currencyamnt1: number = 0,
        balancecuscurrencyamnt2: number = 0;
      netamountincustomercurrency = parseFloat(this._salesinvoiceposform.get('netamountincustomercurrency').value);
      finalnetamountincustomercurrency = (netamountincustomercurrency / this.GetExchangeRate(currencyid1)) * this.GetExchangeRate(currencyid2)
      currencyamnt1 = (cuscurrencyamnt1 / this.GetExchangeRate(currencyid1)) * this.GetExchangeRate(currencyid2);
      balancecuscurrencyamnt2 = (finalnetamountincustomercurrency - currencyamnt1);
      if (balancecuscurrencyamnt2 <= 0) {
        balancecuscurrencyamnt2 = 0;
      }
      this._salesinvoiceposform.controls["cuscurrencyamount2"].setValue(parseFloat(balancecuscurrencyamnt2.toFixed(2)));
    }
    //
  }

  onchangeMulticurrency2() {

    let usernetamount = parseFloat(this._salesinvoiceposform.get("netamountincustomercurrency").value || 0);
    let id = this._salesinvoiceposform.get("currencyid").value;
    let cuscurrencyid1 = this._salesinvoiceposform.get("cuscurrencyid1").value || 0;
    let cuscurrencyid2 = this._salesinvoiceposform.get("cuscurrencyid2").value || 0;

    if (!cuscurrencyid1)
      return;

    //let cutomerselectedcurrency1 = this.GetExchangeRate(currencyid1);
    //var netamountinCustomerinusd = this._salesinvoiceposform.get('netamountincustomercurrency').value;

    //var CustomerCurrencyRate1 = netamountinCustomerinusd * cutomerselectedcurrency1;

    let usercurrencyamount = this.GetUserAmount1();

    let balanceamount = (usercurrencyamount - usernetamount);
    this._salesinvoiceposform.controls["paidamount"].setValue(usercurrencyamount.toFixed(2));

    this._salesinvoiceposform.controls["balanceamount"].setValue(balanceamount.toFixed(2));
    this._salesinvoiceposform.controls["customerbalanceamount"].setValue(balanceamount.toFixed(2));

    this.TotalCalculation();

    this._ReturnCurrencies = [];
    if (cuscurrencyid1) {
      let currency: Currency = this._Currencies.find(f => f.currencyid == cuscurrencyid1);
      if (currency) {
        let hascurrency = this._ReturnCurrencies.some(s => s.currencyid == currency.currencyid);
        if (!hascurrency)
          this._ReturnCurrencies.push(currency);
      }
    }
    if (cuscurrencyid2) {
      let currency: Currency = this._Currencies.find(f => f.currencyid == cuscurrencyid2);
      if (currency) {
        let hascurrency = this._ReturnCurrencies.some(s => s.currencyid == currency.currencyid);
        if (!hascurrency)
          this._ReturnCurrencies.push(currency);
      }
    }
  }
  GetUserAmount1(): number {

    let currencyid = this._salesinvoiceposform.get("currencyid").value;
    let currencyid1 = this._salesinvoiceposform.get("cuscurrencyid1").value;
    let currencyid2 = this._salesinvoiceposform.get("cuscurrencyid2").value;
    let currencyamnt1 = this._salesinvoiceposform.get("cuscurrencyamount1").value;
    let currencyamnt2 = this._salesinvoiceposform.get("cuscurrencyamount2").value;

    let usercurrencyamount = 0;
    if (currencyid == currencyid1) {
      usercurrencyamount += currencyamnt1;
    }
    else {
      usercurrencyamount += parseFloat(((this.GetExchangeRate(currencyid) / this.GetExchangeRate(currencyid1)) * currencyamnt1).toFixed(2));
    }

    if (currencyid2 && currencyamnt2) {
      if (currencyid == currencyid2) {
        usercurrencyamount += currencyamnt2;
      }
      else {
        usercurrencyamount += parseFloat(((this.GetExchangeRate(currencyid) / this.GetExchangeRate(currencyid2)) * currencyamnt2).toFixed(2));
      }
    }

    return usercurrencyamount;
  }
  weighingproductBarcode() {
    let barcodevalue = this._salesproductform.get("productbarcode").value;
    barcodevalue = barcodevalue.trim();
    let isweighingbarcode: string = barcodevalue.substring(0, 2)

    if (isweighingbarcode == "99") {
      let productcode: number = 0;
      let weight: number = 0
      productcode = parseInt(barcodevalue.substring(2, 7));
      weight = parseFloat(barcodevalue.substring(7, 12)) / 1000;
      var selectedbranch = this._salesinvoiceposform.get("collectingbranch").value;
      let severity = this._salesproductform.get("severity").value;
      let isDamaged = (this._salesproductform.get("isdamaged").value == true ||
        this._salesproductform.get("isdamaged").value == 1);

      this._SalesInvoiceTaxService.GetBarCodeWeighingBasedProductDetails({ "productid": productcode, "branchid": this._branchid, "Collectingbranchid": selectedbranch, "severity": severity, postax: 0 }).subscribe((result: any) => {

        this._productBarcodesearch = result.productbarcodesearch || [];
        if (isDamaged) {
          this._salesproductform.controls['stockinhand'].setValue(0);
          if (result.collectingBranchDamagedStock) {
            this._salesproductform.controls['stockinhand'].setValue(result.collectingBranchDamagedStock.damagedstock);
          }
        } else {
          if (!result.collectingBranchStock) {
            this._salesproductform.controls['stockinhand'].setValue(0);
          }
          else {
            this._Branch = result.collectingBranchStock.stock1 || 0;
            this._salesproductform.controls['stockinhand'].setValue(this._Branch);
          }
        }
        let product: any = this._productBarcodesearch.find(f => f.productid == productcode);
        if (product) {
          this._salesproductform.get("productid").setValue(product);
          let productcategory: Productcategory = new Productcategory();
          productcategory.productcategoryid = product.productcategoryid;
          productcategory.producttypeid = product.producttypeid + '';
          productcategory.categoryname = product.categoryname;
          this._ProductCategories.push(productcategory);
          this.filteredProducts.push(product);
          this.GetVariantName();
          this._salesproductform.get("productbarcode").reset();
          this._salesproductform.controls["quantity"].setValue(weight);
          this.GetProductPrice(1)
          $("input[name='productbarcode']").focus();
        }
        else {
          this._CustomExceptionService.handleWarning("Product may not available at this branch");
          this._salesproductform.get("productbarcode").reset();
        }
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

    }
  }

}
