<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Product</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <span class="p-float-label page-search">
                                <p-autoComplete [suggestions]="filteredProducts" placeholder="Search Product Name"
                                    (completeMethod)="filterProducts($event)" field="productname"
                                    [disabled]="isDisabledcopy" (onSelect)="GetProductDetails($event)">
                                    <ng-template let-product pTemplate="item">
                                        <div>{{product.productname}}</div>
                                    </ng-template>
                                </p-autoComplete>
                            </span>
                            <button pButton pRipple title="Save" type="button" (click)="onSave()"
                                [disabled]="isDisabledSave" icon="pi pi-save"></button>
                            <button pButton pRipple title="Clear" icon="pi pi-trash" class="p-button-danger"
                                [disabled]="isDisabledClear" (click)="reset($event)"></button>
                            <button pButton pRiple icon="pi pi-search" title="Back to Search"
                                class="p-button p-button-success p-mr-2" (click)="goBack($event)"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-tabView #tab [(activeIndex)]="selectedtab">
                    <p-tabPanel header="Product">
                        <form [formGroup]="_productform" (ngSubmit)="onSave()">
                            <table class="normal-table">
                                <tr>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [filter]="true"
                                                [options]="_producttypes" optionLabel="producttypename"
                                                optionValue="producttypeid" formControlName="producttypeid"
                                                (onChange)="getProductCategory($event)">
                                            </p-dropdown>
                                            <label for="producttypeid">Product Type <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _productform.get('producttypeid').touched) && _productform.get('producttypeid').errors?.SelectProductType">
                                            Please select product type
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [filter]="true"
                                                [options]="_productcategories" optionLabel="categoryname"
                                                optionValue="productcategoryid" formControlName="productcategoryid">
                                            </p-dropdown>
                                            <label for="productcategoryid"> Product Category <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <!-- if "Product category" is not given, show error as "Please select Product category" -->
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _productform.get('productcategoryid').touched) && _productform.get('productcategoryid').errors?.SelectProductCategory">
                                            Please select product category
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" name="productcode" disabled="true"
                                                formControlName="productcode" pInputText>
                                            <label for="productcode">Product Code <span class="hlt-txt">*</span></label>
                                        </span>
                                        <!-- if "Product code" is not given, show error as "Please enter Product code" -->
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _productform.get('productcode').touched) && _productform.get('productcode').errors?.ProductCodeRequired">
                                            Please Enter product code
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" name="productname" maxlength="50"
                                                formControlName="productname" pInputText>
                                            <label for="productname"> Product Name <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <!-- if "Product name" is not given, show error as "Please enter Product name" -->
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _productform.get('productname').touched) && _productform.get('productname').errors?.ProductNameRequired">
                                            Please enter product name
                                        </span>

                                    </td>
                                    <td style="width: 20%;">
                                        <form [formGroup]="_productbranddetailform">
                                            <span class="p-float-label">
                                                <p-multiSelect [filter]="true" [options]="_brandnames" name="brandid"
                                                    optionLabel="brandname" [selectionLimit]="1" optionValue="brandid"
                                                    formControlName="brandid" (onChange)="onSelectBrands($event)"
                                                    [(ngModel)]="_selectedBrandIds" displaySelectedLabel=true>
                                                </p-multiSelect>
                                                <label for="brandid">Brand<span class="hlt-txt">*</span></label>
                                            </span>
                                        </form>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [filter]="true"
                                                [options]="_purchaseuom" optionLabel="metasubdescription"
                                                optionValue="metasubcode" (onChange)="checkPurchaseandSalesUOM($event)"
                                                formControlName="purchaseuom">
                                            </p-dropdown>
                                            <label for="purchaseuom"> Purchase UOM <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <!-- if "Purchase UOM" is not given, show error as "Please select Purchase UOM" -->
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _productform.get('purchaseuom').touched) && _productform.get('purchaseuom').errors?.SelectPurchaseUOM">
                                            Please select purchase UOM
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [filter]="true" [options]="_salesuom"
                                                optionLabel="metasubdescription" optionValue="metasubcode"
                                                (onChange)="checkPurchaseandSalesUOM($event)"
                                                formControlName="salesuom">
                                            </p-dropdown>
                                            <label for="salesuom"> Sales UOM <span class="hlt-txt">*</span></label>
                                        </span>
                                        <!-- if "selling  UOM" is not given, show error as "Please select selling  UOM" -->
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _productform.get('salesuom').touched) && _productform.get('salesuom').errors?.SelectSalesUOM">
                                            Please select sales UOM
                                        </span>
                                    </td>

                                    <td>
                                        <span class="p-float-label">
                                            <p-inputNumber name="weight" maxlength="8" mode="decimal"
                                                [minFractionDigits]="2" [useGrouping]="false" formControlName="weight">
                                            </p-inputNumber>
                                            <label for="weight">Weight </label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [filter]="true"
                                                [options]="_weightuom" optionLabel="metasubdescription"
                                                optionValue="metasubcode" formControlName="weightuom">
                                            </p-dropdown>
                                            <label for="weightuom"> Weight UOM </label>
                                        </span>
                                    </td>

                                    <td>
                                        <span class="p-float-label">
                                            <input type="text" name="barcode" maxlength="25" formControlName="barcode"
                                                pInputText>
                                            <label for="barcode">Barcode Number</label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="number" name="minimumlevel" maxlength="7"
                                                formControlName="minimumlevel"
                                                (change)="checkMinimumandMaximumLevel($event)" pInputText>
                                            <label for="minimumlevel">Minimum Level <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <!-- if "minimum level" is not given, show error as "please enter minimum level" -->
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _productform.get('minimumlevel').touched) && _productform.get('minimumlevel').errors?.MinimumLevelRequired">
                                            Please enter minimum level
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="number" name="maximumlevel" maxlength="7" max="minimumlevel"
                                                formControlName="maximumlevel"
                                                (change)="checkMinimumandMaximumLevel($event)" pInputText>
                                            <label for="maximumlevel">Maximum Level <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <!-- if " maximun level" is not given, show error as "please enter maximun level" -->
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _productform.get('maximumlevel').touched) && _productform.get('maximumlevel').errors?.MaximumLevelRequired">
                                            Please enter maximum level
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="number" name="reorderlevel" maxlength="7"
                                                formControlName="reorderlevel" (change)="checkReorderLevel($event)"
                                                pInputText>
                                            <label for="reorderlevel">Reorder Level <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <!-- if "reorder level" is not given, show error as "please enter reorder level" -->
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _productform.get('reorderlevel').touched) && _productform.get('reorderlevel').errors?.ReorderLevelRequired">
                                            Please enter reorder level
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" name="specification" maxlength="50"
                                                formControlName="specification" pInputText>
                                            <label for="specification">Specification</label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="true" [options]="_productstatus"
                                                optionLabel="metasubdescription" optionValue="metasubcode"
                                                formControlName="productstatus">
                                            </p-dropdown>
                                            <label for="productstatus"> Status <span class="hlt-txt">*</span></label>
                                        </span>
                                    </td>
                                </tr>
                            </table>
                            <div class="sub-title">
                                <div class="row">
                                    <div class="col-md-5">
                                        <h5>Purchase Category</h5>
                                    </div>
                                    <div class="col-md-7"></div>
                                </div>
                            </div>
                            <table class="normal-table">

                                <tr>
                                    <td style="width: 20%;">
                                        <div class="p-formgroup-inline">
                                            <h6>Purchase From <span class="hlt-txt">*</span></h6>
                                            <div class="p-field-radiobutton" *ngFor="let PF of _PurchaseFrom">
                                                <p-radioButton name="purchasefrom" label={{PF.metasubdescription}}
                                                    value={{PF.metasubcode}} formControlName="purchasefrom"
                                                    (onClick)="getSupplier($event)">
                                                </p-radioButton>
                                            </div>
                                        </div>
                                        <!-- if "purchase  from" is not given , show error as "please select the purchase from" -->
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _productform.get('purchasefrom').touched) && _productform.get('purchasefrom').errors?.SelectPurchaseFrom">
                                            Please select purchase from
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <form [formGroup]="_productsupplierdetailform" (ngSubmit)="onSave()">
                                            <span class="p-float-label">
                                                <p-multiSelect [filter]="true" [options]="_suppliernames"
                                                    name="supplierid" optionLabel="suppliername"
                                                    optionValue="supplierid" formControlName="supplierid"
                                                    displaySelectedLabel="true" [virtualScroll]="true" itemSize="30"
                                                    selectedItemsLabel="{0} items selected"
                                                    (onChange)="onSelectSuppliers($event)"
                                                    [(ngModel)]="_selectedSupplierIds" displaySelectedLabel=true
                                                    [selectionLimit]="3">
                                                </p-multiSelect>
                                                <label for="supplierid">Supplier Name <span
                                                        class="hlt-txt">*</span></label>
                                            </span>
                                            <!-- if any supllier is not selected , show error as "please select atleast one supplier " -->
                                            <!-- <span class="text-danger"
                                                *ngIf="(_submitted || _productsupplierdetailform.get('supplierid').touched) && _productsupplierdetailform.get('supplierid').errors?.SelectSupplierName">
                                                Please select atleast one supplier
                                            </span> -->
                                        </form>
                                    </td>
                                    <td style="width: 20%;">
                                        <div class="p-field-inline">
                                            <div class="p-field-control">
                                                <span class="p-float-label">
                                                    <p-dropdown [autoDisplayFirst]="true" [options]="_currencies"
                                                        optionLabel="currencyname" optionValue="currencyid"
                                                        formControlName="currencyid"
                                                        (onChange)="setPurchasepriceUSD($event)">
                                                    </p-dropdown>
                                                    <label for="currencyid"> Currency <span
                                                            class="hlt-txt">*</span></label>
                                                </span>
                                            </div>
                                            <div class="p-field-control">
                                                <span class="p-float-label">
                                                    <input type="number" min="0" max="999999" name="purchaserate"
                                                        maxlength="9" (keyup)="setPurchasepriceUSD($event)"
                                                        formControlName="purchaserate" pInputText>
                                                    <label for="purchaserate">Purchase Rate <span
                                                            class="hlt-txt">*</span></label>
                                                </span>
                                                <!-- if purchase rate is not given , show error as "please enter purchase  rate " -->
                                                <span class="text-danger"
                                                    *ngIf="(_submitted || _productform.get('purchaserate').touched) && _productform.get('purchaserate').errors?.PurchaseRateRequired">
                                                    Please enter purchase rate
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" name="purchaserateinusd" maxlength="20" disabled="true"
                                                formControlName="purchaserateinusd" pInputText>
                                            <label for="purchaserateinusd">Purchase Rate (USD) </label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-multiSelect [filter]="true" [options]="_currencylist" name="currencyid"
                                                optionLabel="currencyname" optionValue="currencyid"
                                                [disabled]="_action == 'view'" [ngModelOptions]="{standalone: true}"
                                                (onChange)="OnSelectProductCurrencies($event)"
                                                [(ngModel)]="_SelectedCurrencies" displaySelectedLabel=true>
                                            </p-multiSelect>
                                            <label for="brandid">Selling Currency<span class="hlt-txt">*</span></label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="p-formgroup-inline">
                                            <div class="p-field-checkbox">
                                                <p-checkbox name="fixedassets" binary="true"
                                                    formControlName="fixedassets">
                                                </p-checkbox>
                                                <label for="fixedassets">Fixedassets</label>
                                            </div>
                                        </div>

                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input pInputText type="text" maxlength="5" name="discountpercentage"
                                                (keypress)="keypress.kpDecimalInput($event)"
                                                (keyup)="DiscountPercentage()" formControlName="discountpercentage">
                                            <label for="discountpercentage">Discount Percentage <span
                                                    class="hlt-txt">*</span></label>
                                            <!-- mode="decimal" [minFractionDigits]="2" [useGrouping]="false" -->
                                        </span>
                                        <!-- if discount applicabe is checked and discount % is not given, show error as "please enter disount % " -->
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _productform.get('discountpercentage').touched) && _productform.get('discountpercentage').errors?.DiscountPercentageRequired">
                                            Please enter discount percentage
                                        </span>
                                        <!-- <span class="text-danger"
                                            *ngIf="(_submitted || _productform.get('discountpercentage').touched) && _productform.get('discountpercentage').errors?.DiscountPercentageinvalid">
                                        </span> -->
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input pInputText type="text" maxlength="5" name="onlinediscount"
                                                (keypress)="keypress.kpDecimalInput($event)"
                                                (keyup)="OnlineDiscountPercentage()" formControlName="onlinediscount">
                                            <label for="onlinediscount">Online Discount Percentage </label>
                                        </span>
                                    </td>
                                    <td>
                                        <div class="p-formgroup-inline">
                                            <div class="p-field-checkbox">
                                                <p-checkbox name="isperishable" binary="true"
                                                    formControlName="isperishable">
                                                </p-checkbox>
                                                <label for="isperishable">Is Perishable</label>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="p-formgroup-inline">
                                            <div class="p-field-checkbox">
                                                <p-checkbox name="isbranchlevelprice" binary="true"
                                                    formControlName="isbranchlevelprice">
                                                </p-checkbox>
                                                <label for="isbranchlevelprice">Is Branch Level Price </label>
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <div class="p-formgroup-inline">
                                            <div class="p-field-checkbox">
                                                <p-checkbox name="vatapplicable" binary="true"
                                                    formControlName="vatapplicable">
                                                </p-checkbox>
                                                <label for="vatapplicable">VAT applicable</label>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="p-formgroup-inline">
                                            <div class="p-field-checkbox">
                                                <p-checkbox name="notimport" binary="true" formControlName="notimport">
                                                </p-checkbox>
                                                <label for="vatapplicable">Not Import</label>
                                            </div>
                                            <div class="p-field-checkbox">
                                                <p-checkbox name="notexport" binary="true" formControlName="notexport">
                                                </p-checkbox>
                                                <label for="vatapplicable">Not Export</label>
                                            </div>
                                        </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="p-formgroup-inline">
                                            <div #wa class="p-field-checkbox">
                                                <p-checkbox name="warrantyapplicable" binary="true"
                                                    formControlName="warrantyapplicable"
                                                    (onChange)="disableWarrenty($event)">
                                                </p-checkbox>
                                                <label for="warrantyapplicable"> Warranty Applicable</label>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-inputNumber name="warranty" formControlName="warranty" maxlength="5">
                                            </p-inputNumber>
                                            <label for="warranty">Warranty <span class="hlt-txt">*</span></label>
                                        </span>
                                        <!-- if warrenty is checked and warranty year or moths is not given, show error as "please entry warrenty" -->
                                        <!-- <span class="text-danger"
                                            *ngIf="(_submitted || _productform.get('warranty').touched) && _productform.get('warranty').errors?.WarrantyRequired">
                                            Please Enter Warranty
                                        </span> -->
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_warrantyperiods"
                                                optionLabel="metasubdescription" optionValue="metasubcode"
                                                formControlName="warrantyperiod">
                                            </p-dropdown>
                                            <label for="warrantyperiod"> Warranty Period</label>
                                        </span>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="p-formgroup-inline">
                                            <div class="p-field-checkbox">
                                                <p-checkbox name="stuffed" formControlName="stuffed" binary="true"
                                                    (onChange)="disableStuffedQty($event)">
                                                </p-checkbox>
                                                <label for="stuffed">Stuffed</label>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="text" maxlength="5" pKeyFilter="int" name="stuffedqty"
                                                formControlName="stuffedqty"
                                                (keyup)="onEnterofStuffedQty($event.target.value)" pInputText>
                                            <label for="stuffedqty">Stuffed Qunatity </label>
                                        </span>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="p-formgroup-inline">
                                            <div class="p-field-checkbox" *ngFor="let MA of _ManufacturingApplicable">
                                                <p-checkbox name="manufacturingapplicable"
                                                    formControlName="manufacturingapplicable" binary="true"
                                                    label={{MA.metasubdescription}} value={{MA.metasubcode}}>
                                                </p-checkbox>
                                            </div>
                                        </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="p-formgroup-inline">
                                            <div class="p-field-checkbox">
                                                <p-checkbox name="disallownegativeqty"
                                                    formControlName="disallownegativeqty" binary="true">
                                                </p-checkbox>
                                                <label for="disallownegativeqty">Disallow Negative Quantity</label>
                                            </div>
                                        </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="p-formgroup-inline">
                                            <div class="p-field-checkbox">
                                                <p-checkbox name="isavailable" binary="true"
                                                    formControlName="isavailable">
                                                </p-checkbox>
                                                <label for="isavailable">Is Available</label>
                                            </div>
                                        </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="p-formgroup-inline">
                                            <div class="p-field-checkbox">
                                                <p-checkbox name="special" binary="true" formControlName="special">
                                                </p-checkbox>
                                                <label for="special">Special</label>
                                            </div>
                                        </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="p-formgroup-inline">
                                            <div class="p-field-checkbox">
                                                <p-checkbox name="cashonly" binary="true" formControlName="cashonly">
                                                </p-checkbox>
                                                <label for="cashonly">Cash Only</label>
                                            </div>
                                        </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="p-formgroup-inline">
                                            <div class="p-field-checkbox">
                                                <p-checkbox name="nolaybuy" binary="true" formControlName="nolaybuy">
                                                </p-checkbox>
                                                <label for="nolaybuy">No Laybuy</label>
                                            </div>
                                        </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <!-- Debug -->
                                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                                    </td>
                                </tr>
                            </table>
                        </form>
                        <p-dialog header="Product Image" [(visible)]="displaydocumentpopup" [position]="position1"
                            [style]="{width: '500px'}" [baseZIndex]="10000">
                            <img [src]="productimage" alt="" class="img-preview">

                            <ng-template pTemplate="footer">
                                <p-button icon="pi pi-check" (click)="showPopupDialog()" label="Ok"
                                    styleClass="p-button-text">
                                </p-button>
                            </ng-template>
                        </p-dialog>
                    </p-tabPanel>
                    <p-tabPanel header="Price Details" [hidden]="hidePriceHistory">
                        <div class="card">
                            <p-table #dt [value]="_PriceDetails" [rows]="10" [paginator]="true"
                                [rowsPerPageOptions]="[10,25,50,100]"
                                [globalFilterFields]="['LEVELNAME','markuppercentage','vatpercentage','USD','CAD','ZAR','Currency4','Currency5']"
                                [rowHover]="true" dataKey="productdetailid">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th [hidden]="true">Level ID</th>
                                        <th [hidden]="true">Product Detail ID</th>
                                        <th>Markup Level</th>
                                        <th>Markup Percentage </th>
                                        <th>VAT Percentage</th>
                                        <th>USD</th>
                                        <th>USD (VAT)</th>
                                        <th>RAND</th>
                                        <th>RAND (VAT)</th>
                                        <th>BOND</th>
                                        <th>BOND (VAT)</th>
                                        <th>BANK</th>
                                        <th>BANK (VAT)</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_pricedetail>
                                    <tr>
                                        <td [hidden]="true">
                                            {{_pricedetail.levelid}}
                                        </td>
                                        <td [hidden]="true">
                                            {{_pricedetail.productdetailid}}
                                        </td>
                                        <td>
                                            {{_pricedetail.markuplevel}}
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="number" [(ngModel)]="_pricedetail.markuppercentage"
                                                        maxlength="5" (keyup)="onKeyupSellingPrice(_pricedetail,$event)"
                                                        *ngIf="_action != 'view'" pInputText>
                                                    <div *ngIf="_action == 'view'">{{_pricedetail.markuppercentage}}
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_pricedetail.markuppercentage}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <p-dropdown #id [autoDisplayFirst]="false"
                                                        [options]="_vatpercentage" optionLabel="taxcode"
                                                        optionValue="taxid" [(ngModel)]="_pricedetail.vatpercentageid"
                                                        *ngIf="_IsVatParcentage"
                                                        (onChange)="onKeyupSellingPrice(_pricedetail,$event)">
                                                    </p-dropdown>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    <p-dropdown [disabled]="true" [autoDisplayFirst]="false"
                                                        *ngIf="_IsDisableinView" [options]="_vatpercentage"
                                                        optionLabel="taxcode" optionValue="taxid"
                                                        [(ngModel)]="_pricedetail.vatpercentageid">
                                                    </p-dropdown>
                                                    <!-- {{_pricedetail.vatpercentageid}} -->
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="text" [(ngModel)]="_pricedetail.tempusd"
                                                        *ngIf="_action != 'view'" pInputText>
                                                    <div *ngIf="_action == 'view'">{{_pricedetail.tempusd }}</div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_pricedetail.tempusd | number: '1.2-4'}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="text" [(ngModel)]="_pricedetail.usdvat"
                                                        *ngIf="_action != 'view'" pInputText>
                                                    <div *ngIf="_action == 'view'">{{_pricedetail.usdvat }}</div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_pricedetail.tempusdvatin | number: '1.2-4'}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>

                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="text" [(ngModel)]="_pricedetail.temprand"
                                                        *ngIf="_action != 'view'" pInputText>
                                                    <div *ngIf="_action == 'view'">{{_pricedetail.temprand }}</div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_pricedetail.temprand | number: '1.2-4'}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="text" [(ngModel)]="_pricedetail.randvat"
                                                        *ngIf="_action != 'view'" pInputText>
                                                    <div *ngIf="_action == 'view'">{{_pricedetail.randvat }}</div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_pricedetail.temprandvatin | number: '1.2-4'}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="text" [(ngModel)]="_pricedetail.tempbond"
                                                        *ngIf="_action != 'view'" pInputText>
                                                    <div *ngIf="_action == 'view'">{{_pricedetail.tempbond}}</div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_pricedetail.tempbond | number: '1.2-4'}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="text" [(ngModel)]="_pricedetail.bondvat"
                                                        *ngIf="_action != 'view'" pInputText>
                                                    <div *ngIf="_action == 'view'">{{_pricedetail.bondvat }}</div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_pricedetail.tempbondvatin | number: '1.2-4'}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="text" [(ngModel)]="_pricedetail.tempbank"
                                                        *ngIf="_action != 'view'" pInputText>
                                                    <div *ngIf="_action == 'view'">{{_pricedetail.tempbank }}</div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_pricedetail.tempbank | number: '1.2-4'}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="text" [(ngModel)]="_pricedetail.bankvat"
                                                        *ngIf="_action != 'view'" pInputText>
                                                    <div *ngIf="_action == 'view'">{{_pricedetail.bankvat }}</div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_pricedetail.tempbankvatin | number: '1.2-4'}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>

                                    </tr>
                                </ng-template>
                            </p-table>

                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="Package Price Details">
                        <table class="normal-table">
                            <tr>
                                <td><span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_Packagetypes"
                                            optionLabel="metasubdescription" optionValue="metasubcode"
                                            [(ngModel)]="_Packtypeid" [ngModelOptions]="{ standalone: true }"
                                            name="packtype">
                                        </p-dropdown>
                                        <label for="packtype"> Pack Type <span class="hlt-txt">*</span></label>
                                    </span></td>
                                <td>
                                    <span class="p-float-label">
                                        <input type="text" maxlength="5" pKeyFilter="int" name="quantity"
                                            [(ngModel)]="Quantityperpack" [ngModelOptions]="{ standalone: true }"
                                            pInputText>
                                        <label for="quantity">Quantity Per Pack</label>
                                    </span>
                                </td>
                                <td>
                                    <span class="p-float-label">
                                        <input type="text" name="markuprate" maxlength="50" (keyup)="Packpurchaseratewarn($event)"
                                            [(ngModel)]="Packpurchaserate" [ngModelOptions]="{ standalone: true }"
                                            pInputText>
                                        <label for="markuprate">Purchase Rate In USD</label>
                                    </span>
                                </td>
                                <td>
                                    <span class="p-float-label">
                                        <input type="text" name="barcode" maxlength="25" [(ngModel)]="Packbarcode" (focusout)="Checkbarcode()"
                                            [ngModelOptions]="{ standalone: true }" pInputText>
                                        <label for="barcode">Barcode Number</label>
                                    </span>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <button pButton pRipple label="Add" icon="pi pi-plus" [disabled]="isDisabledButton"
                                        class="p-button-success p-mr-2" (click)="AddPackagepriceDetails()"></button>
                                    <button pButton pRipple label="Clear" icon="pi pi-times" [disabled]="isDisabledButton"
                                        (click)="Clearpackdetails()" class="p-button-danger"></button>
                                    <button pButton pRipple label="Delete" icon="pi pi-trash" *ngIf="isDblclick"
                                        (click)="DeletePackPricedetails()" class="p-button-danger"></button>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </table>
                        <div class="card">
                            <p-toolbar styleClass="p-mb-4">
                                <ng-template pTemplate="left">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text"
                                            (input)="cs.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search..." />
                                    </span>
    
                                    <!-- <button pButton pRipple label="" icon="pi pi-trash" class="p-button-danger "></button> -->
                                </ng-template>    
                            </p-toolbar>
                            <p-table #cs [value]="_PackagePriceDetails" [rows]="25" [paginator]="true"
                                [rowsPerPageOptions]="[25,50,100]"
                                [globalFilterFields]="['packtypename','quantityperpack','packagepurchaserate','markuplevel','USD','CAD','ZAR','Currency4','Currency5']"
                                [rowHover]="true" dataKey="productid">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width: 50px;text-align: center;">S.No</th>
                                        <th>Pack Type</th>
                                        <th>Qty Per Pack</th>
                                        <th>Pur. Rate</th>
                                        <th>Barcode </th>
                                        <th>Markup Level</th>
                                        <th>Markup % </th>
                                        <th>VAT %</th>
                                        <th>USD</th>
                                        <th>USD (VAT)</th>
                                        <th>RAND</th>
                                        <th>RAND (VAT)</th>
                                        <th>BOND</th>
                                        <th>BOND (VAT)</th>
                                        <th>BANK</th>
                                        <th>BANK (VAT)</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_PackagePriceDetail let-i="rowIndex">
                                    <tr>
                                        <td style="text-align: center;">{{i+1}}</td>
                                        <td (dblclick)="onRowDoubleClick(_PackagePriceDetail, 1)">
                                            {{_PackagePriceDetail.packtypename}}
                                        </td>
                                        <td>
                                            {{_PackagePriceDetail. quantityperpack}}
                                        </td>
                                        <td>
                                            {{_PackagePriceDetail.packagepurchaserate}}
                                        </td>
                                        <td>
                                            {{_PackagePriceDetail.barcodenumber}}
                                        </td>
                                        <td [hidden]="true">
                                            {{_PackagePriceDetail.levelid}}
                                        </td>
                                        <td [hidden]="true">
                                            {{_PackagePriceDetail.productpricepackagedetailid}}
                                        </td>
                                        <td>
                                            {{_PackagePriceDetail.markuplevel}}
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="number"
                                                        [(ngModel)]="_PackagePriceDetail.markuppercentage" maxlength="5"
                                                        (keyup)="onKeyupPackageSellingPrice(_PackagePriceDetail,$event)"
                                                        *ngIf="_action != 'view'" pInputText>
                                                    <div *ngIf="_action == 'view'">
                                                        {{_PackagePriceDetail.markuppercentage}}
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_PackagePriceDetail.markuppercentage}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <p-dropdown #id [autoDisplayFirst]="false"
                                                        [options]="_vatpercentage" optionLabel="taxcode"
                                                        optionValue="taxid"
                                                        [(ngModel)]="_PackagePriceDetail.vatpercentageid"
                                                        *ngIf="_IsVatParcentage"
                                                        (onChange)="onKeyupPackageSellingPrice(_PackagePriceDetail,$event)">
                                                    </p-dropdown>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    <p-dropdown [disabled]="true" [autoDisplayFirst]="false"
                                                        *ngIf="_IsDisableinView" [options]="_vatpercentage"
                                                        optionLabel="taxcode" optionValue="taxid"
                                                        [(ngModel)]="_PackagePriceDetail.vatpercentageid">
                                                    </p-dropdown>
                                                    <!-- {{_PackagePriceDetail.vatpercentageid}} -->
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <!-- <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="text" [(ngModel)]="_PackagePriceDetail.tempusd"
                                                        *ngIf="_action != 'view'" pInputText>
                                                    <div *ngIf="_action == 'view'">{{_PackagePriceDetail.tempusd }}
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_PackagePriceDetail.tempusd | number: '1.2-4'}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td> -->
                                        <td>{{_PackagePriceDetail.tempusd | number: '1.2-4'}}</td>
                                        <!-- <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="text" [(ngModel)]="_PackagePriceDetail.usdvat"
                                                        *ngIf="_action != 'view'" pInputText>
                                                    <div *ngIf="_action == 'view'">{{_PackagePriceDetail.usdvat }}</div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_PackagePriceDetail.tempusdvatin | number: '1.2-4'}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td> -->
                                        <td>{{_PackagePriceDetail.tempusdvatin | number: '1.2-4'}}</td>

                                        <!-- <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="text" [(ngModel)]="_PackagePriceDetail.temprand"
                                                        *ngIf="_action != 'view'" pInputText>
                                                    <div *ngIf="_action == 'view'">{{_PackagePriceDetail.temprand }}
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_PackagePriceDetail.temprand | number: '1.2-4'}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td> -->
                                        <td>{{_PackagePriceDetail.temprand | number: '1.2-4'}}</td>
                                        <!-- <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="text" [(ngModel)]="_PackagePriceDetail.randvat"
                                                        *ngIf="_action != 'view'" pInputText>
                                                    <div *ngIf="_action == 'view'">{{_PackagePriceDetail.randvat }}
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_PackagePriceDetail.temprandvatin | number: '1.2-4'}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td> -->
                                        <td>{{_PackagePriceDetail.temprandvatin | number: '1.2-4'}}</td>
                                        <!-- <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="text" [(ngModel)]="_PackagePriceDetail.tempbond"
                                                        *ngIf="_action != 'view'" pInputText>
                                                    <div *ngIf="_action == 'view'">{{_PackagePriceDetail.tempbond}}
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_PackagePriceDetail.tempbond | number: '1.2-4'}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td> -->
                                        <td>{{_PackagePriceDetail.tempbond | number: '1.2-4'}}</td>
                                        <!-- <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="text" [(ngModel)]="_PackagePriceDetail.bondvat"
                                                        *ngIf="_action != 'view'" pInputText>
                                                    <div *ngIf="_action == 'view'">{{_PackagePriceDetail.bondvat }}
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_PackagePriceDetail.tempbondvatin | number: '1.2-4'}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td> -->
                                        <td> {{_PackagePriceDetail.tempbondvatin | number: '1.2-4'}}</td>
                                        <!-- <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="text" [(ngModel)]="_PackagePriceDetail.tempbank"
                                                        *ngIf="_action != 'view'" pInputText>
                                                    <div *ngIf="_action == 'view'">{{_PackagePriceDetail.tempbank }}
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_PackagePriceDetail.tempbank | number: '1.2-4'}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td> -->
                                        <td>{{_PackagePriceDetail.tempbank | number: '1.2-4'}}</td>
                                        <!-- <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="text" [(ngModel)]="_PackagePriceDetail.bankvat"
                                                        *ngIf="_action != 'view'" pInputText>
                                                    <div *ngIf="_action == 'view'">{{_PackagePriceDetail.bankvat }}
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_PackagePriceDetail.tempbankvatin | number: '1.2-4'}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td> -->
                                        <td>{{_PackagePriceDetail.tempbankvatin | number: '1.2-4'}}</td>

                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>


                    </p-tabPanel>
                    <p-tabPanel header="Variant Details">
                        <div class="card">

                            <p-table #dt [value]="_Productvariantdetail" [rows]="10" [paginator]="true"
                                [rowsPerPageOptions]="[10,25,50,100]" dataKey="productvariantdetailid"
                                [globalFilterFields]="['variantdescription']" [rowHover]="true"
                                dataKey="productvariantdetailid">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="text-align: center;width: 100px;">Action</th>
                                        <th>Variant Description</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_variantdetail>
                                    <tr>
                                        <td>
                                            <button pButton pRipple title="Add" icon="pi pi-plus"
                                                class="p-button-success p-mr-2" [disabled]="isDisabledButton"
                                                (click)="AddNewVariant($event)"></button>
                                            <button pButton pRipple title="Remove" icon="pi pi-trash"
                                                class="p-button-danger p-mr-2" [disabled]="isDisabledButton"
                                                (click)="RemoveVariant(_variantdetail)"></button>
                                        </td>

                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="text" [(ngModel)]="_variantdetail.variantdescription"
                                                        maxlength="100" *ngIf="_IsDisableinView" pInputText>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_variantdetail.variantdescription}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>

                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </p-tabPanel>
                    <!-- Ecommerce New Tabs -->

                    <p-tabPanel header="Product Descriptions" *ngIf="this._Action._TabViews">
                        <form [formGroup]="_productdescriptionform" (ngSubmit)="onSave()">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="sub-title">
                                        <div class="row">
                                            <div class="col-md-5">
                                                <h5>Short Description</h5>
                                            </div>
                                            <div class="col-md-7"></div>
                                        </div>
                                    </div>

                                    <span class="p-float-label">
                                        <textarea rows="2" cols="30" pInputTextarea
                                            formControlName="shortdescription"></textarea>
                                        <label for="shortdescription">Short Description </label>
                                    </span>
                                </div>
                                <div class="col-md-8">
                                    <div class="sub-title">
                                        <div class="row">
                                            <div class="col-md-5">
                                                <h5>Long Description</h5>
                                            </div>
                                            <div class="col-md-7"></div>
                                        </div>
                                    </div>
                                    <p-editor #editor [style]="{'height':'320px'}" formControlName="longdescription">
                                        <p-header>
                                            <span class="ql-formats">
                                                <button type="button" class="ql-bold" aria-label="Bold"></button>
                                                <button type="button" class="ql-italic" aria-label="Italic"></button>
                                                <button type="button" class="ql-underline"
                                                    aria-label="Underline"></button>
                                            </span>
                                        </p-header>
                                    </p-editor>
                                </div>
                            </div>
                        </form>



                    </p-tabPanel>
                    <p-tabPanel header="Product Images" *ngIf="this._Action._TabViews">
                        <table class="normal-table">
                            <tr>
                                <td style="width:20%">
                                    <div class="p-inputgroup">
                                        <div class="file-upload-wrapper" data-text="Select your file!">
                                            <!-- <input name="file-upload-field" type="file" class="file-upload-field"
                                                (change)="fileChangeEvent($event)" value="" accept="image/*"> -->
                                            <input name="file-upload-field" type="file"
                                                accept="image/png, image/gif, image/jpeg" class="file-upload-field"
                                                (change)="selectFile($event)" value="">
                                        </div>
                                    </div>
                                </td>
                                <td style="width:80%" colspan="4">
                                    <div class="row product-thumb" cdkDropList cdkDropListOrientation="horizontal"
                                        (cdkDropListDropped)="drop($event)">
                                        <div class="col" *ngFor="let product of _ProductImage" cdkDrag>
                                            <div class="product-container">
                                                <div class="product-preview">
                                                    <img src="{{product.productimage}}" alt="" class="img-fluid">
                                                    <div class="action-btn text-center">
                                                        <a href="javascript:void(0)" title="Delete"
                                                            (click)="removeImage(product)"><i
                                                                class="las la-trash-alt"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </p-tabPanel>

                    <p-tabPanel header="Product Q & A" *ngIf="this._Action._TabViews">
                        <form [formGroup]="_faqform" (ngSubmit)="onSave()">
                            <table class="normal-table">
                                <tr>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <textarea rows="2" cols="30" formControlName="question"
                                                pInputTextarea></textarea>
                                            <label for="question">Question </label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <textarea rows="2" cols="30" formControlName="answer"
                                                pInputTextarea></textarea>
                                            <label for="answer">Answer </label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" name="productname" formControlName="answerby"
                                                maxlength="50" pInputText>
                                            <label for="answerby"> Answer By <span class="hlt-txt">*</span></label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="true" optionLabel="metasubdescription"
                                                [options]="_FAQStatus" formControlName="recordstatus"
                                                optionValue="metasubcode">
                                            </p-dropdown>
                                            <label for="recordstatus"> Status <span class="hlt-txt">*</span></label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;"></td>
                                </tr>
                            </table>
                        </form>
                    </p-tabPanel>
                    <p-tabPanel header="Product Review" *ngIf="this._Action._TabViews">
                        <form [formGroup]="_Productreviewform" (ngSubmit)="onSave()">
                            <table class="normal-table">
                                <tr>
                                    <td>
                                        <h5>Rating</h5>
                                        <p-rating [cancel]="false" formControlName="reviewrating"></p-rating>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <textarea rows="2" cols="30" formControlName="reviewdescription"
                                                pInputTextarea></textarea>
                                            <label for="shortdescription">Review </label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" name="productname" formControlName="reviewby"
                                                maxlength="50" pInputText>
                                            <label for="answerby"> Review By <span class="hlt-txt">*</span></label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="true" formControlName="recordstatus"
                                                [options]="_ReviewStatus" optionLabel="metasubdescription"
                                                optionValue="metasubcode">
                                            </p-dropdown>
                                            <label for="recordstatus"> Status <span class="hlt-txt">*</span></label>
                                        </span>
                                    </td>

                                    <td></td>
                                </tr>

                            </table>
                        </form>
                    </p-tabPanel>
                    <p-tabPanel header="Price History" [hidden]="hidePriceHistory">
                        <table class="normal-table">
                            <tr>
                                <td>
                                    <span class="p-float-label">
                                        <p-calendar name="effectivefrom" [(ngModel)]="_effectiveFrom" [showIcon]="true">
                                        </p-calendar>
                                        <label for="effectivefrom"> From </label>
                                    </span>
                                </td>
                                <td>
                                    <span class="p-float-label">
                                        <p-calendar name="effectiveto" [(ngModel)]="_effectiveTo" [showIcon]="true">
                                        </p-calendar>
                                        <label for="effectiveto"> To </label>
                                    </span>
                                </td>
                                <td>
                                    <!-- <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_levels"
                                            optionLabel="levelname" optionValue="levelid" [(ngModel)]="_selectedlevelId"
                                            name="MarkUpLevel">
                                        </p-dropdown>
                                        <label for="MarkUpLevel"> Markup Level <span class="hlt-txt">*</span></label>
                                    </span> -->
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <button pButton pRipple label="Search" icon="pi pi-search" class="p-button-success"
                                        (click)="ProductPriceDetailSearch()"></button>
                                    <button pButton pRipple label="Clear" icon="pi pi-trash" (click)="ClearFields()"
                                        class="p-button-danger"></button>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </table>

                        <div class="card">
                            <p-table #dt [value]="_PriceHistories" [rows]="10" [paginator]="true"
                                [rowsPerPageOptions]="[10,25,50,100]" dataKey="roleid"
                                [globalFilterFields]="['HISTORYDATE','CURRENCYNAME','PURCHASERATEINUSD','PURCHASERATE', 'version']"
                                [rowHover]="true" dataKey="levelid"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="text-align: center;width: 100px;" pSortableColumn="sno">S.No
                                            <p-sortIcon field="sno">
                                            </p-sortIcon>
                                        </th>
                                        <th style="text-align: center;width: 150px;" pSortableColumn="version">History
                                            Version
                                            <p-sortIcon field="version">
                                            </p-sortIcon>
                                        </th>
                                        <th style="text-align: center;" pSortableColumn="HISTORYDATE">Date
                                            <p-sortIcon field="HISTORYDATE">
                                            </p-sortIcon>
                                        </th>
                                        <th style="text-align: center;" pSortableColumn="CURRENCYNAME">Currency
                                            <p-sortIcon field="CURRENCYNAME"></p-sortIcon>
                                        </th>
                                        <th style="text-align: center;" pSortableColumn="PURCHASERATE">Cost Price
                                            <p-sortIcon field="PURCHASERATE"></p-sortIcon>
                                        </th>
                                        <th style="text-align: center;" pSortableColumn="PURCHASERATEINUSD">Cost Price
                                            in USD
                                            <p-sortIcon field="PURCHASERATEINUSD"></p-sortIcon>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_pricehistory let-i="rowIndex">
                                    <tr>
                                        <td style="text-align: center; width: 100px;">
                                            {{i+1}}
                                        </td>
                                        <td style="text-align: center; width: 150px;">
                                            {{_pricehistory.version}}
                                        </td>
                                        <td>
                                            {{_pricehistory.historydate | date:'dd/MM/yyyy'}}
                                        </td>
                                        <td>
                                            {{_pricehistory.currencyname}}
                                        </td>
                                        <td style="text-align: right;">
                                            {{_pricehistory.purchaserate}}
                                        </td>
                                        <td style="text-align: right;">
                                            {{_pricehistory.purchaserateinusd}}
                                        </td>
                                    </tr>
                                </ng-template>

                            </p-table>
                        </div>
                    </p-tabPanel>
                </p-tabView>
            </div>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>
<p-dialog header="Image Cropper" [(visible)]="imageCropperTool" [modal]="true" [style]="{width: '1200px'}"
    [baseZIndex]="10000">
    <div class="text-center mb10">
        <button pButton pRipple label="" icon="las la-plus" pTooltip="Zoom +" tooltipPosition="top" (click)="zoomIn()"
            class="p-button p-mr-2"></button>
        <button pButton pRipple label="" icon="las la-minus" pTooltip="Zoom -" tooltipPosition="top" (click)="zoomOut()"
            class="p-button p-mr-2"></button>
        <button pButton pRipple label="" icon="las la-redo-alt" pTooltip="Reset Image" tooltipPosition="top"
            (click)="resetImage()" class="p-button-danger p-mr-2"></button>
        <button pButton pRipple label="" icon="las la-save" pTooltip="Save" tooltipPosition="top"
            (click)="saveImage($event)" class="p-button-success p-mr-2"></button>
    </div>
    <div class="row">
        <div class="col-md-6">
            <h5>Input</h5>
            <div class="crop-input">
                <image-cropper class="img-fluid" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                    [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="4/3" [resizeToWidth]="800"
                    [cropperMinWidth]="128" [onlyScaleDown]="true" [roundCropper]="false"
                    [canvasRotation]="canvasRotation" [transform]="transform" [alignImage]="'left'"
                    [style.display]="showCropper ? null : 'none'" format="png" (imageCropped)="imageCropped($event)"
                    (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
                    (loadImageFailed)="loadImageFailed()"></image-cropper>
            </div>
        </div>
        <div class="col-md-6">
            <h5>Output</h5>
            <img [src]="croppedImage" />
        </div>
    </div>
</p-dialog>
<p-toast position="bottom-right"></p-toast>