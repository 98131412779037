<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Sales Invoice POS</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple type="submit" title="Save" icon="pi pi-save"
                                [disabled]="isDisabledsave" (click)="onSave()"></button>
                            <button pButton pRipple type="button" (click)="reset($event)" title="Clear"
                                icon="pi pi-trash" class="" [disabled]="isDisabledClear"
                                class="p-button-danger"></button>
                            <button pButton pRiple type="button" (click)="goBack($event)" icon="pi pi-search"
                                title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                            <!-- <button pButton pRiple type="button" (click)="posPrint()" icon="pi pi-print" title="Print"
                                class="p-button p-button-success p-mr-2"></button> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <div class="row">
                    <div class="col-md-5">
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-5">
                                    <h5>Customer Information</h5>
                                </div>
                                <div class="col-md-7"></div>
                            </div>
                        </div>

                        <form [formGroup]="_salesinvoiceposform">
                            <table class="normal-table">
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="text" name="_copyinvoiceno" [(ngModel)]="_copyinvoiceno"
                                                [ngModelOptions]="{standalone: true}" [disabled]="_action == 'view'"
                                                (keyup.Enter)="CopyInvoice()" pInputText>
                                            <label for="_copyinvoiceno">Copy Invoice</label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input pInputText name="salesinvoiceno" formControlName="salesinvoiceno"
                                                disabled>
                                            <label for="salesinvoiceno">Invoice No</label>
                                        </span>
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <p-dropdown [autoDisplayFirst]="false" [options]="_Customernames"
                                                    optionLabel="customername" optionValue="customerid"
                                                    [virtualScroll]="true" itemSize="15" name="customerid"
                                                    formControlName="customerid" [filter]="true" filterBy="customername"
                                                    (onChange)="GetCustomer($event)">
                                                </p-dropdown>
                                                <!-- <p-autoComplete formControlName="customerid" 
                                                    [suggestions]="filteredcustomer" 
                                                    (completeMethod)="filtercustomer($event)" field="customername"
                                                    (onSelect)="GetCustomer($event)">
                                                    <ng-template let-customer pTemplate="item">
                                                        <div>{{customer.customername}}</div>
                                                    </ng-template>
                                                </p-autoComplete> -->
                                                <label for="customerid">Customer Name <span
                                                        class="hlt-txt">*</span></label>
                                            </span>
                                            <button class="p-inputgroup-addon p-button" title="Add" type="button"
                                                (click)="showBasicDialog()">
                                                <i class="las la-plus"></i>
                                            </button>
                                        </div>
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _salesinvoiceposform.get('customerid').touched) && _salesinvoiceposform.get('customerid').errors?.SelectCustomerName">
                                            Please select customer name
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-calendar name="salesinvoicedate" [showIcon]="true" dateFormat="dd/mm/yy"
                                                [readonlyInput]="true" formControlName="salesinvoicedate"
                                                [disabled]="true">
                                            </p-calendar>
                                            <label for="salesinvoicedate">Invoice Date <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _salesinvoiceposform.get('salesinvoicedate').touched) && _salesinvoiceposform.get('salesinvoicedate').errors?.SelectInvoiceDate">
                                            Please select invoice date
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Currencies"
                                                optionLabel="currencyname" optionValue="currencyid" name="currencyid"
                                                formControlName="currencyid"
                                                (onChange)="OnUserPreferredCurrencyChange()">
                                            </p-dropdown>
                                            <label for="currencyid">Currency</label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input pInputText name="mobileno" formControlName="mobileno"
                                                (keypress)="keypress.kpMobileNumber($event)" maxlength="20"
                                                (keydown.enter)="GetCustomerWithMobileNumber()">
                                            <label for="mobileno">Mobile No.</label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <input pInputText name="referenceorderno" formControlName="referenceorderno"
                                                maxlength="20">
                                            <label for="referenceorderno">Reference order No.</label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Collectingbranch"
                                                (onChange)="CheckCollectingBranchStock()" optionLabel="branchname"
                                                optionValue="branchid" name="collectingbranch"
                                                formControlName="collectingbranch">
                                            </p-dropdown>
                                            <label for="collectingbranch">Collecting Branch</label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Salesreferences"
                                                optionLabel="metasubdescription" optionValue="metasubcode"
                                                name="salesreference" formControlName="salesreference"
                                                [showClear]="true" (onChange)="GetReferenceNo($event)">
                                            </p-dropdown>
                                            <label for="salesreference">Sales Reference</label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_SalesReferenceno"
                                                optionLabel="referenceno" optionValue="referenceno" [showClear]="true"
                                                filterBy="referenceno" [filter]="true" [hidden]="_action == 'view'"
                                                name="salesreferenceno" formControlName="salesreferenceno"
                                                (onChange)="GetReferenceDetails($event)">
                                            </p-dropdown>
                                            <input type="text" name="salesreferenceno" [hidden]="_action != 'view'"
                                                formControlName="salesreferenceno" disabled pInputText>
                                            <label for="salesreferenceno">Reference No</label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="p-inputgroup">
                                            <!-- <span class="p-float-label">
                                                <p-dropdown [autoDisplayFirst]="false" [options]="_Deliverymode"
                                                    optionLabel="metasubdescription" optionValue="metasubid"
                                                    name="deliveryrequired" formControlName="deliverymode">
                                                </p-dropdown>
                                                <label for="deliveryrequired">Delivery Mode<span
                                                        class="hlt-txt">*</span></label>
                                            </span> -->
                                        </div>
                                        <!-- <span class="text-danger"
                                            *ngIf="(_submitted || _salesinvoiceposform.get('deliverymode').touched) && _salesinvoiceposform.get('deliverymode').errors?.SelectDeliveryMode">
                                            Please select delivery mode
                                        </span> -->
                                    </td>

                                    <td>
                                        <!-- <span class="p-float-label">
                                            <input pInputText name="deliveryrequired" formControlName="deliveryrequired"
                                                disabled>
                                            <label for="deliveryrequired">Address</label>
                                        </span> -->
                                    </td>
                                </tr>
                            </table>
                        </form>
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-5">
                                    <h5>Product Details</h5>
                                </div>
                                <div class="col-md-7"></div>
                            </div>
                        </div>
                        <form [formGroup]="_salesproductform">
                            <table class="normal-table">
                                <tr>
                                    <td>
                                        <div class="p-field-inline">
                                            <div class="p-formgroup-inline">
                                                <div class="p-field-checkbox">
                                                    <p-checkbox formControlName="isdamaged" label="Is Damaged?"
                                                        [disabled]="IsDisabledLayBuy" binary="true"
                                                        (onChange)="OnChangeDamagedProduct()">
                                                    </p-checkbox>
                                                </div>
                                            </div>

                                            <div class="p-field-control">
                                                <span class="p-float-label">
                                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Severities"
                                                        optionLabel="metasubdescription" optionValue="metasubcode"
                                                        formControlName="severity" (onChange)="OnChangeSeverity()">
                                                    </p-dropdown>
                                                    <label for="severity"> Severity </label>
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="text" name="productbarcode" formControlName="productbarcode"
                                                pInputText (keyup.enter)="FetchProductUsingBarcode()"
                                                autocomplete="off" />
                                            <label for="productbarcode">Bar code</label>
                                        </span>
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <p-multiSelect [filter]="true" [options]="_ProductTypes"
                                                optionLabel="producttypename" optionValue="producttypeid"
                                                formControlName="producttypeid" displaySelectedLabel=true
                                                [disabled]="IsDisabledLayBuy" (onChange)="GetProductCategory($event)">
                                            </p-multiSelect>
                                            <label for="producttypeid">Product Type <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <!-- <span class="text-danger"
                                            *ngIf="(_submitted1 || _salesproductform.get('producttypeid').touched) && _salesproductform.get('producttypeid').errors?.SelectProductType">
                                            Please Select Atleast One Product Type
                                        </span> -->
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-multiSelect [filter]="true" [options]="_ProductCategories"
                                                [disabled]="_action == 'view'" optionLabel="categoryname"
                                                optionValue="productcategoryid" [(ngModel)]="productcatid"
                                                [disabled]="IsDisabledLayBuy" [ngModelOptions]="{standalone: true}"
                                                displaySelectedLabel=true>
                                            </p-multiSelect>

                                            <!-- <p-multiSelect  [options]="_ProductTypes"
                                            optionLabel="producttypename" optionValue="producttypeid"
                                            formControlName="producttypeid" displaySelectedLabel=true
                                            (onChange)="GetProductCategory($event)">
                                        </p-multiSelect> -->


                                            <label for="productcategoryid">Product Category <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <!-- <span class="text-danger"
                                            *ngIf="(_submitted1 || _salesproductform.get('productcategoryid').touched) && _salesproductform.get('productcategoryid').errors?.SelectProductCategory">
                                            Please Select Atleast One Product Category
                                        </span> -->

                                    </td>
                                </tr>
                                <tr>

                                    <td>
                                        <span class="p-float-label">
                                            <!-- <p-dropdown [autoDisplayFirst]="false" [options]="_ProductNames"
                                                optionLabel="productname" optionValue="productid"
                                                formControlName="productid" [filter]="true" filterBy="searchfilter"
                                                [showClear]="true" (onChange)="GetVariantName($event)">
                                            </p-dropdown> -->
                                            <p-autoComplete formControlName="productid" [suggestions]="filteredProducts"
                                                [disabled]="IsDisabledLayBuy" (completeMethod)="filterProducts($event)"
                                                field="productname" (onSelect)="GetVariantName()">
                                                <ng-template let-product pTemplate="item">
                                                    <div>{{product.productname}}</div>
                                                </ng-template>
                                            </p-autoComplete>
                                            <label for="productid">Product Name <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted1 || _salesproductform.get('productid').touched) && _salesproductform.get('productid').errors?.SelectProductName">
                                            Please Select Product Name
                                        </span>
                                    </td>
                                    <!-- <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Variants"
                                                optionLabel="variantdescription" optionValue="productvariantdetailid"
                                                [showClear]="true" formControlName="variantid">
                                            </p-dropdown>
                                            <label for="variantid">Variant </label>
                                        </span>
                                    </td> -->
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_packdetails"
                                                optionLabel="metasubdescription" optionValue="metasubcode"
                                                [showClear]="true" formControlName="packtype">
                                            </p-dropdown>
                                            <label for="packtype">Pack Name </label>
                                        </span>
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <p-inputNumber *ngIf="hidequantity == true" formControlName="quantity"
                                                autocomplete="off" maxlength="14"
                                                (keyup.enter)="GetProductPrice($event)" mode="decimal"
                                                [minFractionDigits]="0" [maxFractionDigits]="0"></p-inputNumber>
                                            <label *ngIf="hidequantity == true" for="quantity">Quantity </label>
                                        </span>

                                        <span class="p-float-label">
                                            <p-inputNumber *ngIf="hidequantity != true" formControlName="quantity"
                                                autocomplete="off" maxlength="14"
                                                (keyup.enter)="GetProductPrice($event)" mode="decimal"
                                                [minFractionDigits]="3" [maxFractionDigits]="3"></p-inputNumber>
                                            <label *ngIf="hidequantity != true" for="quantity"> Quantity <span
                                                    class="hlt-txt">*</span>
                                            </label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted1 || _salesproductform.get('quantity').touched) && _salesproductform.get('quantity').errors?.required">
                                            Please Enter Quantity
                                        </span>

                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="text" name="stockinhand" formControlName="stockinhand" disabled
                                                pInputText>
                                            <label for="stockinhand">Stock In Hand </label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <button pButton pRipple label="Add" icon="pi pi-plus" class="p-button-success"
                                            *ngIf="_action != 'view'" [disabled]="IsDisabledLayBuy" type="button"
                                            (click)="GetProductPrice($event)"></button>
                                        <button pButton pRipple label="Clear" icon="pi pi-trash" class="p-button-danger"
                                            *ngIf="_action != 'view'" [disabled]="IsDisabledLayBuy" type="button"
                                            (click)="Clear($event)"></button>
                                    </td>
                                    <!--<td></td>
                                <td></td>
                                <td></td>
                                <td></td> -->
                                </tr>
                            </table>
                        </form>
                    </div>
                    <div class="col-md-7">
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-5">
                                    <h5>Payment Details</h5>
                                </div>
                                <div class="col-md-7"></div>
                            </div>
                        </div>
                        <form [formGroup]="_salesinvoiceposform">
                            <table class="normal-table">
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <p-multiSelect [filter]="false" [options]="_advancereferences"
                                                optionLabel="refNo" optionValue="refNo" [virtualScroll]="true"
                                                itemSize="30" [filter]="true" filterBy="refNo"
                                                (onChange)="OnChangeAdvanceReferences()" [(ngModel)]="_advances"
                                                [ngModelOptions]="{standalone: true}" displaySelectedLabel="true"
                                                [disabled]="_IsdisabledAdvanceRef ||  IsDisabledLayBuy || _action != 'create'">
                                            </p-multiSelect>
                                            <label>Advance References</label>
                                        </span>
                                        <!-- </form> -->
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="number" name="customeradvanceamount" disabled
                                                formControlName="customeradvanceamount" pInputText>
                                            <label for="customeradvanceamount">Advance Amount</label>
                                        </span>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="p-formgroup-inline">
                                            <div class="p-field-checkbox">
                                                <p-checkbox formControlName="applyrewardpoints"
                                                    [disabled]="IsDisabledLayBuy"
                                                    (onChange)="OnChangeRewardPointApplicable($event)"
                                                    label="Apply Reward Points" binary="true"></p-checkbox>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="number" name="applicablerewardpoints"
                                                formControlName="applicablerewardpoints" [disabled]="_isDisabledpoints"
                                                (onBlur)="allocateRewardPoints($event)"
                                                (keyup.enter)="allocateRewardPoints($event)" pInputText>
                                            <label for="applicablerewardpoints">Applicable Points</label>

                                            <!--[attr.disabled]="_salesinvoicetaxform.get('applyrewardpoints').value == true"  -->
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="number" name="remainingpoints"
                                                formControlName="remainingpoints" disabled pInputText>
                                            <label for="remainingpoints">Remaining Points</label>
                                        </span>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="p-formgroup-inline">
                                            <div class="p-field-checkbox">
                                                <p-checkbox formControlName="creditnoteapplicable"
                                                    [disabled]="IsDisabledLayBuy" label="Apply Credit Note"
                                                    binary="true" (onChange)="OnChangeCreditNoteApplicable()"
                                                    (onChange)="GetCreditNoteDetails()"></p-checkbox>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_SalesInvoiceNumbers"
                                                optionLabel="refNo" optionValue="refNo" name="creditnoteinvoiceno"
                                                formControlName="creditnoteinvoiceno" [virtualScroll]="true"
                                                itemSize="30" [filter]="true" filterBy="refNo"
                                                (onChange)="OnChangeSalesInvoiceNo($event)"
                                                [disabled]="!_salesinvoiceposform.get('creditnoteapplicable').value || _action != 'create'">
                                            </p-dropdown>
                                            <label for="salesby">Sales Invoice No</label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-multiSelect [filter]="false" [options]="_CreditNoteRefernces"
                                                optionLabel="voucherno" optionValue="creditnoteid"
                                                [virtualScroll]="true" itemSize="30" [filter]="true"
                                                filterBy="voucherno" formControlName="creditnoteid"
                                                displaySelectedLabel="true" (onChange)="OnChangeCreditNote()"
                                                [(ngModel)]="_creditnoteids"
                                                [disabled]="!_salesinvoiceposform.get('creditnoteapplicable').value || _action != 'create'">
                                            </p-multiSelect>
                                            <label for="creditnoteid">Credit Notes <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="_salesinvoiceposform.get('creditnoteapplicable').value && (_submitted || _salesinvoiceposform.get('creditnoteid').touched) && _salesinvoiceposform.get('creditnoteid').errors?.SelectProductType">
                                            Please Select Atleast One Credit Note
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input pInputText name="creditnoteamount" formControlName="creditnoteamount"
                                                disabled>
                                            <label for="creditnoteamount">Credit Note Amount</label>
                                        </span>
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown #id [autoDisplayFirst]="false" [options]="_Descriptions"
                                                optionLabel="otherchargename" optionValue="otherchargeid" filter="true"
                                                filterBy="otherchargename" [showClear]="true"
                                                [disabled]="IsDisabledLayBuy ||_action != 'create'"
                                                [(ngModel)]="otherchargeid" [ngModelOptions]="{standalone: true}"
                                                (onChange)="OnchangeOtherCharge($event)">
                                            </p-dropdown>
                                            <label for="otherchargeid">Other Charges</label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input pInputText name="otherchargesamount" type="number"
                                                formControlName="otherchargesamount"
                                                (keypress)="keypress.kpDecimalInput($event)"
                                                (keyup)="oneditothercharge()">
                                            <label for="otherchargesamount">Other Charges Amount</label>
                                        </span>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <input pInputText name="productamount" formControlName="productamount"
                                                disabled>
                                            <label for="productamount">Gross Amount</label>
                                        </span>
                                    </td>

                                    <td>
                                        <span class="p-float-label">
                                            <input pInputText name="taxamount" formControlName="taxamount" disabled>
                                            <label for="taxamount">Tax Amount</label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input pInputText name="amountinusd" formControlName="amountinusd" disabled>
                                            <label for="amountinusd">Net Amount in USD</label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label" [hidden]="showpprofit">
                                            <input pInputText name="profitamount" disabled
                                                formControlName="profitamount">
                                            <label for="profitamount">Profit Amount</label>
                                        </span>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <input pInputText name="netamountincustomercurrency" disabled
                                                formControlName="netamountincustomercurrency">
                                            <label for="netamountincustomercurrency">Amount in Customer Currency</label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="number" pInputText name="paidamount"
                                                formControlName="paidamount" disabled
                                                (keyup)="CalculateBalanceAmount()">
                                            <label for="paidamount">Customer Paid Amount</label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="number" name="customerbalanceamount" disabled
                                                formControlName="customerbalanceamount" pInputText>
                                            <label for="customerbalanceamount">Customer Balance Amount</label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input pInputText name="balanceamount" formControlName="balanceamount"
                                                disabled>
                                            <label for="balanceamount">Balance paid to customer</label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="p-formgroup-inline">
                                            <div class="p-field-checkbox">
                                                <p-checkbox formControlName="deliveryrequired"
                                                    [disabled]="IsDisabledLayBuy"
                                                    (onChange)="OnChangeDeliverymode($event)"
                                                    label="Is Delivery Required" binary="true"></p-checkbox>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Deliverymode"
                                                optionLabel="metasubdescription" optionValue="metasubid"
                                                formControlName="deliverymode">
                                            </p-dropdown>
                                            <label for="deliverymode">Delivery Mode</label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Salesby"
                                                optionLabel="username" optionValue="userid" name="salesby" filter="true"
                                                filterBy="username" [showClear]="true" formControlName="salesby">
                                            </p-dropdown>
                                            <label for="salesby">Sales By <span class="hlt-txt">*</span></label>
                                        </span>

                                        <span class="text-danger"
                                            *ngIf="(_submitted || _salesinvoiceposform.get('salesby').touched) && _salesinvoiceposform.get('salesby').errors?.SelectSupplier">
                                            Please select sales by
                                        </span>
                                    </td>
                                    <td rowspan="2">
                                        <span class="p-float-label">
                                            <textarea rows="3" name="Remarks" pInputTextarea
                                                formControlName="remarks"></textarea>
                                            <label for="remarks">Remarks </label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="p-formgroup-inline">
                                            <h6>Payment Mode </h6>
                                            <div class="p-field-radiobutton" *ngFor="let PM of _PaymentModes">
                                                <p-radioButton name="paymenttype" formControlName="paymenttype"
                                                    label={{PM.metasubdescription}} value={{PM.metasubcode}}
                                                    (click)="OnChangePaymode()">
                                                </p-radioButton>
                                            </div>
                                        </div>
                                    </td>
                                    <td style="width:20%">
                                        <span class="p-float-label">
                                            <p-dropdown #id [autoDisplayFirst]="false" [options]="_toaccounts"
                                                optionLabel="name" optionValue="ledgerid"
                                                formControlName="bankledgerid">
                                            </p-dropdown>
                                            <label for="bankledgerid">Bank Accounts<span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input pInputText name="paymentreferenceno"
                                                formControlName="paymentreferenceno">
                                            <label for="paymentreferenceno">Reference No</label>
                                        </span>
                                    </td>
                                </tr>

                                <br>
                            </table>
                            <div class="sub-title">
                                <div class="row">
                                    <div class="col-md-5">
                                        <h5>Amount Details</h5>
                                    </div>
                                    <div class="col-md-7"></div>
                                </div>
                            </div>
                            <table class="normal-table">
                                <tr>
                                    <td style="width:25%">
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Multicurrencies1"
                                                optionLabel="currencyname" optionValue="currencyid"
                                                (onChange)="OnSelectCurrency1()" formControlName="cuscurrencyid1">
                                            </p-dropdown>
                                            <label for="cuscurrencyid1">Currency 1<span class="hlt-txt">*</span></label>
                                        </span>
                                    </td>
                                    <td style="width: 25%;">
                                        <span class="p-float-label">
                                            <input type="number" name="cuscurrencyamount1"
                                                formControlName="cuscurrencyamount1" (keyup)="OnSelectCurrency2()"
                                                (focusout)="OnSelectCurrency2()" pInputText>
                                            <label for="cuscurrencyamount1">Customer Paid Amount 1
                                            </label>
                                        </span>
                                    </td>
                                    <td style="width:25%">
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Multicurrencies2"
                                                optionLabel="currencyname" optionValue="currencyid"
                                                (onChange)="Multicurrencycalculation()"
                                                formControlName="cuscurrencyid2">
                                            </p-dropdown>
                                            <label for="cuscurrencyid2">Currency 2<span class="hlt-txt">*</span></label>
                                        </span>
                                    </td>
                                    <td style="width: 25%;">
                                        <span class="p-float-label">
                                            <input type="number" name="cuscurrencyamount2"
                                                formControlName="cuscurrencyamount2" (keyup)="onchangeMulticurrency2()"
                                                (focusout)="onchangeMulticurrency2()" pInputText>
                                            <label for="cuscurrencyamount2">Customer Paid Amount 2
                                            </label>
                                        </span>
                                    </td>

                                </tr>
                            </table>

                            <div class="sub-title">
                                <div class="row">
                                    <div class="col-md-5">
                                        <h5>Return Amount details</h5>
                                    </div>
                                    <div class="col-md-7"></div>
                                </div>
                            </div>
                            <table class="normal-table">
                                <tr>
                                    <td style="width:25%">
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Currencies"
                                                optionLabel="currencyname" optionValue="currencyid" [filter]="true"
                                                filterBy="currencyname" [showClear]="true"
                                                (onChange)="ChangeReturnCurrency($event)"
                                                formControlName="returncurrencyid">
                                            </p-dropdown>
                                            <label for="returncurrencyid">Return Currency<span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                    </td>
                                    <td style="width: 25%;">
                                        <span class="p-float-label">
                                            <input type="number" name="returnamount" formControlName="returnamount"
                                                disabled pInputText>
                                            <label for="returnamount">Return Amount
                                            </label>
                                        </span>
                                    </td>
                                    <td style="width: 25%;">
                                        <span class="p-float-label">
                                            <input type="number" name="creditnotebalanceamount"
                                                [ngClass]="{'hlt-color': _salesinvoiceposform.get('creditnotebalanceamount').value > 0 }"
                                                formControlName="creditnotebalanceamount" disabled pInputText>
                                            <label for="creditnotebalanceamount">Creditnote Balance Amount
                                            </label>
                                        </span>
                                    </td>
                                    <td></td>
                                </tr>
                            </table>
                        </form>
                    </div>
                </div>
                <div class="card">
                    <p-table [value]="_ProductDetails" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[5,10,25,50,100]"
                        [globalFilterFields]="['productname','variantdescription']" [rowHover]="true" dataKey="billno"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true" [(selection)]="selectedProductDetailsRows">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;width:100px;" class="text-center">Action</th>
                                <th>Pack Type</th>
                                <th>Barcode</th>
                                <th>Product Type</th>
                                <th>Product Name</th>
                                <!-- <th>Variant</th> -->
                                <th>Pack Qty</th>
                                <th>Per Pack</th>
                                <th>Qty</th>
                                <!-- <th>Remaining Stock</th> -->
                                <th>UOM</th>
                                <th>Unit Price</th>
                                <th>Price</th>
                                <!-- <th>Default Discount %</th> -->
                                <th>Req Dist %</th>
                                <!-- <th>Approved Discount %</th> -->
                                <th>Tax %</th>
                                <!-- <th>Default Discount Amount</th> -->
                                <th>Req Dis Amt</th>
                                <!-- <th>Approved Discount Amount</th> -->
                                <th>Tax Amt</th>
                                <th>Final Amt</th>
                                <th>Is Scheme Applied</th>
                                <!-- <th>Remarks</th> -->
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_ProductDetail>
                            <tr>
                                <td style="text-align:center">
                                    <button pButton pRipple title="Remove" icon="pi pi-trash"
                                        [disabled]="IsDisabledLayBuy" class="p-button-danger p-mr-2"
                                        (click)="RemoveProduct(_ProductDetail)"></button>
                                </td>
                                <td>
                                    {{_ProductDetail.packtypename}}
                                </td>
                                
                                <td>
                                    {{_ProductDetail.barcode}}
                                </td>
                                <td>
                                    {{_ProductDetail.producttype}}

                                </td>
                                <td>
                                    {{_ProductDetail.productname}}

                                </td>
                                <!-- <td>
                                    {{_ProductDetail.variantdescription}}

                                </td> -->
                               
                                <td>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="text" [(ngModel)]="_ProductDetail.quantity"
                                                [disabled]="_ProductDetail.disableFreeProductFeild || _action != 'create'"
                                                (keyup)="allocatequantity(_ProductDetail)"
                                                (focusout)="allocatequantity(_ProductDetail)" pInputText>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_ProductDetail.quantity}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    {{_ProductDetail.quantityperpack}}
                                </td>
                                <td>
                                    {{_ProductDetail.stockqty}}
                                </td>
                                <td>
                                    {{_ProductDetail.uomname}}

                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="text" [(ngModel)]="_ProductDetail.unitprice"
                                                [disabled]="_ProductDetail.disableunitprice"
                                                (keydown.enter)="OnChangeUnitPrice(_ProductDetail)"
                                                (keyup.tab)="OnChangeUnitPrice(_ProductDetail)"
                                                (onBlur)="OnChangeUnitPrice(_ProductDetail)" pInputText
                                                *ngIf="_action != 'view'">
                                            <div *ngIf="_action == 'view'">{{_ProductDetail.unitprice | number: '1.2'}}
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_ProductDetail.unitprice | number: '1.2'}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    {{_ProductDetail.productamount | number: '1.2':'en-US'}}

                                </td>
                                <!-- <td>

                                    {{_ProductDetail.discountpercentage}}

                                </td> -->
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="text" [(ngModel)]="_ProductDetail.customerdiscountpercentage"
                                                [disabled]="IsDisableRequestDiscount || IsDisabledLayBuy || _ProductDetail.disableFreeProductFeild || _action != 'create'"
                                                (keyup)="allocatequantity(_ProductDetail)"
                                                (focusout)="allocatequantity(_ProductDetail)" pInputText>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_ProductDetail.customerdiscountpercentage}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <!-- <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="text" [disabled]="_ProductDetail.disableFreeProductFeild"
                                                [(ngModel)]="_ProductDetail.approveddiscountpercentage" pInputText>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_ProductDetail.approveddiscountpercentage}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td> -->
                                <td>
                                    {{_ProductDetail.taxpercentage}}
                                </td>
                                <!-- <td>
                                    {{_ProductDetail.discountamount | number: '1.2' }}
                                </td> -->
                                <td>
                                    {{_ProductDetail.customerdiscountamount | number: '1.2' }}
                                </td>
                                <!-- <td>
                                    {{_ProductDetail.approveddiscountamount | number: '1.2-4'}}
                                </td> -->

                                <td>
                                    {{_ProductDetail.taxamount | number: '1.2' }}

                                </td>
                                <td>
                                    {{_ProductDetail.finalamount | number: '1.2'}}

                                </td>
                                <td>
                                    <label [hidden]="_ProductDetail.iscshemeapplied">No</label>
                                    <label [hidden]="!_ProductDetail.iscshemeapplied">Yes</label>
                                </td>
                                <!-- <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="text" [(ngModel)]="_ProductDetail.remarks" pInputText
                                                [disabled]="_ProductDetail.disableFreeProductFeild || _action != 'create'">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_ProductDetail.remarks}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td> -->
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <table>
                    <tr>
                        <td>
                            <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                        </td>
                    </tr>
                </table>
            </div>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

            <p-dialog #cd [style]="{width: '400px'}" header="Payment Request"
                [(visible)]="paymentModel.visiblePaymentPopup" [modal]="true" [baseZIndex]="10000" [draggable]="false"
                [resizable]="false" [closable]="false">
                <ng-template pTemplate="content">
                    <label [innerHTML]="paymentModel.Message"></label>
                </ng-template>
                <ng-template pTemplate="footer">
                    <button type="button" pButton icon="pi pi-refresh" label="Retry" autofocus class="p-button-success"
                        (click)="paymentModel.Retry()" [hidden]="paymentModel.IsRetryEnable"></button>
                    <!-- <button type="button" pButton icon="pi pi-dollar" label="Change Cash"
                        (click)="paymentModel.ChangeCash()"></button> -->
                    <button type="button" pButton icon="pi pi-times" label="Cancel Invoice" class="p-button-danger"
                        (click)="paymentModel.CancelInvoice()" [hidden]="paymentModel.IsRetryEnable"></button>
                    <button type="button" pButton icon="pi pi-times" label="Cancel Request" class="p-button-warning"
                        (click)="paymentModel.CancelRequest()"
                        [hidden]="paymentModel.isHideCancelRequestButton"></button>
                </ng-template>
            </p-dialog>

            <p-dialog #warning [style]="{width: '400px'}" header="Warning" [(visible)]="creditnotemodel.showdialog"
                [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="false">
                <ng-template pTemplate="content">
                    <label [innerHTML]="creditnotemodel.message"></label>
                </ng-template>
                <ng-template pTemplate="footer">
                    <button type="button" pButton icon="las la-check" label="Yes" autofocus
                        (click)="creditnotemodel.Yes()"></button>
                    <button type="button" pButton icon="pi pi-times" label="No" autofocus
                        (click)="creditnotemodel.Cancel()"></button>
                </ng-template>
            </p-dialog>
        </div>
    </div>
    <!-- For Product Type Electronics -->
    <p-dialog header="Electrical Product Type" [(visible)]="displayBasicProductType" modal="true" class="popup-new"
        [style]="{width: '500px'}">
        <p>Please Advise the Customer to Check Electrical Products Before Leaving the Premises</p>
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-check"
                (click)="CloseProductTypeDialog(false,Salesinvoiceresultjson,salesproductprice)" label="OK"
                styleClass="p-button-text"></p-button>
        </ng-template>
    </p-dialog>
    <!-- For Scheme -->
    <p-dialog header="Scheme" [(visible)]="dispalyBasicScheme" modal="true" class="popup-new"
        [style]="{width: '500px'}">
        <ul *ngFor="let sr of schemeRemarks" class="bullet01">
            <li><i class="las la-gift"></i>{{sr}}</li>
        </ul>
        <!-- <p>{{schemeRemarks}}</p> -->
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-check"
                (click)="CloseProductTypeDialog(false,Salesinvoiceresultjson,salesproductprice)"
                label="Proceed With Current Qty" styleClass="p-button-text"></p-button>
            <p-button icon="las la-pen" (click)="modifyqty()" label="Modify Qty" styleClass="p-button-text"></p-button>
        </ng-template>
    </p-dialog>
    <!-- For pop Up -->
    <p-dialog header="Customers" [(visible)]="ManageDialog" [style]="{width: '350px',height:'400px'}"
        [maximizable]="false">
        <ng-template pTemplate="content">
            <form [formGroup]="_customerform" (ngSubmit)="Save()">
                <table class="normal-table">
                    <tr>
                        <td>
                            <span class="p-float-label">
                                <input type="text" name="customername" formControlName="customername" pInputText>

                                <label for="customername">Customer Name <span class="hlt-txt">*</span></label>
                            </span>
                            <span class="text-danger"
                                *ngIf="(_customersubmitted || _customerform.get('customername').touched) && _customerform.get('customername').errors?.CustomerNameRequired">
                                Please Enter Customer Name
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span class="p-float-label">
                                <input type="text" name="vatno" formControlName="vatno" pInputText>
                                <label for="vatno">VAT No. <span class="hlt-txt">*</span></label>
                            </span>
                            <span class="text-danger"
                                *ngIf="(_customersubmitted || _customerform.get('vatno').touched) && _customerform.get('vatno').errors?.CustomerNameRequired">
                                Please Enter Vat No
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span class="p-float-label">
                                <input type="text" name="bpno" formControlName="bpno" pInputText>
                                <label for="bpno">BP No. <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span class="p-float-label">
                                <input type="text" name="mobileno" formControlName="mobileno" pInputText
                                    (keypress)="keypress.kpMobileNumber($event)" maxlength="20">

                                <label for="mobileno">Phone Number <span class="hlt-txt">*</span></label>
                            </span>
                            <span class="text-danger"
                                *ngIf="(_customersubmitted || _customerform.get('mobileno').touched) && _customerform.get('mobileno').errors?.MobileNumberRequired">
                                Please Enter Phone Number
                            </span>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <span class="p-float-label">
                                <input type="text" name="mailid" formControlName="mailid" pInputText>

                                <label for="mailid">Email <span class="hlt-txt">*</span></label>
                            </span>
                            <span class="text-danger"
                                *ngIf="(_customersubmitted || _customerform.get('mailid').touched) && _customerform.get('mailid').errors?.EmailIDRequired">
                                Please Enter Email
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span class="p-float-label">
                                <input type="text" name="address" formControlName="address" pInputText>
                                <label for="address"> Address <span class="hlt-txt">*</span></label>
                            </span>
                            <span class="text-danger"
                                *ngIf="(_customersubmitted || _customerform.get('address').touched) && _customerform.get('address').errors?.AddressRequired">
                                Please Enter Address
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span class="p-float-label">
                                <p-dropdown [options]="_customertype" [autoDisplayFirst]="false" name="customertype"
                                    optionLabel="metasubdescription" optionValue="metasubcode"
                                    formControlName="customertype">
                                </p-dropdown>
                                <label for="customertype"> Customer Type <span class="hlt-txt">*</span></label>
                            </span>
                            <span class="text-danger"
                                *ngIf="(_customersubmitted || _customerform.get('customertype').touched) && _customerform.get('customertype').errors?.CustomerNameRequired">
                                Please choose Customer Type
                            </span>
                        </td>
                    </tr>
                </table>
            </form>
        </ng-template>

        <ng-template pTemplate="footer">

            <button pButton pRipple label="Save" (click)="Save()" icon="las la-check" class="p-button-text"></button>
            <button pButton pRipple label="Cancel" icon="pi pi-times" (click)="Cancel()" class="p-button-text"></button>
        </ng-template>

    </p-dialog>
    <p-dialog header="Reward Details" [(visible)]="dispalyBasicReward" modal="true" class="popup-new"
        [style]="{width: '800px',height:'500px'}">
        <div class="row">
            <div class="col-md-6">
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-6">
                            <h5>Earned Reward Points</h5>
                        </div>
                        <div class="col-md-6 text-right">
                            <h3>Total : {{totalearningpoints| number : '1.2'}}</h3>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <p-table #dtBranchWiseStocks [value]="earnedrewarddetails" selectionMode="single" [rows]="15"
                        [paginator]="true" [rowsPerPageOptions]="[15,25,50]"
                        [globalFilterFields]="['branchname','closingstock']" [rowHover]="true" dataKey="branchid">
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="earneddate">Earned Date
                                    <p-sortIcon field="earneddate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="earnedpoints" style="width: 120px;">Earned Points
                                    <p-sortIcon field="earnedpoints">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="expiredate" style="width: 120px;">Expire Date
                                    <p-sortIcon field="expiredate">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_earnedpoint>
                            <tr>
                                <td>
                                    {{_earnedpoint.earneddate | date:'dd-MMM-yyyy'}}
                                </td>
                                <td class="text-right">
                                    {{_earnedpoint.earnedpoints| number : '1.2'}}
                                </td>
                                <td>
                                    {{_earnedpoint.expiredate | date:'dd-MMM-yyyy'}}
                                </td>

                            </tr>
                        </ng-template>
                        <!-- <ng-template pTemplate="footer">
                            <tr>
                                <td colspan="2" class="text-right"></td>

                            </tr>
                        </ng-template> -->
                    </p-table>
                </div>
            </div>
            <div class="col-md-6">
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-6">
                            <h5>Used Reward Points</h5>
                        </div>
                        <div class="col-md-6  text-right">
                            <h3>Total : {{totalusedpoints| number : '1.2'}}</h3>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <p-table #dtProductCategoryWiseStocks [value]="usedrewarddetails" [rows]="15" [paginator]="true"
                        [rowsPerPageOptions]="[15,25,50]" [globalFilterFields]="['productcategoryname','closingstock']"
                        [rowHover]="true" dataKey="productcategoryid">
                        <ng-template pTemplate="header">
                            <tr>

                                <th pSortableColumn="useddate">Used Date
                                    <p-sortIcon field="useddate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="usedpoints" style="width: 120px;">Used Points
                                    <p-sortIcon field="usedpoints">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-_usedpoints let-i="rowIndex">
                            <tr>
                                <td>
                                    {{_usedpoints.useddate | date:'dd-MMM-yyyy'}}
                                </td>
                                <td class="text-right">
                                    {{_usedpoints.usedpoints| number : '1.2'}}
                                </td>
                            </tr>
                        </ng-template>
                        <!-- <ng-template pTemplate="footer">
                            <tr>
                              
                                <td colspan="2" class="text-right"></td>

                            </tr>
                        </ng-template> -->
                    </p-table>
                </div>


            </div>
            <div class="col-md-12 text-right">
                <h5>Total Remaining Points : {{totalremainingpoints| number : '1.2'}}</h5>
            </div>
        </div>

    </p-dialog>
</div>
<p-toast position="bottom-right"></p-toast>