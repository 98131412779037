export class Salesreturnproductdetailview {
    salesreturnid: number;
    salesinvoiceid: number;
    salesinvoiceno: string;
    productid: number;
    productname: string;
    variantid: number;
    variantdescription: string;
    invqty: number;
    invoicequantity: number;
    returnquantity: number;
    uomcode: string;
    uomname: string;
    unitprice: number;
    productamount: number;
    accountingyear: number;
    createdby: number;
    createdon: Date | string;
    modifiedby: number;
    modifiedon: Date | string;
    salereturntype: string;
    series: string;
    returnreason: string;
    taxid: number;
    taxpercentage: number;
    taxamount: number;
    temptaxamount: number;
    totalamount: number;
    discountpercentage: number;
    discountamount: number;
    remarks: string;
    deliverybranch: number;
    deliverybranchname: string;
    salesuom: string;
    salesuomname: string;
    packtype: string;
    packtypename: string;
    quantityperpack: number;

}
