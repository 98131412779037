<div class="page-container">
    <div class="inner-page-container">
      <div class="page-loader" *ngIf="_IsProgressSpinner">
        <p-progressSpinner strokeWidth="5"></p-progressSpinner>
      </div>
      <div class="white">
        <div class="page-title">
          <div class="row">
            <div class="col-md-5">
              <h3>Consignment Stock Report</h3>
            </div>
          </div>
        </div>
        <div class="form-container scroll-y">
          <form [formGroup]="_rptconsignmentstockform">
            <table class="normal-table">
              <tr>
                <td style="width: 20%;">
                    <span class="p-float-label">
                        <p-calendar [showIcon]="true" formControlName="fromdate" [monthNavigator]="true"
                            [minDate]="mindate" [maxDate]="maxdate"
                            [yearNavigator]="true" yearRange="1980:2030" dateFormat="dd/mm/yy">
                        </p-calendar>
                        <label for="fromdate">From Date</label>
                    </span>
                </td>
                <td style="width: 20%;">
                    <span class="p-float-label">
                        <p-calendar [showIcon]="true" formControlName="todate" [monthNavigator]="true"
                            [yearNavigator]="true"
                            [minDate]="mindate" [maxDate]="maxdate"
                            yearRange="1980:2030" dateFormat="dd/mm/yy">
                        </p-calendar>
                        <label for="todate">To Date</label>
                    </span>
                </td>
                <td style="width: 20%">
                    <span class="p-float-label">
                      <p-multiSelect
                        [options]="_Customerlist"
                        optionValue="customerid" optionLabel="customername"
                        formControlName="customerid" 
                        [filter]="true" filterBy="customername"> 
                      </p-multiSelect>
                      <label>Customer Name</label>
                    </span>
                  </td>
                <td style="width: 20%">
                  <span class="p-float-label">
                    <p-multiSelect
                        [options]="_Consignmentbranch"
                        optionValue="cutomerbranchid" optionLabel="customerbranchname"
                        formControlName="customerbranchid"
                        [filter]="true" filterBy="customerbranchname"> 
                    </p-multiSelect>
                    <label>Customer Branch Name </label>
                  </span>
                </td>
                <!-- <td style="width: 20%">
                    <span class="p-float-label">
                      <p-dropdown></p-dropdown>
                      <label>Delivery Note No <span class="hlt-txt">*</span></label>
                    </span>
                  </td> -->
                  <td></td>
              </tr>
              <tr>
                <td style="width: 20%">
                    <span class="p-float-label">
                      <p-multiSelect
                          [options]="_Producttypelist"
                          optionValue="producttypeid" optionLabel="producttypename"
                          formControlName="producttypeid" (onChange)="getProductCategory($event)"
                          [filter]="true" filterBy="customerbranchname"> 
                      </p-multiSelect>
                      <label>Product Type </label>
                    </span>
                  </td>
                  <td style="width: 20%;">
                    <span class="p-float-label">
                        <p-multiSelect [options]="_productcategorylist" optionLabel="categoryname"
                            [selectionLimit]="50" formControlName="productcategoryid"
                            (onChange)="getProduct($event)" optionValue="productcategoryid" [virtualScroll]="true" itemSize="30">
                        </p-multiSelect>
                        <label for="productcategoryid">Product Category Name</label>
                    </span>
                </td>
                <td style="width: 20%;">
                    <span class="p-float-label">
                        <p-multiSelect [options]="_productlist" optionLabel="productname"
                            optionValue="productid" formControlName="productid"
                            (onChange)="ChangeProduct($event)"
                            [virtualScroll]="true" itemSize="30">
                        </p-multiSelect>
                        <label for="productid">Product Name </label>
                    </span>
                </td>
                <td style="width: 20%;">
                    <span class="p-float-label">
                        <p-multiSelect [options]="_brandlist" optionLabel="brandname" optionValue="brandid"
                            formControlName="brandid"
                            [virtualScroll]="true" itemSize="30">
                        </p-multiSelect>
                        <label for="brandid">Brand Name </label>
                    </span>
                </td>
            </tr>
            <tr>
                
              </tr>
              <tr>
                <td>
                  <button
                    pButton
                    pRipple
                    label="Search"
                    (click)="GenerateReport($event)"
                    icon="pi pi-search"
                    class="p-button-sm p-button-success"
                  ></button>
                  <button
                    pButton
                    pRipple
                    label="Clear"
                    icon="pi pi-times"
                    class="p-button-danger"
                    (click)="Clear()"
                  ></button>
                </td>
              </tr>
            </table>
          </form>
          <div class="card">
            <p-toolbar styleClass="p-mb-4">
              <ng-template pTemplate="left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                  placeholder="Search..." />
                </span>
              </ng-template>
              <ng-template pTemplate="right">
                <button
                  type="button"
                  pButton
                  pRipple
                  icon="pi pi-file-o"
                  title="Export CSV"
                  class="p-mr-2"
                  pTooltip="CSV"
                  tooltipPosition="bottom"
                  (click)="exportExcel()"
                ></button>
                <button
                  type="button"
                  pButton
                  pRipple
                  icon="pi pi-file-excel"
                  title="Export Excel"
                  class="p-button-success p-mr-2"
                  pTooltip="XLS"
                  tooltipPosition="bottom"
                  (click)="exportExcel()"
                ></button>
                <button
                  type="button"
                  pButton
                  pRipple
                  icon="pi pi-file-pdf"
                  title="Export PDF"
                  class="p-button-warning p-mr-2"
                  pTooltip="PDF"
                  tooltipPosition="bottom"
                  (click)="ExportToPdf()"
                ></button>
              </ng-template>
            </p-toolbar>
            <p-table
                #dt [value]="_ReportLists" [scrollable]="true" scrollHeight="450px" [rows]="10"
                [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]"
                [globalFilterFields]="['consignmentdeliverynotedate','consignmentdeliverynoteno','customername','customerbranchname','producttypename','productcategoryname','productname','brandname','uom','stock']"
                [rowHover]="true" dataKey="consignmentdeliverynoteno"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [showCurrentPageReport]="true">
              <ng-template pTemplate="header">
                <tr>
                  <th
                    class="text-center" style="width: 50px;">
                    S.No
                  </th>
                  <th pSortableColumn="consignmentdeliverynotedate">
                    Delivery Note Date
                    <p-sortIcon field="consignmentdeliverynotedate"> </p-sortIcon>
                  </th>
                  <th pSortableColumn="consignmentdeliverynoteno">
                    Delivery Note No
                    <p-sortIcon field="consignmentdeliverynoteno"> </p-sortIcon>
                  </th>
                  <th pSortableColumn="customername">
                    Customer Name
                    <p-sortIcon field="customername"> </p-sortIcon>
                  </th>
                  <th pSortableColumn="customerbranchname">
                    Customer Branch Name
                    <p-sortIcon field="customerbranchname"> </p-sortIcon>
                  </th>
                  <th pSortableColumn="producttypename">
                    Product Type
                    <p-sortIcon field="producttypename"> </p-sortIcon>
                  </th>
                  <th pSortableColumn="productcategoryname">
                    Product Category
                    <p-sortIcon field="productcategoryname"> </p-sortIcon>
                  </th>
                  <th pSortableColumn="productname">
                    Product Name
                    <p-sortIcon field="productname"> </p-sortIcon>
                  </th>
                  <th pSortableColumn="brandname">
                    Brand Name
                    <p-sortIcon field="brandname"> </p-sortIcon>
                  </th>
                  <th pSortableColumn="uom">
                    UOM
                    <p-sortIcon field="uom"> </p-sortIcon>
                  </th>
                  <th pSortableColumn="stock">
                    Stock
                    <p-sortIcon field="stock"> </p-sortIcon>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-_ReportList let-i="rowIndex">
                <tr>
                  <td class="text-center" style="width: 50px;">
                    {{ i + 1 }}
                  </td>
                  <td>{{ _ReportList.consignmentdeliverynotedate | date: 'dd/MM/yyyy' }}</td>
                  <td>{{ _ReportList.consignmentdeliverynoteno  }}</td>
                  <td>{{ _ReportList.customername  }}</td>
                  <td>{{ _ReportList.customerbranchname  }}</td>
                  <td>{{ _ReportList.producttypename  }}</td>
                  <td>{{ _ReportList.productcategoryname  }}</td>
                  <td>{{ _ReportList.productname  }}</td>
                  <td>{{ _ReportList.brandname   }}</td>
                  <td>{{ _ReportList.uom   }}</td>
                  <td>{{ _ReportList.stock    }}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p-toast position="bottom-right"></p-toast>
  