<header>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-7">
                <form [formGroup]="_headerCompForm">
                    <div class="header-search">
                        <p-dropdown [autoDisplayFirst]="false" [options]="userBranches" name="userbranch"
                            optionLabel="branchname" optionValue="branchid" formControlName="userBranch" [filter]="true"
                            (onChange)="OnUserBranchChange($event)">
                        </p-dropdown>
                    </div>

                    <div class="header-search">
                        <p-dropdown [autoDisplayFirst]="false" [options]="userRoles" name="userrole"
                            optionLabel="rolename" optionValue="roleid" formControlName="userRole"
                            (onChange)="OnUserRoleChange($event)">
                        </p-dropdown>
                    </div>

                    <!-- <div class="header-search">
                        <p-autoComplete class="quick-search" formControlName="genericsearch"
                            (onSelect)="OnSelect($event)" [suggestions]="filteredsuggestions"
                            (completeMethod)="search($event)" field="name" placeholder="I'm Searching For...">
                            <ng-template let-filter pTemplate="item">
                                <a class="search-name">{{filter.name}}
                                    <span class="search-category"> {{filter.code}}</span>
                                </a>
                            </ng-template>
                        </p-autoComplete>
                        </div> -->

                    <div class="header-search all">
                        <p-autoComplete class="quick-search" [(ngModel)]="_selectedScreens"
                            [suggestions]="_filteredScreens" (completeMethod)="filterScreens($event)"
                            field="Childscreenname" placeholder="I'm Searching For..." (onSelect)="RouteTo($event)">
                            <ng-template let-screen pTemplate="item">
                                <a href="javascript:void(0)" class="search-name">
                                    {{ screen.screenname }}
                                    <span class="search-category">in {{ screen.modulename }}</span>
                                </a>
                            </ng-template>
                        </p-autoComplete>
                    </div>

                    <button pButton pRipple icon="las la-random" (click)="getcurrenctstatus($event)"
                        class="p-button-sm p-button" title="Current Status"></button>
                    <button pButton pRipple icon="las la-toggle-on" (click)="opencloserevmax($event)"
                        class="p-button-sm p-button-success" title="Open/Close Revmax"></button>
                    <button pButton pRipple icon="las la-toggle-on" (click)="OnbuttonChange()" *ngIf="_isfreezenable"
                        class="p-button-sm p-button-danger" title="Full Rights Freeze/Unfreeze"
                        style="margin-left:20px !important;"></button>
                    <p class="p-button-sm d-inline-block" style="margin-left:5px;" *ngIf="_isfreezenable">Status: {{Freezestatus}}</p>


                </form>
            </div>
            <div class="col-md-5 text-right">
                <ul class="navbar-nav">
                    <li>
                        <div class="dropdown">
                            <a href="javascript:void(0);" class="toggle-btn text-center" data-toggle="dropdown"
                                id="userLogout1">
                                <span class="badges">{{ NotificationCount }}</span>
                                <i class="las la-bell"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userLogout1">
                                <ul class="notification">
                                    <li *ngFor="let NV of _Notificationsview">
                                        <a href="javascript:void(0);" (click)="OpenNotifications()">
                                            <h6>{{ NV.username }} sent you Notification</h6>
                                            <p>{{ NV.subject }}</p>
                                            <small>{{
                                                NV.messagedate | date : "dd/MM/yyyy hh:mm a"
                                                }}</small>
                                        </a>
                                    </li>
                                    <!-- <li>
                                        <h6>Report Created Successfully</h6>
                                        <small>31 Oct 2020 - 02:20 PM</small>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="dropdown user-logout">
                            <a href="javascript:void(0);" class="user-profile txt-center" data-toggle="dropdown"
                                id="userLogout">
                                <img [src]="userLogo" alt="" class="img-fluid" />
                                <div class="header-info text-left">
                                    <span>Hi, <strong>{{ userName | titlecase }}</strong></span>
                                    <small>{{ designation }}</small>
                                </div>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userLogout">
                                <ul class="dropdown-user">
                                    <li>
                                        <a href="cChangePassword"><i class="las la-lock"></i>Change Password</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);" (click)="OnClickLogOut()"><i
                                                class="las la-sign-out-alt"></i>Logout</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</header>
<!-- <div class="approval-notification chat-window">
    <div class="chat-hdr">
        Chat
        <a href="javascript:void(0);" class="approval-close">&times;</a>
    </div>
    <div class="chat-body">
        <div class="comment-section text-right">
            <div class="comment02">
                <label>Me</label>
                <div class="text">Are you there?</div>
            </div>
        </div>
        <div class="comment-section">
            <div class="comment01">
                <label>Supervisor Name</label>
                <div class="text">Yes...</div>
            </div>
        </div>
        <div class="comment-section text-right">
            <div class="comment02">
                <label>Me</label>
                <div class="text">Need quick approval from your end...</div>
            </div>
        </div>
        <div class="comment-section">
            <div class="comment01">
                <label>Supervisor Name</label>
                <div class="text">Yes. Please....</div>
            </div>
        </div>
        <div class="comment-section text-right">
            <div class="comment02">
                <label>Me</label>
                <div class="text">Customer wants some number of items which is out of stock. Shall I proceed?</div>
            </div>
        </div>
        <div class="comment-section">
            <div class="comment01">
                <label>Supervisor Name</label>
                <div class="text">Yes... You can... I will look up and forward it to some of branches and let you
                    know....</div>
            </div>
        </div>
        <div class="comment-section text-right">
            <div class="comment02">
                <label>Me</label>
                <div class="text">Ok... Thanks...</div>
            </div>
        </div>
    </div>
    <div class="chat-ftr">
        <div class="chat-input-area">
            <textarea class="form-control" rows="2"></textarea>
            <button class="p-button" title="Send">
                <i class="pi pi-arrow-up"></i>
            </button>
        </div>
    </div>
</div> -->

<p-dialog [(visible)]="ApprovalPopup" class="approval-notification send-approval" [style]="{ width: '300px' }"
    (onHide)="Approvalclose(_ApprovalDocument.logdetailid)" position="bottom-right">
    <ng-template pTemplate="header">
        <div class="approval-hdr">Approval Notification</div>
    </ng-template>
    <div class="approval-body">
        <div class="img-text" [hidden]="true">
            <p>{{ _ApprovalDocument.logdetailid }}</p>
        </div>
        <div class="img-text">
            <p>{{ _ApprovalDocument.workflowname }}</p>
        </div>
        <table>
            <tr>
                <td colspan="2">
                    <label>Document No</label>
                    <div class="text">{{ _ApprovalDocument.documentno }}</div>
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <label>Prepared By</label>
                    <div class="text">{{ _ApprovalDocument.requestby }}</div>
                </td>
            </tr>
            <div class="img-text" [hidden]="true">
                <p>{{ _ApprovalDocument.conditiontype }}</p>
            </div>
            <tr>
                <textarea pInputTextarea [rows]="2">{{
          _ApprovalDocument.remarks
        }}</textarea>
            </tr>
        </table>
    </div>
    <p-footer>
        <div class="approval-ftr">
            <div class="text-center">
                <button class="p-button p-button-success" (click)="ApproveClick(_ApprovalDocument.logdetailid)"
                    title="Approve">
                    <i class="las la-check"></i>
                </button>
                <button class="p-button p-button-warning" (click)="RejectClick(_ApprovalDocument.logdetailid)"
                    title="Reject">
                    <i class="pi pi-ban"></i>
                </button>
                <button class="p-button" (click)="ViewApproval(_ApprovalDocument.logdetailid)" title="View">
                    <i class="pi pi-eye"></i>
                </button>
            </div>
        </div>
    </p-footer>
</p-dialog>
<p-dialog header="Session Expired" [modal]="true" [(visible)]="SessionPopup"
    class="approval-notification session-expiry" [style]="{ width: '300px' }">
    <div class="approval-body">
        <p>
            <strong>{{ idleState }}</strong>
        </p>
        <!-- <div class="text-center">
        <button (click)="LogOut()" class="p-button p-button-success" *ngIf="timedOut">Back To Login</button>
    </div> -->
    </div>
</p-dialog>

<!-- Ecommerce -->
<!-- Order Popup -->
<p-dialog [(visible)]="OrderPopup" class="approval-notification send-approval" [style]="{ width: '300px' }"
    (onHide)="Orderclose(_OrderDocument.orderid)" position="bottom-right">
    <ng-template pTemplate="header">
        <div class="approval-hdr">Order Notification</div>
    </ng-template>
    <div class="approval-body">
        <div class="img-text" [hidden]="true">
            <p>{{ _OrderDocument.orderid }}</p>
        </div>
        <div class="img-text">
            <!-- <p>{{_OrderDocument.workflowname}}</p> -->
        </div>
        <table>
            <tr>
                <td colspan="2">
                    <label>Order ID</label>
                    <div class="text">{{ _OrderDocument.orderid }}</div>
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <label>Prepared By</label>
                    <div class="text">E-Commerce</div>
                </td>
            </tr>
        </table>
    </div>
    <p-footer>
        <div class="approval-ftr">
            <div class="text-center">
                <!-- <button class="p-button p-button-success" (click)="ApproveClick(_OrderDocument.logdetailid)"
                    title="Approve">
                    <i class="las la-check"></i>
                </button>
                <button class="p-button p-button-warning" (click)="RejectClick(_OrderDocument.logdetailid)"
                    title="Reject">
                    <i class="pi pi-ban"></i>
                </button> -->
                <button class="p-button" (click)="ViewOrder(_OrderDocument.orderid)" title="View">
                    <i class="pi pi-eye"></i>
                </button>
            </div>
        </div>
    </p-footer>
</p-dialog>

<p-dialog [(visible)]="Freezebutton.showdialog" #warning [style]="{width: '500px'}" header="Confirm" [modal]="true"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="false">
    <ng-template pTemplate="content">
        <label [innerHTML]="Freezebutton.message"></label>
    </ng-template>
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="las la-check" label="Yes" autofocus (click)="Yes()"></button>
        <button type="button" pButton icon="pi pi-times" label="No" autofocus (click)="Cancel()"></button>
    </ng-template>
</p-dialog>