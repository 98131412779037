<div class="page-container">
  <div class="inner-page-container">
    <div class="page-loader" *ngIf="_IsProgressSpinner">
      <p-progressSpinner strokeWidth="5"></p-progressSpinner>
    </div>
    <div class="white">
      <div class="page-title">
        <div class="row">
          <div class="col-md-5">
            <h3>Consignment Customer Invoice</h3>
          </div>
          <div class="col-md-7 text-right">
            <div class="action-btn">
              <button pButton pRipple title="Save" type="submit" icon="pi pi-save" [hidden]="_IsSaveVisible"
                *ngIf="_action != 'view'" (click)="onSave()"></button>
              <button pButton pRipple title="Clear" icon="pi pi-trash" class="p-button-danger"
                [hidden]="_IsClearVisible" *ngIf="_action != 'view'" (click)="reset($event)"></button>
              <button pButton pRipple icon="pi pi-search" title="Back to Search"
                class="p-button p-button-success p-mr-2" (click)="goBack($event)"></button>
            </div>
          </div>
        </div>
      </div>
      <div class="form-container scroll-y">

        <div class="sub-title">
          <div class="row">
            <div class="col-md-2">
              <h5>Invoice Details</h5>
            </div>
            <div class="col-md-2"></div>

            <div class="col-md-2">
              <h5>Payment Details</h5>
            </div>
            <div class="col-md-4"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <form [formGroup]="_consignmentinvoiceform">
              <table class="normal-table">
                <tr>
                  <td style="width: 20%">
                    <span class="p-float-label">
                      <input type="text" name="salesinvoiceno" disabled formControlName="salesinvoiceno" disabled
                        pInputText />
                      <label for="salesinvoiceno">Consignment Invoice No</label>
                    </span>
                  </td>
                  <td style="width: 20%">
                    <span class="p-float-label">
                      <p-calendar [showIcon]="true" name="salesinvoicedate" dateFormat="dd/mm/yy" [readonlyInput]="true"
                        formControlName="salesinvoicedate" [maxDate]="_maxdate" [monthNavigator]="true"
                        [yearNavigator]="true" yearRange="1980:2030" [disabled]="true">
                      </p-calendar>
                      <label for="salesinvoicedate">Invoice Date </label>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style="width: 20%">
                    <div class="p-inputgroup">
                      <span class="p-float-label">
                        <p-dropdown [autoDisplayFirst]="false" [options]="_Customernames" optionLabel="customername"
                          optionValue="customerid" [filter]="true" filterBy="customername" formControlName="customerid"
                          [virtualScroll]="true" itemSize="15" (onChange)="GetCustomer($event)">
                        </p-dropdown>
                        <label for="customerid">
                          Customer Name <span class="hlt-txt">*</span></label>
                      </span>
                    </div>
                    <span class="text-danger" *ngIf="
                        (_submitted ||
                          _consignmentinvoiceform.get('customerid').touched) &&
                        _consignmentinvoiceform.get('customerid').errors
                          ?.SelectCustomerName
                      ">
                      Please Select Customer Name
                    </span>
                  </td>
                  <td>
                    <span class="p-float-label">
                      <input type="number" name="_customercreditlimit" [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="_customercreditlimit" disabled pInputText />
                      <label for="_customercreditlimit">Customer Credit Limit</label>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="p-float-label">
                      <p-dropdown [autoDisplayFirst]="false" [options]="_Currencies" optionLabel="currencyname"
                        optionValue="currencyid" formControlName="currencyid"
                        (onChange)="OnUserPreferredCurrencyChange()" [disabled]="_ispageonload">
                      </p-dropdown>
                      <label for="currencyid">Currency</label>
                    </span>
                  </td>
                  <td>
                    <span class="p-float-label">
                      <p-dropdown [autoDisplayFirst]="false" [options]="_Salesreferenceno" optionLabel="referenceno"
                        optionValue="referenceno" formControlName="salesreferenceno" [showClear]="true"
                        filterBy="referenceno" [filter]="true" (onChange)="GetReferenceDetails()"
                        [disabled]="_ispageonload" [hidden]="_action == 'view'">
                      </p-dropdown>
                      <input type="text" name="salesreferenceno" [hidden]="_action != 'view'"
                        formControlName="salesreferenceno" disabled pInputText />
                      <label for="salesRef"> Delivery Note No </label>
                    </span>
                  </td>


                </tr>
                <tr>
                  <td>
                    <span class="p-float-label">
                      <p-dropdown [autoDisplayFirst]="false" [options]="_Markuplevels" optionLabel="levelname"
                        optionValue="levelid" formControlName="markuplevelid" (onChange)="GetLevel($event)"
                        [disabled]="isDisabledlevel">
                      </p-dropdown>
                      <label for="markuplevelid">Markup Level</label>
                    </span>
                  </td>
                  <td style="width: 20%">
                    <span class="p-float-label">
                      <input pInputText name="customerbranch" [(ngModel)]="_customerbranch"
                        [ngModelOptions]="{standalone: true}" disabled />
                      <label for="customerbranch">Customer Branch</label>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style="width: 20%">
                    <span class="p-float-label">
                      <input pInputText name="mobileno" [(ngModel)]="_mobileno" [ngModelOptions]="{standalone: true}"
                        disabled />
                      <label for="mobileno">Mobile No</label>
                    </span>
                  </td>
                  <td style="width: 20%">
                    <span class="p-float-label">
                      <input pInputText name="mailid" [(ngModel)]="_mailid" [ngModelOptions]="{standalone: true}"
                        disabled />
                      <label for="mailid">Mail Id</label>
                    </span>
                  </td>

                </tr>
              </table>
            </form>
          </div>
          <div class="col-md-8">
            <form [formGroup]="_consignmentinvoiceform">
              <table class="normal-table">
                <tr>
                  <td>
                    <span class="p-float-label">
                      <input type="number" name="productamount" formControlName="productamount" disabled pInputText />
                      <label for="productamount">Gross Amount</label>
                    </span>
                  </td>
                  <td>
                    <span class="p-float-label">
                      <input type="number" name="taxamount" formControlName="taxamount" disabled pInputText />
                      <label for="taxamount">Tax Amount</label>
                    </span>
                  </td>

                </tr>
                <tr>
                  <td>
                    <span class="p-float-label">
                      <p-dropdown #id [autoDisplayFirst]="false" [options]="_Descriptions" optionLabel="otherchargename"
                        optionValue="otherchargeid" filter="true" filterBy="otherchargename" [showClear]="true"
                        [disabled]="IsDisabledLayBuy ||_action != 'create'" [(ngModel)]="otherchargeid"
                        [ngModelOptions]="{standalone: true}" [disabled]="_ispageonload"
                        (onChange)="OnchangeOtherCharge($event)">
                      </p-dropdown>
                      <label for="otherchargeid">Other Charges</label>
                    </span>
                  </td>
                  <td>
                    <span class="p-float-label">
                      <input pInputText name="otherchargesamount" type="number" pKeyFilter="num"
                        formControlName="otherchargesamount" maxlength="10" pattern="^\d{1,10}(\.\d{0,2})?$" min="0"
                        (keypress)="keypress.kpDecimalInput($event)" (keyup)="oneditothercharge()"
                        [disabled]="_ispageonload">
                      <label for="otherchargesamount">Other Charges Amount</label>
                    </span>
                  </td>
                  <td>
                    <span class="p-float-label">
                      <p-dropdown [autoDisplayFirst]="false" [options]="_Salesby" optionLabel="username"
                        optionValue="userid" filter="true" filterBy="username" [showClear]="true"
                        formControlName="salesby" [disabled]="_ispageonload">
                      </p-dropdown>
                      <label for="productid">Sales By <span class="hlt-txt">*</span></label>
                    </span>
                    <span class="text-danger" *ngIf="
                          (_submitted ||
                            _consignmentinvoiceform.get('salesby').touched) &&
                          _consignmentinvoiceform.get('salesby').errors
                            ?.SelectProductType
                        ">
                      Please Select Sales by
                    </span>
                  </td>

                </tr>
                <tr>
                  <td>
                    <span class="p-float-label">
                      <input type="number" name="netamountincustomercurrency"
                        formControlName="netamountincustomercurrency" disabled
                        style="height: 40px; font-size: 15px; font-weight: bold; text-align: right; background-color: rgb(236, 236, 132); color: black;"
                        pInputText>
                      <label for="netamountincustomercurrency">Net Amount in Customer Currency</label>
                    </span>
                  <td>
                    <span class="p-float-label">
                      <input type="number" name="amountinusd" formControlName="amountinusd" disabled pInputText
                        style="height: 40px; font-size: 15px; font-weight: bold; text-align: right; background-color: rgb(236, 236, 132); color: black;" />
                      <label for="amountinusd">Net Amount in USD</label>
                    </span>
                  </td>
                  <td>
                    <span class="p-float-label">
                      <input type="number" name="customerbalanceamount" disabled formControlName="customerbalanceamount"
                        pInputText />
                      <label for="customerbalanceamount">Customer Balance Amount
                      </label>
                    </span>
                  </td>
                </tr>
              </table>
            </form>

          </div>

        </div>

        <div class="sub-title">
          <div class="row">
            <div class="col-md-5">
              <h5>Product Details</h5>
            </div>
            <div class="col-md-7"></div>
          </div>
        </div>
        <div class="card">
          <p-toolbar styleClass="p-mb-4">
            <ng-template pTemplate="left">
              <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                  placeholder="Search..." />
              </span>
            </ng-template>
          </p-toolbar>
          <p-table #dt [value]="_ProductDetails" [rows]="10" [paginator]="true"
            [rowsPerPageOptions]="[5, 10, 25, 50, 100]"
            [globalFilterFields]="['productname','uomname','totalquantity','balancequantity','quantity','unitprice','customerdiscountpercentage','taxpercentage','taxamount','finalamount']"
            [rowHover]="true" dataKey="billno"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [showCurrentPageReport]="true" [(selection)]="selectedProductDetailsRows">
            <ng-template pTemplate="header">
              <tr>
                <th style="text-align: center; width: 60px" class="text-center">
                  Action
                </th>
                <th style="width: 250px">Product Name</th>
                <th>UOM</th>
                <th>Total Invoice Qty</th>
                <th>Balance Invoice Qty</th>
                <th>Current Stock</th>
                <th>Invoice Qty</th>
                <!-- <th>Remaining Stock</th> -->
                <th>Unit Price</th>
                <th>Price</th>
                <!-- <th>Default Discount %</th> -->
                <th>Discount %</th>
                <!-- <th>Approved Discount %</th> -->
                <th>Tax %</th>
                <!-- <th>Default Discount Amount</th> -->
                <th> Dis Amount</th>
                <!-- <th>Approved Discount Amount</th> -->
                <th>Tax Amount</th>
                <th>Final Amount</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-_ProductDetail>
              <tr>
                <td style="text-align: center">
                  <button pButton pRipple title="Remove" icon="pi pi-trash" class="p-button-danger p-mr-2"
                    [disabled]="IsDisabledLayBuy || _action == 'view'" (click)="RemoveProduct(_ProductDetail)"></button>
                </td>
                <td style="width: 250px">
                  {{ _ProductDetail.productname }}
                </td>
                <td>
                  {{ _ProductDetail.uomname }}
                </td>
                <td>
                  {{ _ProductDetail.totalquantity }}
                </td>
                <td>
                  {{ _ProductDetail.balancequantity }}
                </td>
                <td>
                  {{ _ProductDetail.stocktemp }}
                </td>
                <td pEditableColumn>
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input type="text" [(ngModel)]="_ProductDetail.quantity"
                        (keydown.enter)="allocatequantity(_ProductDetail)"
                        (keyup.tab)="allocatequantity(_ProductDetail)" (focusout)="allocatequantity(_ProductDetail)"
                        (keydown)="PreventMinus($event)" pInputText *ngIf="_action != 'view'" />
                      <div *ngIf="_action == 'view'">
                        {{ _ProductDetail.quantity }}
                      </div>
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{ _ProductDetail.quantity }}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td>
                  {{ _ProductDetail.unitprice | number : "1.2" }}
                </td>

                <td>
                  {{ _ProductDetail.productamount | number : "1.2" }}
                </td>

                <td pEditableColumn>
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input type="text" [(ngModel)]="
                        _ProductDetail.customerdiscountpercentage
                      " [disabled]="
                        IsDisableRequestDiscount ||
                        IsDisabledLayBuy ||
                        _ProductDetail.disableFreeProductFeild ||
                        _action != 'create'
                      " (keyup)="allocatequantity(_ProductDetail)" pInputText *ngIf="_action != 'view'" />
                      <div *ngIf="_action == 'view'">
                        {{ _ProductDetail.customerdiscountpercentage }}
                      </div>
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{ _ProductDetail.customerdiscountpercentage }}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <!-- <td>
                                      {{_ProductDetail.approveddiscountpercentage}}
                                  </td> -->
                <td>
                  {{ _ProductDetail.taxpercentage }}
                </td>
                <!-- <td>
                                      {{_ProductDetail.discountamount | number: '1.2' }}
                                  </td> -->
                <td>
                  {{
                  _ProductDetail.customerdiscountamount | number : "1.2"
                  }}
                </td>
                <!-- <td>
                                      {{_ProductDetail.approveddiscountamount | number: '1.3'}}
                                  </td> -->

                <td>
                  {{ _ProductDetail.taxamount | number : "1.2" }}
                </td>
                <td>
                  {{ _ProductDetail.finalamount | number : "1.2" }}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>

        <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>

      </div>
    </div>
  </div>
</div>
<p-toast position="bottom-right"></p-toast>