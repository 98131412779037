import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as moment from 'moment';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Brand } from 'src/app/core/Models/Brand';
import { Currency } from 'src/app/core/Models/Currency';
import { Level } from 'src/app/core/Models/Level';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Producttype } from 'src/app/core/Models/Producttype';
import { User } from 'src/app/core/Models/User';
import { RptsalesService } from 'src/app/core/Services/reports/Sales/Rptsales/rptsales.service';
import { Customerview } from 'src/app/core/Views/Customerview';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { ExportUtility } from 'src/assets/js/export-utility';
import { RptlaybuyService } from 'src/app/core/Services/reports/Sales/RptLaybuy/rptlaybuy.service';

@Component({
  selector: 'app-rptsales',
  templateUrl: './rptsales.component.html',
  styleUrls: ['./rptsales.component.css']
})
export class RptsalesComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _rptsalesform: FormGroup;
  _branchlist = sessionStorage["BranchID"];
  _userid: number;
  datepipe = new DatePipe('en-US');
  _customerlist: Customerview[];
  _src: SafeResourceUrl;
  filteredProducts: any[];
  _currencylist: Currency[] = [];
  _Salesby: User[] = [];
  userid: number;
  _producttypelist: any[] = [];
  _productcategorylist: Productcategory[];
  _productlist: Productoverallview[] = [];
  _SalesReportPrintObj: any;
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  productcatid: number[] = [];
  producttypeid: number[] = [];
  productid: number[] = [];
  currencyid: number[] = [];
  branchid: number[] = [];
  customerid: number[] = [];
  _fromMaxDate = new Date();
  _brandlist: any;
  brandid: number[] = [];
  _ProductObj: any;
  _Tempbrandlist: Brand;
  _Levellist: Level[] = [];
  levelid: number[] = [];
  branchselectionlimit: number = 1;
  _Action: IAction;
  _ReportLists: any[] = [];
  _exceldisable: boolean = true;
  _customertypelist: any[] = [];
  _supplierlist: any
  supplierids: any[] = []
  _Tempproducttypelist: any[] = [];
  mindate: Date;
  maxdate: Date;
  customertypes: any[] = [];
  _categorylist: any[];
  _subcategorylist: any[];
  _Rightslist: any[];
  _Packtypelist: any[];
  _Series= sessionStorage["Series"];
  constructor(
    private utility: CommonUtilities,
    private _AccessRightsService: AccessRightsService,
    private fb: FormBuilder,
    private _RptsalesService: RptsalesService,
    private _RptlaybuyService: RptlaybuyService,
    private _CustomExceptionService: CustomExceptionService,
    private sanitizer: DomSanitizer,
    private exportUtility: ExportUtility
  ) { }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(116);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._rptsalesform = this.fb.group({
      fromdate: [''],
      todate: [''],
      branchid: [0],
      productid: [0],
      currencyid: [],
      productcategoryid: [0],
      producttypeid: [0],
      customerid: [],
      userid: [],
      isdamaged: [false],
      brandid: [0],
      levelid: [0],
      customertype: [],
      categoryid: [],
      subcategoryid: [],
      rights: [],
      isconsignmentinvoice: [],
      packtype: []
    })
    this._exceldisable = true;
    this._branchlist = parseInt(sessionStorage["BranchID"]);
    this._userid = parseInt(sessionStorage["userid"]);
    this.branchid = [];
    this.branchid.push(parseInt(sessionStorage["currentbranchid"]));
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptsalesform.get("fromdate").setValue(new Date);
      this._rptsalesform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptsalesform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptsalesform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    this._rptsalesform.controls['currencyid'].setValue(1);
    this.OnChangeDate();
    this.RightsChange();
    this._IsProgressSpinner = true;
    this._RptsalesService.PageOnload(this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);

      this._branchlist = resultJSON.branchList;
      this._producttypelist = resultJSON.producttypeList;
      this._Tempproducttypelist = resultJSON.producttypeList;
      this._currencylist = resultJSON.currencyList;
      this._Salesby = resultJSON.users;
      this._customerlist = resultJSON.customerList;
      this._brandlist = resultJSON.brandList;
      this._Tempbrandlist = resultJSON.brandList;
      this._Levellist = resultJSON.levels;
      this._customertypelist = resultJSON.metadatum;
      this._supplierlist = resultJSON.supplierList;
      this._Rightslist = resultJSON.rightslist;
      this._Packtypelist = resultJSON.packtypelist;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  // onchangecustomertype(event) {
  //   var customertype = event.value || 0;
  //   this._userid = parseInt(sessionStorage["userid"]);

  //   let data =
  //   {
  //     "userid": this._userid,
  //     "customertype": customertype
  //   }

  //   this._IsProgressSpinner = true;

  //   this._RptsalesService.onchangecustomertype(data).subscribe((result) => {
  //     const resultJSON = JSON.parse(JSON.stringify(result));
  //     this._customerlist = resultJSON.customers;
  //     this._IsProgressSpinner = false;
  //   }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  // }
  clear(event) {
    this._rptsalesform.reset();
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptsalesform.get("fromdate").setValue(new Date);
      this._rptsalesform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptsalesform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptsalesform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    this._rptsalesform.controls['currencyid'].setValue(1);
    this.productcatid = [];
    this.productid = [];
    this.branchid = [];
    this.branchid.push(parseInt(sessionStorage["currentbranchid"]));
    this.customerid = [];
    this.currencyid = [];
    this.producttypeid = [];
    this._productcategorylist = [] = [];
    this._productlist = [] = [];
    this.brandid = [];
    this.levelid = [];
    this._brandlist = this._Tempbrandlist;
    this._exceldisable = true;
    this._categorylist = [];
    this._subcategorylist = [];
    this._customerlist = [];
    this.customerid = [];
    this.RightsChange();
    
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
  }
  OnChangeDate() {
    var fromdate = moment(this._rptsalesform.get("fromdate").value, "YYYY-MM-DD"); //Previous used query
    var todate = moment(this._rptsalesform.get("todate").value, "YYYY-MM-DD");   //Previous used query
    var caldays = todate.diff(fromdate, 'days');
    const startOfMonth = moment().startOf('month').format("YYYY-MM-DD");
    const endOfMonth = moment().endOf('month').format("YYYY-MM-DD");
    // if (caldays > 0) {
    //   this.branchselectionlimit = caldays;
    //   this.branchid = [];
    //   this.branchid.push(parseInt(sessionStorage["currentbranchid"]));
    //   if (this._userid == 1 && caldays <= 31) {
    //     this.branchselectionlimit = 0;
    //     this.branchid = [];
    //     this.branchid.push(parseInt(sessionStorage["currentbranchid"]));
    //   }
    // } else {
    //   this.branchselectionlimit = 0;
    //   this.branchid = [];
    //   this.branchid.push(parseInt(sessionStorage["currentbranchid"]));
    // }
  }
  GenerateReport(event) {
    var _fromdate = this._rptsalesform.get("fromdate").value;
    var _todate = this._rptsalesform.get("todate").value;
    var categoryid = this._rptsalesform.get("categoryid").value || 0;
    var subcategoryid = this._rptsalesform.get("subcategoryid").value || 0;
    var Rights = this._rptsalesform.get("rights").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    var customertype = this._rptsalesform.get("customertype").value;
    var salesby = this._rptsalesform.get("userid").value == null ? 0 : this._rptsalesform.get("userid").value;
    var levelid = this._rptsalesform.get("levelid").value || 0;
    var consigninvoice = this._rptsalesform.get("isconsignmentinvoice").value || false;
    let packtype = this._rptsalesform.get("packtype").value || null;
    if (fromdate == null) {
      this._CustomExceptionService.handleError(usererrors.Rptfromdate);
      return;
    }
    if (todate == null) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    if (fromdate > todate) {
      this._CustomExceptionService.handleError(usererrors.Rptfromtodate);
      return;
    }
    if (!this.branchid || !this.branchid.length) {
      this._CustomExceptionService.handleError("Please Select Atleast One Branch");
      return;
    }
    var branchid = this.branchid;
    var customerid = this.customerid;
    var isdamaged = this._rptsalesform.get("isdamaged").value == null ? false : this._rptsalesform.get("isdamaged").value;
    var mediaType = 'application/pdf';
    this._exceldisable = false;
    this._IsProgressSpinner = true;
    this._SalesReportPrintObj = {
      "Productcategory": this.productcatid || [],
      "Producttype": this.producttypeid || [],
      "Productheader": this.productid || [],
      "Branchid": branchid || [],
      "Branch": parseInt(sessionStorage["currentbranchid"]),
      "Currency": this.currencyid || [],
      "Customerid": customerid || [],
      "IsDamaged": isdamaged,
      "Userid": parseInt(sessionStorage["userid"]),
      "FromDate": fromdate,
      "ToDate": todate,
      "Salesby": salesby,
      "brandid": this.brandid || [],
      "Levelid": this.levelid || [],
      "supplierid": this.supplierids || [],
      "customertype": customertype,
      "Categoryid": categoryid,
      "Subcategoryid": subcategoryid,
      "Rights": Rights,
      "Isconsignmentinvoice": consigninvoice,
      "Packtype": packtype,
    }
    this._RptsalesService.Print(this._SalesReportPrintObj).subscribe((result) => {
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  GenerateReportexcel() {
    var _fromdate = this._rptsalesform.get("fromdate").value;
    var _todate = this._rptsalesform.get("todate").value;
    var customertype = this._rptsalesform.get("customertype").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    var salesby = this._rptsalesform.get("userid").value == null ? 0 : this._rptsalesform.get("userid").value;
    var customertype = this._rptsalesform.get("customertype").value;
    var salesby = this._rptsalesform.get("userid").value == null ? 0 : this._rptsalesform.get("userid").value;
    var levelid = this._rptsalesform.get("levelid").value || 0;
    var consigninvoice = this._rptsalesform.get("isconsignmentinvoice").value || false;
    var categoryid = this._rptsalesform.get("categoryid").value || 0;
    var subcategoryid = this._rptsalesform.get("subcategoryid").value || 0;
    var Rights = this._rptsalesform.get("rights").value;
    let packtype = this._rptsalesform.get("packtype").value || null;
    if (fromdate == null) {
      this._CustomExceptionService.handleError(usererrors.Rptfromdate);
      return;
    }
    if (todate == null) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    if (fromdate > todate) {
      this._CustomExceptionService.handleError(usererrors.Rptfromtodate);
      return;
    }
    if (!this.branchid || !this.branchid.length) {
      this._CustomExceptionService.handleError("Please Select Atleast One Branch");
      return;
    }
    var branchid = this.branchid;
    var customerid = this.customerid;
    var isdamaged = this._rptsalesform.get("isdamaged").value == null ? false : this._rptsalesform.get("isdamaged").value;;
    var mediaType = 'application/pdf';
    this._IsProgressSpinner = true;
    this._SalesReportPrintObj = {
      "Productcategory": this.productcatid || [],
      "Producttype": this.producttypeid || [],
      "Productheader": this.productid || [],
      "Branchid": branchid || [],
      "Branch": parseInt(sessionStorage["currentbranchid"]),
      "Currency": this.currencyid || [],
      "Customerid": customerid || [],
      "IsDamaged": isdamaged,
      "Userid": parseInt(sessionStorage["userid"]),
      "FromDate": fromdate,
      "ToDate": todate,
      "Salesby": salesby,
      "brandid": this.brandid || [],
      "Levelid": this.levelid || [],
      "supplierid": this.supplierids || [],
      "customertype": customertype,
      "Categoryid": categoryid,
      "Subcategoryid": subcategoryid,
      "Rights": Rights,
      "Isconsignmentinvoice": consigninvoice,
      "Packtype": packtype,
    }
    this._RptsalesService.Printexcel(this._SalesReportPrintObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      // var blob = new Blob([result], { type: mediaType });
      // var url = window.URL.createObjectURL(blob);
      // this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._ReportLists = resultJSON.reportLists;
      this._IsProgressSpinner = false;
      this.exportExcel();
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  getProductCategory(event) {
    var selectedProductType = event.value;
    this.productcatid = [];
    if (!this.productcatid.length) {
      this._productlist = [];
    }
    this.productid = [];
    if (selectedProductType.length == 0 || selectedProductType == null) {
      this._productcategorylist = [];
      this._productlist = [];
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
      this._RptsalesService.getProductCategory(this._ProductTypeObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productcategorylist = resultJSON.productcategories;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  getProduct(event) {
    var selectedProductCategory = event.value;
    this.productid = [];
    if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
      this._productlist = [];
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": parseInt(sessionStorage["currentbranchid"]), "suppliers": this.supplierids || [] };
      this._RptsalesService.getProduct(this._ProductCategoryObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productlist = resultJSON.productoverallList;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  ChangeProduct(event) {
    var selectedProduct = event.value;
    if (selectedProduct.length == 0 || selectedProduct == null) {
      this._brandlist = this._Tempbrandlist
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductObj = { "Products": selectedProduct };
      this._RptsalesService.getBrand(this._ProductObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log("brand", resultJSON);
        let productbrand = resultJSON.productbrandlist;
        let uniqueChars = [...new Set(productbrand)];
        uniqueChars = productbrand.filter((test, index, array) =>
          index === array.findIndex((findTest) =>
            findTest.brandid === test.brandid
          ));
        this._brandlist = uniqueChars;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  exportExcel() {
    let SalesReportList = this.GetSalesList();
    this.exportUtility.exportExcel(SalesReportList, 'Sales_Report', 'xlsx');
  }

  GetSalesList() {
    let salesList = [];
    if (this._ReportLists == undefined) {
      this._CustomExceptionService.handleError("No Data available")
    }
    this._ReportLists.forEach(element => {
      let sales: any = {};
      sales["Branch Name"] = element.branchname;
      sales["Customer Name"] = element.customername;
      sales["Invoice No"] = element.salesinvoiceno;
      sales["Invoice Date"] = this.datepipe.transform(element.salesinvoicedate, 'dd/MM/yyyy');
      sales["Product Name"] = element.productname;
      sales["Brand Name"] = element.brandname;
      sales["Pack Type"] = element.packtypename;
      sales["Pack Qty"] = element.quantity;
      sales["Quantity"] = element.stockqty;
      sales["Product Amount"] = element.productamount;
      sales["Currency Name"] = element.currencyname;
      sales["Sales By"] = element.salesbyname;


      salesList.push(sales);
    });
    return salesList;
  }

  onchangesupplier() {
    if (this.supplierids.length == 0) {
      this._producttypelist = this._Tempproducttypelist

    }
    else {

      let data = {
        "Suppliersid": this.supplierids || []
      }
      this._RptsalesService.supplierbasedproducttype(data).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log("brand", resultJSON);
        this._producttypelist = resultJSON.ProducttypeList;


        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }

  }

  onchangecustomertype(event) {
    var customertype = this._rptsalesform.get('customertype').value || null;
    var categoryid = this._rptsalesform.get('categoryid').value || 0;
    var subcategoryid = this._rptsalesform.get('subcategoryid').value || 0;
    var rights = this._rptsalesform.get("rights").value || 0;
    this.customerid = [];

    let data = {
      userid: this._userid,
      rights: rights,
      customertype: customertype,
      Categoryid: categoryid,
      SubCategoryid: subcategoryid,
    };
    this._IsProgressSpinner = true;
    this._RptlaybuyService.GetCustomer(data).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._customerlist = resultJSON.customerList;
        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }

  GetCategory(event) {
    this._categorylist = [];
    this._rptsalesform.controls["categoryid"].reset();
    this._rptsalesform.get('subcategoryid').reset();
    this._subcategorylist = [];
    this._customerlist = [];
    this.customerid = [];
    var customertype = event.value || 0;

    let obj = {
      userid: this._userid,
      customertype: customertype,
    };
    this._IsProgressSpinner = true;
    this._RptlaybuyService.GetCategory(obj).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._categorylist = resultJSON.categoryList;
        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }

  GetSubcategory(event) {
    this._subcategorylist = [];
    this._rptsalesform.get('subcategoryid').reset();
    this._customerlist = [];
    this.customerid = [];
    var customercategory = event.value || 0;

    let obj = {
      userid: this._userid,
      Customercategory: customercategory,
    };
    this._IsProgressSpinner = true;
    this._RptlaybuyService.GetSubcategory(obj).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._subcategorylist = resultJSON.subcategorylist;
        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }
  RightsChange()
  {
    if(this._Series == "MSC00093")
    {
      this._rptsalesform.controls["rights"].setValue("MSC00093");
      this._rptsalesform.controls["rights"].disable();
      this.onchangecustomertype(event);
    }
    else
    {
      this._rptsalesform.controls["rights"].setValue("MSC00339");
      this.onchangecustomertype(event);
    }
  }
}