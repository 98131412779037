import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AnyARecord } from 'dns';
import { interval, Subscription, timer } from 'rxjs';
import { startWith, switchMap, takeWhile } from 'rxjs/operators';
import { CommonUtilities } from '../../../../assets/js/common-utilities';
import { CustomExceptionService } from '../../errorHandlers/custom-exception.service';
import { Workflowpopupview } from '../../Models/Approvalpopup';
import { ApprovalRequest } from '../../Models/Approvalrequest';
import { ApprovalworkflowService } from '../../Services/popup/approvalworkflow.service';
import { DialogModule } from 'primeng/dialog';
import { LoginService } from '../../Services/users/login.service';
import { Userrolemap } from '../../Models/Userrolemap';
import { Userbranchmap } from '../../Models/Userbranchmap';
import { Userrolemapview } from '../../Views/Userrolemapview';
import { Userbranchmapview } from '../../Views/Userbranchmapview';
import { Userrolemappedfunctionsview } from '../../Views/Userrolemappedfunctionsview';
import { FormBuilder, FormGroup, FormsModule } from '@angular/forms'
import { usererrors } from '../../errors/usererrors';
import { HeaderService } from '../../Services/header/header.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { NotificationService } from '../../Services/masters/notification.service';
import { NotificationHandler } from 'src/assets/js/notification-handler';
import { environment } from 'src/environments/environment';
import { UserService } from '../../services/users/user.service';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  _headerCompForm: FormGroup;
  sessionClearTimer: NodeJS.Timeout;
  ApprovalPopup: boolean = false;
  alive: boolean = true;
  mySubscription: Subscription;
  _IsProgressSpinner: boolean = false;
  //Local Variable Declaration:
  _userid = sessionStorage["userid"];
  _branchid = sessionStorage["BranchID"];
  _ApprovalDocument: any = {};
  _ApprovalRequest: ApprovalRequest = new ApprovalRequest();
  userRoles: Userrolemapview[];
  userBranches: Userbranchmapview[];
  text: string;
  _createdby: number;
  _genericcolumn: string;
  filteredsuggestions: string[];
  genericload: any[];
  _Purchaseorderid: number;
  _Purchaseorderno: number;
  _action: string = "view";
  _View: boolean;

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  SessionPopup: boolean = false;
  FreezeCustomers: boolean = false;

  userName: string = sessionStorage["username"];
  designation: string = sessionStorage["currentRoleName"];
  userLogo: string = "assets/images/avatar.png";
  //Notifications
  _Userid: number = sessionStorage["userid"]
  _isviewed: boolean = false
  NotificationCount: number = 0;
  _Notificationsview: any[] = [];
  NotificationCountlist: any[] = [];
  //Ecommerce
  _OrderDocument: any = {};
  OrderPopup: boolean = false;
  _selectedScreens: Userrolemappedfunctionsview;
  _screens: Userrolemappedfunctionsview[] = [];
  _filteredScreens: Userrolemappedfunctionsview[] = [];
  _tempscreens: Userrolemappedfunctionsview[] = [];
  _isfreezenable: boolean = false;
  _Freezecustomers: boolean = false;
  isfullrightsfreeze: boolean;
  Freezebutton: any = {};
  Ans = 0;
  Freezestatus: string;
  @Output() EnableMenus: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router, private _HeaderService: HeaderService, private utility: CommonUtilities, private _router: Router, private _ApprovalworkflowService: ApprovalworkflowService, private _CustomExceptionService: CustomExceptionService,
    private pLoginService: LoginService, private fb: FormBuilder, private idle: Idle, private keepalive: Keepalive, private _NotificationService: NotificationService,
    private notify: NotificationHandler, private _UserService: UserService,
  ) {
    // sets an idle timeout of 30 seconds, for testing purposes.
    // idle.setIdle(900);
    // // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    // idle.setTimeout(5);
    // // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    // idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // idle.onIdleEnd.subscribe(() => {
    //   this.SessionPopup = false;
    //   this.idleState = 'No longer idle.'
    // });
    // idle.onTimeout.subscribe(() => {
    //   this.idleState = 'Your Session has been Expired. Please Login to Proceed Further';
    //   //this.timedOut = true;
    //   this.SessionPopup = false;
    //   localStorage.setItem("Istimeout", "true");
    //   this.LogOut();
    // });
    // idle.onIdleStart.subscribe(() => {
    //   this.idleState = 'You\'ve gone idle!';
    //   this.SessionPopup = false;
    // });
    // idle.onTimeoutWarning.subscribe((countdown) => {
    //   this.SessionPopup = true;
    //   this.idleState = 'You will time out in ' + countdown + ' seconds!'
    // });

    // // sets the ping interval to 15 seconds
    // keepalive.interval(15);

    // keepalive.onPing.subscribe(() => this.lastPing = new Date());

    // this.reset();
  }
  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
    this.SessionPopup = false;
  }
  Initialize() {
    this._headerCompForm = this.fb.group({
      genericsearch: [''],
      userRole: [''],
      userBranch: ['']
    });
  }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.Initialize();
    if(this._userid == 1 || this._userid == 528)
    {
      this._isfreezenable = true;
    }
    this._ApprovalDocument = [];

    this.userBranches = <Userbranchmapview[]>JSON.parse(sessionStorage.getItem("userbranches"));
    this.userRoles = <Userrolemapview[]>JSON.parse(sessionStorage.getItem("userroles"));
    let userBranch = this._headerCompForm.get("userBranch").value;
    let userRole = this._headerCompForm.get("userRole").value;
    this._headerCompForm.get("userBranch").setValue(userBranch || parseInt(sessionStorage["BranchID"]));
    this._headerCompForm.get("userRole").setValue(userRole || parseInt(sessionStorage["currentRole"]));
    //User Screen List
    this._tempscreens = <Userrolemappedfunctionsview[]>JSON.parse(sessionStorage.getItem("Functions"));

    this._screens = this._tempscreens.filter(f => f.roleid == parseInt(sessionStorage["currentRole"]))
    //
    this._HeaderService.PageOnLoad().subscribe((result) => {
      this.genericload = result.genericsearches;
      this.isfullrightsfreeze = result.isfullrightsfreeze;
      if(this.isfullrightsfreeze == true){
        this.Freezestatus = "Freeze";
      }
      else{
        this.Freezestatus = "UnFreeze";
      }

    });
    if (sessionStorage["userlogopath"])
      this.userLogo = sessionStorage["userlogopath"];

    //For Approval Popup:

    this.ApprovalPopupShow();
    this.ReceieveNotification();
    this.OrderConfirmationPopup();
    let connectionURL = environment.signalr_userhub + "?userId=" + this._userid
    this.notify.InitializeConnection(connectionURL)
      .Watch("approvalNotification", (result: any) => {
        if (this.ApprovalPopup == false) {
          const resultJSON = result;
          resultJSON.workflowpopups = resultJSON.workflowpopups || [];
          if (resultJSON.workflowpopups && resultJSON.workflowpopups.length != 0) {
            this._ApprovalDocument = resultJSON.workflowpopups[0];
            this.ApprovalPopup = true;
          } else {
            this.ApprovalPopup = false;
          }
        }
      })
      .Watch("bellNotifications", (result: any) => {
        const resultJSON = result;
        this._Notificationsview = resultJSON.notificationviewlst;
        this.NotificationCountlist = this._Notificationsview.filter(a => a.isviewed != true);
        this._Notificationsview = (this.NotificationCountlist || []).slice(0, 5);
        this.NotificationCount = (this.NotificationCountlist || []).length;
        for (let i = 0; i < this.NotificationCount; i++) {
          if (this.NotificationCountlist[i].subject.length >= 30) {
            this.NotificationCountlist[i].subject = (this.NotificationCountlist[i].subject).slice(0, 35) + '...';
          }
        }
        this._IsProgressSpinner = false;
      })
      .Start().then(() => {
        this.notify.invoke("SetOnlineStatus", () => {
          console.log("user status changed to online");
        });
      });

    this.SessionWatcher();

    // this.mySubscription = interval(10000).subscribe(x => {
    //   try {
    //     let userRole = this._headerCompForm.get("userRole").value;
    //     this.ReceieveNotification();
    //     if (this.ApprovalPopup == false) {
    //       this._ApprovalworkflowService.Popupfetch(this._userid, this._branchid, userRole).subscribe((result) => {
    //         const resultJSON = JSON.parse(JSON.stringify(result));
    //         resultJSON.workflowpopups = resultJSON.workflowpopups || [];
    //         if (resultJSON.workflowpopups && resultJSON.workflowpopups.length != 0) {
    //           this._ApprovalDocument = resultJSON.workflowpopups[0];
    //           this.ApprovalPopup = true;
    //         } else {
    //           this.ApprovalPopup = false;
    //         }
    //       });
    //     }

    //   } catch (exception) {
    //     //console.log(exception);
    //   }
    // });
    //this.mySubscription.unsubscribe();
  }

  ApprovalPopupShow() {
    if (this.ApprovalPopup == false) {
      let userRole = this._headerCompForm.get("userRole").value;
      this._ApprovalworkflowService.Popupfetch(this._userid, this._branchid, userRole).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        resultJSON.workflowpopups = resultJSON.workflowpopups || [];
        if (resultJSON.workflowpopups && resultJSON.workflowpopups.length != 0) {
          this._ApprovalDocument = resultJSON.workflowpopups[0];
          this.ApprovalPopup = true;
        } else {
          this.ApprovalPopup = false;
        }
      });
    }
  }

  //Notifications
  ReceieveNotification() {
    this._NotificationService.FetchAll(this._Userid, this._isviewed).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this._Notificationsview = resultJSON.notificationviewlst
      this.NotificationCountlist = this._Notificationsview.filter(a => a.isviewed != true);
      this.NotificationCount = (this.NotificationCountlist || []).length;
      this._Notificationsview = (this.NotificationCountlist || []).slice(0, 5);
      for (let i = 0; i < this.NotificationCount; i++) {
        if (this.NotificationCountlist[i].subject.length >= 30) {
          this.NotificationCountlist[i].subject = (this.NotificationCountlist[i].subject).slice(0, 35) + '...';
        }
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  OpenNotifications() {
    this.router.navigate(['/vNotification']);
  }
  // Approval Event:
  ApproveClick(event) {
    var documentdetailid = event;
    var approvedstatus = "MSC00047";
    this._ApprovalRequest = new ApprovalRequest();
    this._ApprovalRequest.companyid = "0";
    this._ApprovalRequest.branchid = this._branchid;
    this._ApprovalRequest.workflowid = this._ApprovalDocument.workflowid;
    this._ApprovalRequest.requesterid = this._ApprovalDocument.requesterid;
    this._ApprovalRequest.alternateuser = this._ApprovalDocument.alternateuser;
    this._ApprovalRequest.parentid = this._ApprovalDocument.parentid;
    this._ApprovalRequest.userid = this._userid;
    this._ApprovalRequest.requestfromdate = new Date();
    this._ApprovalRequest.requesttodate = new Date();
    this._ApprovalRequest.documentno = this._ApprovalDocument.documentno;
    this._ApprovalRequest.documentdetailid = this._ApprovalDocument.documentdetailid;
    this._ApprovalRequest.approverid = this._userid;
    this._ApprovalRequest.stageid = this._ApprovalDocument.stageid;
    this._ApprovalRequest.isviewed = true;
    this._ApprovalRequest.logstatus = "MSC00047";
    this._ApprovalRequest.remarks = this._ApprovalDocument.remarks;
    this._ApprovalworkflowService.ApprovalWorkFlowUpdate(this._ApprovalRequest).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this.ApprovalPopup = false;
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
      }

    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  // Reject Event:
  RejectClick(event) {
    var documentdetailid = event;
    this._ApprovalRequest = new ApprovalRequest();
    this._ApprovalRequest.companyid = "0";
    this._ApprovalRequest.branchid = this._branchid;
    this._ApprovalRequest.workflowid = this._ApprovalDocument.workflowid;
    this._ApprovalRequest.requesterid = this._ApprovalDocument.requesterid;
    this._ApprovalRequest.alternateuser = this._ApprovalDocument.alternateuser;
    this._ApprovalRequest.parentid = this._ApprovalDocument.parentid;
    this._ApprovalRequest.userid = this._userid;
    this._ApprovalRequest.requestfromdate = new Date();
    this._ApprovalRequest.requesttodate = new Date();
    this._ApprovalRequest.documentno = this._ApprovalDocument.documentno;
    this._ApprovalRequest.documentdetailid = this._ApprovalDocument.documentdetailid;
    this._ApprovalRequest.approverid = this._userid;
    this._ApprovalRequest.stageid = this._ApprovalDocument.stageid;
    this._ApprovalRequest.isviewed = true;
    this._ApprovalRequest.logstatus = "MSC00048";
    this._ApprovalRequest.remarks = this._ApprovalDocument.remarks;
    this._ApprovalworkflowService.ApprovalWorkFlowUpdate(this._ApprovalRequest).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this.ApprovalPopup = false;
    });
  }

  // Approval Event:
  ViewApproval(event) {
    if (this._ApprovalDocument.workflowid == 13 || this._ApprovalDocument.workflowid == 23) {
      this._router.navigate(['/cProduct'], { state: { productid: this._ApprovalDocument.documentdetailid, action: 'view' } });
    }
    else if (this._ApprovalDocument.workflowid == 4) {
      this._router.navigate(['/cQuotefinalize'], { state: { supplierquotesno: this._ApprovalDocument.documentdetailid, action: 'view' } });
    }
    else if (this._ApprovalDocument.workflowid == 5) {
      this._router.navigate(['/cPurchaseOrder'], { state: { purchaseorderid: this._ApprovalDocument.documentdetailid, purchaseorderno: this._ApprovalDocument.documentno, action: 'view' } });
    }
    else if (this._ApprovalDocument.workflowid == 6) {
      this._router.navigate(['/cPurchaseinvoice'], { state: { purchaseinvoiceid: this._ApprovalDocument.documentdetailid, purchaseinvoiceno: this._ApprovalDocument.documentno, action: 'view' } });
    }
    else if (this._ApprovalDocument.workflowid == 7) {
      this._router.navigate(['/cPurchasereturn'], { state: { purchasereturnid: this._ApprovalDocument.documentno, action: 'view' } });
    }
    else if (this._ApprovalDocument.workflowid == 8) {
      this._router.navigate(['/cStockTransferOrder'], { state: { stocktransferid: this._ApprovalDocument.documentdetailid, action: 'view' } });
    }
    else if (this._ApprovalDocument.workflowid == 9) {
      this._router.navigate(['/cGoodsReceiptNote'], { state: { grnno: this._ApprovalDocument.documentno, action: 'view' } });
    }
    else if (this._ApprovalDocument.workflowid == 30) {
      this._router.navigate(['/cStockAdjustment'], { state: { stocktakeid: this._ApprovalDocument.documentdetailid, action: 'view' } });
    }
    else if (this._ApprovalDocument.workflowid == 27 || this._ApprovalDocument.workflowid == 26) {
      this._router.navigate(['/cSupplier'], { state: { supplierid: this._ApprovalDocument.documentdetailid, action: 'view' } });
    }
    else if (this._ApprovalDocument.workflowid == 20) {
      this._router.navigate(['/cSalesOrder'], { state: { salesorderno: this._ApprovalDocument.documentno, action: 'view' } });
    }
    else if (this._ApprovalDocument.workflowid == 19) {
      this._router.navigate(['/cSalesQuotation'], { state: { salesquotationno: this._ApprovalDocument.documentno, action: 'view' } });
    }
    else if (this._ApprovalDocument.workflowid == 24 || this._ApprovalDocument.workflowid == 25) {
      this._router.navigate(['/cCustomer'], { state: { customerid: this._ApprovalDocument.documentdetailid, action: 'view' } });
    }
    else if (this._ApprovalDocument.workflowid == 21) {
      this._router.navigate(['/cSalesinvoicetax'], { state: { salesinvoiceno: this._ApprovalDocument.documentno, action: 'view' } });
    }
    else if (this._ApprovalDocument.workflowid == 22) {
      this._router.navigate(['/cSalesinvoicepos'], { state: { salesinvoiceno: this._ApprovalDocument.documentno, action: 'view' } });
    }
    else if (this._ApprovalDocument.workflowid == 18) {
      this._router.navigate(['/cSalesreturn'], { state: { salesreturnno: this._ApprovalDocument.documentno, action: 'view' } });
    }
    else if (this._ApprovalDocument.workflowid == 17) {
      this._router.navigate(['/CPettycash'], { state: { pettycashid: this._ApprovalDocument.documentno, action: 'view' } });
    }
    else if (this._ApprovalDocument.workflowid == 12) {
      this._router.navigate(['/CPettycashSettlement'], { state: { pettycashsettlementid: this._ApprovalDocument.documentno, action: 'view' } });
    }
    else if (this._ApprovalDocument.workflowid == 16) {
      this._router.navigate(['/cSalescommission'], { state: { salescommissionid: this._ApprovalDocument.documentno, action: 'view' } });
    }
    else if (this._ApprovalDocument.workflowid == 11) {
      this._router.navigate(['/cdamagedproductpricedetails'], { state: { productid: this._ApprovalDocument.documentno, action: 'view' } });
    }
    else if (this._ApprovalDocument.workflowid == 80) {
      this._router.navigate(['/cReward'], { state: { rewardid: this._ApprovalDocument.documentno, action: 'view' } });
    }
    else if (this._ApprovalDocument.workflowid == 83) {
      this._router.navigate(['/cCustomerslabwisediscount'], { state: { customerid: this._ApprovalDocument.documentno, action: 'view' } });
    }
    else if (this._ApprovalDocument.workflowid == 77) {
      this._router.navigate(['/cCustomerwisediscount'], { state: { customerid: this._ApprovalDocument.documentno, action: 'view' } });
    }
    else if (this._ApprovalDocument.workflowid == 79) {
      this._router.navigate(['/cScheme'], { state: { schemeid: this._ApprovalDocument.documentno, action: 'view' } });
    }
    else if (this._ApprovalDocument.workflowid == 57) {
      this._router.navigate(['/cProductpricechange'], { state: { productratechangeid: this._ApprovalDocument.documentno, action: 'view' } });
    }

    else if (this._ApprovalDocument.workflowid == 58) {
      this._router.navigate(['/cTellerCashDaySettlement'], { state: { userid: this._ApprovalDocument.userid, tellerid: this._ApprovalDocument.documentno, tellercashid: this._ApprovalDocument.documentdetailid, salesdate: this._ApprovalDocument.requestfromdate, branchid: this._ApprovalDocument.branchid, action: 'view' } });
    }


  }

  Approvalclose(event) {
    var documentdetailid = event;
    var approvedstatus = null;
    this._ApprovalworkflowService.Popupupdate(this._userid, documentdetailid, approvedstatus).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this.ApprovalPopup = false;
    });
  }

  OnClickLogOut() {
    this._IsProgressSpinner = true;
    localStorage.setItem("userid", '0');
  }

  LogOut() {
    this._IsProgressSpinner = true;
    this.pLoginService.logout().subscribe((result) => {
      sessionStorage.clear();
      this.EnableMenus.emit(false);
      this.utility.ClearTimer();
      localStorage.setItem("userid", '0');
      this.router.navigate(['/'], { state: { IsLogOut: true } });
      this._IsProgressSpinner = false;
      //clearInterval(this.sessionClearTimer);
      this.notify.invoke("SetOfflineStatus", (res) => {
        console.log("user status set to offline.")
      }).then(() => {
        this.notify.Stop();
      })
    });
  }

  search(event) {
    this._genericcolumn = event.query;
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.genericload.length; i++) {
      let filter = this.genericload[i];
      if (filter.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(filter);
      }
    }
    this.filteredsuggestions = filtered;
  }

  OnSelect(event) { // code changed by asraf.
    this._createdby = sessionStorage["userid"];
    this._IsProgressSpinner = false;
    this._HeaderService.Genericsearch(event.name, this._createdby, sessionStorage["currentRole"]).subscribe((result) => {
      if (result.userrolemappedfunctionsviews != undefined) {
        this._View = result.userrolemappedfunctionsviews[0].view;
        this.router.onSameUrlNavigation = 'reload';
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
      }
      else {
        this._View = false;
      }
      this._action = history.state.action;
      let state = {};
      if (event.code == "In Product") {
        state = { productid: event.id, action: 'view' };
      }
      else if (event.code == "In Purchase order") {
        state = { purchaseorderno: event.name, purchaseorderid: event.id, action: 'view' };
      }
      else if (event.code == "In Purchase Invoice") {
        event.purchaseinvoiceid = event.id;
        event.purchaseinvoiceno = event.name;
        state = { purchaseinvoiceid: event.purchaseinvoiceid, purchaseinvoiceno: event.purchaseinvoiceno, action: 'view' }
      }
      else if (event.code == "In Purchase Return") {
        event.purchasereturnid = event.id;
        event.purchasereturnno = event.name;
        state = { purchasereturnid: event.purchasereturnid, purchasereturnno: event.purchasereturnno, action: 'view' };
      }
      else if (event.code == "In SalesOrder") {
        state = { salesorderid: event.id, action: 'view' };
      }
      else if (event.code == "In SalesInvoice Tax") {
        state = { salesinvoiceid: event.id, series: event.series, action: 'view' };
      }
      else if (event.code == "In SalesInvoice POS") {
        state = { salesinvoiceid: event.id, series: event.series, action: 'view' };
      }
      else if (event.code == "In Sales Return") {
        state = { salesreturnid: event.id, action: 'view' };
      }
      else if (event.code == "In GRN") {
        state = { grnid: event.id, inwardtype: event.inwardtype, action: 'view' };
      }
      if (this._View) {
        this.router.navigate([event.url], { state: state });
      }
      else {
        this._CustomExceptionService.handleWarning(usererrors.AccessrightsView);
      }
    });
  }
  SessionWatcher() {
    if (!this.sessionClearTimer) {
      this.sessionClearTimer = setInterval(() => {
        let userSessionEnded = !parseInt(localStorage.getItem("userid") || '0');
        if (userSessionEnded) {
          this.LogOut();
          clearInterval(this.sessionClearTimer);
        }
      }, 1000);
    }
  }

  OnUserBranchChange(event: any) {
    const path = window.location.pathname;
    sessionStorage["BranchID"] = event.value;
    sessionStorage["currentbranchid"] = event.value;
    let userBranches = (<Userbranchmapview[]>JSON.parse(sessionStorage["userbranches"]));
    sessionStorage["currentbranchname"] = userBranches.filter(f => f.branchid == event.value)[0].branchname;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([path])
    });
    sessionStorage["isReload"] = true;
  }

  OnUserRoleChange(event) {
    const path = window.location.pathname;
    this.EnableMenus.emit(false);
    if (!this.IsRoleHasScreenAccess(event))
      return;
    sessionStorage["currentRole"] = event.value;
    sessionStorage["currentRoleName"] = (<Userrolemapview[]>JSON.parse(sessionStorage["userroles"])).find(f => f.roleid == event.value).rolename;
    this.designation = sessionStorage["currentRoleName"];

    var screenAccessRights: Userrolemappedfunctionsview[] = JSON.parse(sessionStorage.getItem("Functions"));
    let _AccessRights = screenAccessRights.find(funct => funct.path == path && funct.roleid == event.value);

    if (_AccessRights) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([path])
      });
    }
    sessionStorage["isReload"] = true;
    this.SelectDefaultScreen();
  }

  IsRoleHasScreenAccess(event) {
    var screenlist = JSON.parse(sessionStorage["Functions"]);
    var selectedRole = event.value;
    var vScreenList: Userrolemappedfunctionsview[] = screenlist;
    vScreenList = vScreenList.filter(f => f.roleid == selectedRole);
    if (!vScreenList || !vScreenList.length) {
      this._CustomExceptionService.handleError("No screen assigned to this role.")
      sessionStorage["currentRole"] = sessionStorage["defaultRole"];
      this._headerCompForm.get("userRole").setValue(parseInt(sessionStorage["currentRole"]));
      return false;
    }
    return true;
  }

  // Role based screen list:
  SelectDefaultScreen() {
    this._IsProgressSpinner = true;
    var selectedRole = sessionStorage["currentRole"];
    let screens = (<Userrolemappedfunctionsview[]>JSON.parse(sessionStorage["Functions"]) || []).filter(f => f.roleid == selectedRole);
    let path = '/';
    if (screens && screens.length) {
      screens = screens.sort((a, b) => (
        // your sort logic
        a.moduleid - b.moduleid // example : order by id
      ));
      path = screens[0].path;
    }
    this.router.navigate([path]);
    this.EnableMenus.emit(true);
    this._IsProgressSpinner = false;
  }

  //Ecommerce Order Popup
  OrderConfirmationPopup() {
    try {
      let userRole = this._headerCompForm.get("userRole").value;
      var id = 0;
      if (this.OrderPopup == false) {
        this._ApprovalworkflowService.OrderPopupfetch(this._userid, this._branchid, userRole, id).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          resultJSON.ordernotificationpopuplst = resultJSON.ordernotificationpopuplst || [];
          if (resultJSON.ordernotificationpopuplst && resultJSON.ordernotificationpopuplst.length != 0) {
            this._OrderDocument = resultJSON.ordernotificationpopuplst[0];
            this.OrderPopup = true;
          } else {
            this.OrderPopup = false;
          }
        });
      }

    } catch (exception) {
      //console.log(exception);
    }
  }
  ViewOrder(event) {
    this._router.navigate(['/cOrderStatus'], { state: { orderid: this._OrderDocument.orderid, action: 'view' } }).then(function () {
      window.location.reload();
    });
    this.Orderclose(event);
  }

  Orderclose(event) {
    var documentdetailid = event;
    var approvedstatus = null;
    let userRole = this._headerCompForm.get("userRole").value;
    this._ApprovalworkflowService.OrderPopupfetch(this._userid, this._branchid, userRole, this._OrderDocument.orderheaderid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this.OrderPopup = false;
    });
  }

  RouteTo(screen: Userrolemappedfunctionsview) {
    if (!screen || !screen.path)
      return;
    this.router.navigate([screen.path]);
  }

  filterScreens(event) {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: Userrolemappedfunctionsview[] = [];
    this._screens = [];
    this._screens = this._tempscreens.filter(f => f.roleid == parseInt(sessionStorage["currentRole"]))
    let query = event.query;
    for (let i = 0; i < this._screens.length; i++) {
      let screen = this._screens[i];
      if (screen.screenname.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(screen);
      }
    }

    this._filteredScreens = filtered;
  }
  getcurrenctstatus(event) {
    var userid = this._userid;

    this._IsProgressSpinner = true;
    this._UserService.GetZReportStatus(userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      var revmaxconnected = resultJSON.isRevmaxDeviceConnected;

      var Url = window.open("currentstatus-zreport:$" + parseFloat(this._userid));
      setTimeout(() => {
        Url.close();
      }, 10000);

      // if (resultJSON.isRevmaxDeviceConnected == "YES") {
      //   //this._CustomExceptionService.handleWarning("Zreports Current Status is '" + resultJSON.zreportstatus + "' ");
      //   this._CustomExceptionService.handleWarning("Connected");
      // }

      // else if (resultJSON.isRevmaxDeviceConnected == "NO") {
      //   this._CustomExceptionService.handleWarning("Revmax Device Is Not Connected");
      // }

    })
  }

  opencloserevmax(event) {
    var userid = this._userid;
    this._IsProgressSpinner = true;
    this._UserService.GetZReport(userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      var revmaxconnected = resultJSON.isRevmaxDeviceConnected;

      var Url = window.open("checkin-checkout:$" + parseFloat(this._userid));
      setTimeout(() => {
        Url.close();
      }, 10000);

      // if (resultJSON.isRevmaxDeviceConnected == "YES") {
      //   //this._CustomExceptionService.handleWarning("Zreports are '" + resultJSON.zreportvalue + "' Successfully");
      //   this._CustomExceptionService.handleWarning("Connected");
      // }

      // else if (resultJSON.isRevmaxDeviceConnected == "NO") {
      //   this._CustomExceptionService.handleWarning("Revmax Device Is Not Connected");
      // }
      // this._IsProgressSpinner = false;

    })
  }

  OnbuttonChange() {
    this.FreezeCustomers = true;
    let Freezestatus;
    if (this.isfullrightsfreeze == false) {
      Freezestatus = "Freeze";
      this._Freezecustomers = true;
    }
    else {
      Freezestatus = "UnFreeze";
      this._Freezecustomers = false;
    }
    this._IsProgressSpinner = true;
    if (this.Ans == 0) {
      this.showconfirm('Are you sure you want to ' + Freezestatus + ' FullRights ?', () => {
      }, null)
      return
    }
    this._HeaderService.UpdateStatus(this._Freezecustomers).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
        window.location.reload();
      }
    });
  }

  showconfirm(message: string, yesHandler: any, NoHandler?: any) {
    this.Freezebutton.showdialog = true;
    this.Freezebutton.message = message;
    this.Freezebutton.Yes = () => {
      if (typeof yesHandler === 'function')
        yesHandler();
      this.Freezebutton.showdialog = false;
      // this.confirm1('')
    };
    this.Freezebutton.Cancel = () => {
      if (typeof NoHandler === 'function')
        NoHandler();
      this.Freezebutton.showdialog = false;
      return
    };
  }
  Yes() {
    this.Freezebutton.showdialog = false;
    this.Ans = 1;
    this.OnbuttonChange();
  }

  Cancel() {
    this.Freezebutton.showdialog = false;
    return
  }

}
