<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3> Sales Invoice - Tax</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple title="Save" type="submit" icon="pi pi-save"
                                [hidden]="_IsSaveVisible" *ngIf="_action != 'view'" (click)="onSave()"></button>
                            <button pButton pRipple title="Clear" icon="pi pi-trash" class="p-button-danger"
                                [hidden]="_IsClearVisible" *ngIf="_action != 'view'" (click)="reset($event)"></button>
                            <button pButton pRipple title="Send Email" icon="las la-envelope"
                                [disabled]="isDisabledinvoiceslip"></button>
                            <button pButton pRipple title="Print" icon="las la-print"></button>
                            <button pButton pRipple icon="pi pi-search" title="Back to Search"
                                class="p-button p-button-success p-mr-2" (click)="goBack($event)"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-tabView [(activeIndex)]="selectedtab">
                    <p-tabPanel header="Invoice Details">
                        <form [formGroup]="_salesinvoicetaxform">
                            <table class="normal-table">
                                <tr>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" name="salesinvoiceno" disabled
                                                formControlName="salesinvoiceno" disabled pInputText>
                                            <label for="salesinvoiceno">Sales Invoice No</label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-calendar [showIcon]="true" name="salesinvoicedate" dateFormat="dd/mm/yy"
                                                [readonlyInput]="true" formControlName="salesinvoicedate"
                                                [maxDate]="_maxdate" [monthNavigator]="true" [yearNavigator]="true"
                                                yearRange="1980:2030" [disabled]="true">
                                            </p-calendar>
                                            <label for="salesinvoicedate">Invoice Date </label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <p-dropdown [autoDisplayFirst]="false" [options]="_Customernames"
                                                    optionLabel="customername" optionValue="customerid" [filter]="true"
                                                    filterBy="customername" formControlName="customerid"
                                                    [virtualScroll]="true" itemSize="15"
                                                    (onChange)="GetCustomer($event)">
                                                </p-dropdown>
                                                <label for="customerid"> Customer Name <span
                                                        class="hlt-txt">*</span></label>
                                            </span>
                                        </div>
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _salesinvoicetaxform.get('customerid').touched) && _salesinvoicetaxform.get('customerid').errors?.SelectCustomerName">
                                            Please Select Customer Name
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" name="mobileno" disabled formControlName="mobileno"
                                                pInputText (keypress)="keypress.kpMobileNumber($event)" maxlength="20">
                                            <label for="mobileno">Mobile No</label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" name="mailid" disabled formControlName="mailid"
                                                pInputText>
                                            <label for="mailid">Email Id</label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Salesreferences"
                                                optionLabel="metasubdescription" optionValue="metasubcode"
                                                formControlName="salesreference" [showClear]="true"
                                                (onChange)="GetReferenceNo()">
                                            </p-dropdown>
                                            <label for="salesreference">Sales Reference </label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Salesreferenceno"
                                                optionLabel="referenceno" optionValue="referenceno"
                                                formControlName="salesreferenceno" [showClear]="true"
                                                filterBy="referenceno" [filter]="true"
                                                (onChange)="GetReferenceDetails()" [hidden]="_action == 'view'">
                                            </p-dropdown>
                                            <input type="text" name="salesreferenceno" [hidden]="_action != 'view'"
                                                formControlName="salesreferenceno" disabled pInputText>
                                            <label for="salesRef"> Reference No </label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Collectingbranch"
                                                (onChange)="CheckCollectingBranchStock()" optionLabel="branchname"
                                                optionValue="branchid" formControlName="collectingbranch"></p-dropdown>
                                            <label for="collectingbranch">Collecting Branch</label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input pInputText name="referenceorderno" formControlName="referenceorderno"
                                                maxlength="20">
                                            <label for="referenceorderno">Reference order No.</label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="number" name="_customercreditlimit"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="_customercreditlimit"
                                                disabled pInputText>
                                            <label for="_customercreditlimit">Customer Credit Limit</label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Markuplevels"
                                                optionLabel="levelname" optionValue="levelid"
                                                formControlName="markuplevelid" (onChange)="GetLevel($event)"
                                                [disabled]="isDisabledlevel">
                                            </p-dropdown>
                                            <label for="markuplevelid">Markup Level</label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Currencies"
                                                optionLabel="currencyname" optionValue="currencyid"
                                                formControlName="currencyid"
                                                (onChange)="OnUserPreferredCurrencyChange()">
                                            </p-dropdown>
                                            <label for="currencyid">Currency</label>
                                        </span>
                                    </td>
                                    <td>
                                        <div class="p-formgroup-inline">
                                            <div class="p-field-checkbox">
                                                <p-checkbox formControlName="deliveryrequired"
                                                    (onChange)="OnChangeDeliverymode($event)"
                                                    label="Is Delivery Required" binary="true"></p-checkbox>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Deliverymode"
                                                optionLabel="metasubdescription" optionValue="metasubid"
                                                formControlName="deliverymode">
                                            </p-dropdown>
                                            <label for="deliverymode">Delivery Mode</label>
                                        </span>
                                    </td>
                                    <!-- <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-calendar [showIcon]="true" name="duedate" dateFormat="dd/mm/yy"
                                                [readonlyInput]="true" formControlName="duedate"
                                                yearRange="2020:2030">
                                            </p-calendar>
                                            <label for="duedate">Due Date </label>
                                        </span>
                                    </td> -->
                                    <td rowspan="2">
                                        <span class="p-float-label">
                                            <textarea rows="3" name="Remarks" pInputTextarea
                                                formControlName="remarks"></textarea>
                                            <label for="remarks">Remarks </label>
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </form>

                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-5">
                                    <h5>Product Details</h5>
                                </div>
                                <div class="col-md-7"></div>
                            </div>
                        </div>
                        <form [formGroup]="_salesinvoicetaxproductform">
                            <table class="normal-table">
                                <tr>
                                    <td>
                                        <div class="p-field-inline">
                                            <div class="p-formgroup-inline">
                                                <div class="p-field-checkbox">
                                                    <p-checkbox formControlName="isdamaged" label="Is Damaged?"
                                                        [disabled]="IsDisabledLayBuy"
                                                        (onChange)="OnChangeDamagedProduct()" binary="true">
                                                    </p-checkbox>
                                                </div>
                                            </div>

                                            <div class="p-field-control">
                                                <span class="p-float-label">
                                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Severities"
                                                        optionLabel="metasubdescription" optionValue="metasubcode"
                                                        (onChange)="OnChangeSeverity()" formControlName="severity">
                                                    </p-dropdown>
                                                    <label for="severity"> Severity <span
                                                            class="hlt-txt">*</span></label>
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" name="productbarcode" autocomplete="off"
                                                formControlName="productbarcode" pInputText
                                                (keyup.enter)="FetchProductUsingBarcode()">
                                            <label for="productbarcode">Bar code</label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;"></td>
                                    <td style="width: 20%;"></td>
                                    <td style="width: 20%;"></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <p-multiSelect [filter]="true" [options]="_ProductTypes"
                                                optionLabel="producttypename" optionValue="producttypeid"
                                                formControlName="producttypeid" displaySelectedLabel=true
                                                (onChange)="GetProductCategory($event)">
                                            </p-multiSelect>
                                            <label for="producttypeid">Product Type </label>
                                        </span>
                                        <!-- <span class="text-danger"
                                            *ngIf="(_submitted || _salesinvoicetaxproductform.get('producttypeid').touched) && _salesinvoicetaxproductform.get('producttypeid').errors?.SelectProductType">
                                            Please Select Atleast One Product Type
                                        </span> -->
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-multiSelect [filter]="true" [options]="_ProductCategories"
                                                [disabled]=" _action == 'view'" optionLabel="categoryname"
                                                optionValue="productcategoryid" [(ngModel)]="productcatid"
                                                [ngModelOptions]="{standalone: true}">
                                            </p-multiSelect>
                                            <!-- formControlName="productcategoryid" -->
                                            <label for="productcategoryid">Product Category</label>
                                        </span>

                                    </td>

                                    <td>
                                        <span class="p-float-label">

                                            <p-autoComplete formControlName="productid" [suggestions]="filteredProducts"
                                                (completeMethod)="filterProducts($event)" field="productname"
                                                [disabled]="IsDisabledLayBuy" (onSelect)="GetVariantName()">
                                                <ng-template let-product pTemplate="item">
                                                    <div>{{product.productname}}</div>
                                                </ng-template>
                                            </p-autoComplete>
                                            <label for="productid">Product Name <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted1 || _salesinvoicetaxproductform.get('productid').touched) && _salesinvoicetaxproductform.get('productid').errors?.SelectProductName">
                                            Please Select Product Name
                                        </span>
                                    </td>

                                    <!-- <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Variants"
                                                optionLabel="variantdescription" optionValue="productvariantdetailid"
                                                [disabled]="IsDisabledLayBuy" [showClear]="true"
                                                formControlName="variantid" (onChange)="GetVariants($event)">
                                            </p-dropdown>
                                            <label for="variantid">Variant </label>
                                        </span>

                                    </td> -->
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_packdetails"
                                                optionLabel="metasubdescription" optionValue="metasubcode"
                                                [showClear]="true" formControlName="packtype">
                                            </p-dropdown>
                                            <label for="packtype">Pack Name </label>
                                        </span>
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <p-inputNumber *ngIf="hidequantity == true" formControlName="quantity"
                                                autocomplete="off" maxlength="14"
                                                (keyup.enter)="GetProductPrice($event)" (keyup)="CalculateQuantity()"
                                                [disabled]="IsDisabledLayBuy" mode="decimal" [minFractionDigits]="0"
                                                [maxFractionDigits]="0"></p-inputNumber>
                                            <label *ngIf="hidequantity == true" for="quantity">Quantity </label>
                                        </span>
                                        <span class="p-float-label">
                                            <p-inputNumber *ngIf="hidequantity != true" formControlName="quantity"
                                                autocomplete="off" maxlength="14"
                                                (keyup.enter)="GetProductPrice($event)" (keyup)="CalculateQuantity()"
                                                [disabled]="IsDisabledLayBuy" mode="decimal" [minFractionDigits]="3"
                                                [maxFractionDigits]="3"></p-inputNumber>
                                            <label *ngIf="hidequantity != true" for="quantity"> Quantity <span
                                                    class="hlt-txt">*</span>
                                            </label>
                                        </span>
                                        <!-- <span class="p-float-label">
                                            <input type="text" name="quantity" formControlName="quantity"
                                                (keyup.enter)="GetProductPrice($event)" (keyup)="CalculateQuantity()"
                                                [disabled]="IsDisabledLayBuy" pInputText autocomplete="off">
                                            <label for="quantity">Quantity <span class="hlt-txt">*</span></label>
                                        </span> -->
                                        <span class="text-danger"
                                            *ngIf="(_submitted1 || _salesinvoicetaxproductform.get('quantity').touched) && _salesinvoicetaxproductform.get('quantity').errors?.required">
                                            Please Enter Quantity
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="text" name="stockinhand" formControlName="stockinhand" disabled
                                                pInputText>
                                            <label for="stockinhand">Remaining Stock </label>
                                        </span>
                                    </td>
                                </tr>

                                <tr>

                                    <td>
                                        <button pButton type="button" pRipple label="Add" icon="pi pi-plus"
                                            class="p-button-success" *ngIf="_action != 'view'"
                                            [disabled]="IsDisabledLayBuy" (click)="GetProductPrice($event)"></button>
                                        <button pButton type="button" pRipple label="Clear" icon="pi pi-trash"
                                            class="p-button-danger" *ngIf="_action != 'view'"
                                            [disabled]="IsDisabledLayBuy" (click)="Clear($event)"></button>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </table>
                        </form>

                        <div class="card">
                            <p-table [value]="_ProductDetails" [rows]="10" [paginator]="true"
                                [rowsPerPageOptions]="[5,10,25,50,100]"
                                [globalFilterFields]="['productname','variantdescription']" [rowHover]="true"
                                dataKey="billno"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true" [(selection)]="selectedProductDetailsRows">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="text-align: center;width:100px;" class="text-center">Action</th>
                                        <th>Barcode Number</th>
                                        <th>Pack Type</th>
                                        <th>Product Type</th>
                                        <th>Product Name</th>
                                        <!-- <th>Variant</th> -->
                                        <th>pack Qty</th>
                                        <th>Per Pack</th>
                                        <th>Qty</th>
                                        <th>UOM</th>
                                        <th>Unit Price</th>
                                        <th>Price</th>
                                        <!-- <th>Default Discount %</th> -->
                                        <th>Request Discount %</th>
                                        <!-- <th>Approved Discount %</th> -->
                                        <th>Tax %</th>
                                        <!-- <th>Default Discount Amount</th> -->
                                        <th>Request Discount Amount</th>
                                        <!-- <th>Approved Discount Amount</th> -->
                                        <th>Tax Amount</th>
                                        <th>Final Amount</th>
                                        <th>Is Scheme Applied</th>
                                        <!-- <th>Remarks</th> -->
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_ProductDetail>
                                    <tr>
                                        <td style="text-align:center">
                                            <button pButton pRipple title="Remove" icon="pi pi-trash"
                                                class="p-button-danger p-mr-2"
                                                [disabled]="IsDisabledLayBuy||_action == 'view'"
                                                (click)="RemoveProduct(_ProductDetail)"></button>
                                        </td>
                                        <td>
                                            {{_ProductDetail.barcode}}

                                        </td>
                                        <td>
                                            {{_ProductDetail.packtypename}}
                                        </td>
                                        <td>
                                            {{_ProductDetail.producttype}}

                                        </td>
                                        <td>
                                            {{_ProductDetail.productname}}

                                        </td>
                                        <!-- <td>
                                            {{_ProductDetail.variantdescription}}

                                        </td> -->
                                        <td>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="text" [(ngModel)]="_ProductDetail.quantity"
                                                        [disabled]="_ProductDetail.disableFreeProductFeild"
                                                        (keyup)="allocatequantity(_ProductDetail)"
                                                        (focusout)="allocatequantity(_ProductDetail)" pInputText
                                                        *ngIf="_action != 'view'">

                                                    <div *ngIf="_action == 'view'">{{_ProductDetail.quantity}}</div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_ProductDetail.quantity}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td>
                                            {{_ProductDetail.quantityperpack}}
                                        </td>
                                        <td>
                                            {{_ProductDetail.stockqty}}
                                        </td>
                                        <td>
                                            {{_ProductDetail.uomname}}

                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="text" [(ngModel)]="_ProductDetail.unitprice"
                                                        (keydown.enter)="OnChangeUnitPrice(_ProductDetail)"
                                                        (keyup.tab)="OnChangeUnitPrice(_ProductDetail)"
                                                        (focusout)="OnChangeUnitPrice(_ProductDetail)" pInputText
                                                        *ngIf="_action != 'view'">
                                                    <div *ngIf="_action == 'view'">{{_ProductDetail.unitprice | number:
                                                        '1.2'}}
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_ProductDetail.unitprice | number: '1.2'}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td>
                                            {{_ProductDetail.productamount | number: '1.2'}}

                                        </td>
                                        <!-- <td>

                                            {{_ProductDetail.discountpercentage}}

                                        </td> -->
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="text"
                                                        [(ngModel)]="_ProductDetail.customerdiscountpercentage"
                                                        [disabled]="IsDisableRequestDiscount || IsDisabledLayBuy || _ProductDetail.disableFreeProductFeild || _action != 'create'"
                                                        (keyup)="allocatequantity(_ProductDetail)" pInputText
                                                        *ngIf="_action != 'view'">
                                                    <div *ngIf="_action == 'view'">
                                                        {{_ProductDetail.customerdiscountpercentage}}</div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_ProductDetail.customerdiscountpercentage}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <!-- <td>
                                            {{_ProductDetail.approveddiscountpercentage}}
                                        </td> -->
                                        <td>
                                            {{_ProductDetail.taxpercentage}}
                                        </td>
                                        <!-- <td>
                                            {{_ProductDetail.discountamount | number: '1.2' }}
                                        </td> -->
                                        <td>
                                            {{_ProductDetail.customerdiscountamount | number: '1.2' }}
                                        </td>
                                        <!-- <td>
                                            {{_ProductDetail.approveddiscountamount | number: '1.3'}}
                                        </td> -->

                                        <td>
                                            {{_ProductDetail.taxamount | number: '1.2'}}

                                        </td>
                                        <td>
                                            {{_ProductDetail.finalamount | number: '1.2'}}

                                        </td>
                                        <td>
                                            <label [hidden]="_ProductDetail.iscshemeapplied">No</label>
                                            <label [hidden]="!_ProductDetail.iscshemeapplied">Yes</label>
                                        </td>
                                        <!-- <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="text" [(ngModel)]="_ProductDetail.remarks" pInputText
                                                        [disabled]="_ProductDetail.disableFreeProductFeild"
                                                        *ngIf="_action != 'view'">
                                                    <div *ngIf="_action == 'view'">{{_ProductDetail.remarks}}</div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_ProductDetail.remarks}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td> -->
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                        <!-- For Product Type Electronics -->
                        <p-dialog header="Electrical Product Type" [(visible)]="displayBasicProductType" modal="true"
                            class="popup-new" [style]="{width: '500px'}">
                            <p>Please Advise the Customer to Check Electrical Products Before Leaving the Premises</p>
                            <ng-template pTemplate="footer">
                                <p-button icon="pi pi-check"
                                    (click)="CloseProductTypeDialog(false,Salesinvoiceresultjson,salesproductprice)"
                                    label="OK" styleClass="p-button-text"></p-button>
                            </ng-template>
                        </p-dialog>
                        <!-- For Scheme -->
                        <p-dialog header="Scheme" [(visible)]="dispalyBasicScheme" modal="true" class="popup-new"
                            [style]="{width: '500px'}">
                            <ul *ngFor="let sr of schemeRemarks" class="bullet01">
                                <li><i class="las la-gift"></i>{{sr}}</li>
                            </ul>
                            <!-- <p>{{schemeRemarks}}</p> -->
                            <ng-template pTemplate="footer">
                                <p-button icon="pi pi-check"
                                    (click)="CloseProductTypeDialog(false,Salesinvoiceresultjson,salesproductprice)"
                                    label="Proceed With Current Qty" styleClass="p-button-text"></p-button>

                                <p-button icon="las la-pen" (click)="modifyqty()" label="Modify Qty"
                                    styleClass="p-button-text"></p-button>
                            </ng-template>
                        </p-dialog>
                        <!-- For pop Up -->
                        <p-dialog header="Customers" [(visible)]="ManageDialog"
                            [style]="{width: '350px',height:'400px'}" [maximizable]="false">
                            <ng-template pTemplate="content">
                                <form [formGroup]="_customerform" (ngSubmit)="Save()">
                                    <table class="normal-table">
                                        <tr>
                                            <td>
                                                <span class="p-float-label">
                                                    <input type="text" name="customername"
                                                        formControlName="customername" pInputText>

                                                    <label for="customername">Customer Name <span
                                                            class="hlt-txt">*</span></label>
                                                </span>
                                                <span class="text-danger"
                                                    *ngIf="(_customersubmitted || _customerform.get('customername').touched) && _customerform.get('customername').errors?.CustomerNameRequired">
                                                    Please Enter Customer Name
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="p-float-label">
                                                    <input type="text" name="vatno" formControlName="vatno" pInputText>
                                                    <label for="vatno">VAT No. <span class="hlt-txt">*</span></label>
                                                </span>
                                                <span class="text-danger"
                                                    *ngIf="(_customersubmitted || _customerform.get('vatno').touched) && _customerform.get('vatno').errors?.CustomerNameRequired">
                                                    Please Enter Vat No
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="p-float-label">
                                                    <input type="text" name="mobileno" formControlName="mobileno"
                                                        pInputText (keypress)="keypress.kpMobileNumber($event)"
                                                        maxlength="20">

                                                    <label for="mobileno">Phone Number <span
                                                            class="hlt-txt">*</span></label>
                                                </span>
                                                <span class="text-danger"
                                                    *ngIf="(_customersubmitted || _customerform.get('mobileno').touched) && _customerform.get('mobileno').errors?.MobileNumberRequired">
                                                    Please Enter Phone Number
                                                </span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <span class="p-float-label">
                                                    <input type="text" name="mailid" formControlName="mailid"
                                                        pInputText>

                                                    <label for="mailid">Email <span class="hlt-txt">*</span></label>
                                                </span>
                                                <span class="text-danger"
                                                    *ngIf="(_customersubmitted || _customerform.get('mailid').touched) && _customerform.get('mailid').errors?.EmailIDRequired">
                                                    Please Enter Email
                                                </span>
                                            </td>

                                        </tr>

                                        <tr>
                                            <td>
                                                <span class="p-float-label">
                                                    <input type="text" name="address" formControlName="address"
                                                        pInputText>
                                                    <label for="address"> Address <span class="hlt-txt">*</span></label>
                                                </span>
                                                <span class="text-danger"
                                                    *ngIf="(_customersubmitted || _customerform.get('address').touched) && _customerform.get('address').errors?.AddressRequired">
                                                    Please Enter Address
                                                </span>
                                            </td>

                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="p-float-label">
                                                    <p-dropdown [options]="_customertype" [autoDisplayFirst]="false"
                                                        name="customertype" optionLabel="metasubdescription"
                                                        optionValue="metasubcode" formControlName="customertype">
                                                    </p-dropdown>
                                                    <label for="customertype"> Customer Type <span
                                                            class="hlt-txt">*</span></label>
                                                </span>
                                                <span class="text-danger"
                                                    *ngIf="(_customersubmitted || _customerform.get('customertype').touched) && _customerform.get('customertype').errors?.CustomerNameRequired">
                                                    Please choose Customer Type
                                                </span>
                                            </td>
                                        </tr>

                                    </table>
                                </form>
                            </ng-template>

                            <ng-template pTemplate="footer">

                                <button pButton pRipple label="Save" (click)="Save()" icon="las la-check"
                                    class="p-button-text"></button>
                                <button pButton pRipple label="Cancel" icon="pi pi-times" (click)="Cancel()"
                                    class="p-button-text"></button>
                            </ng-template>

                        </p-dialog>

                        <table>
                            <td>
                                <!-- Debug -->
                                <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                            </td>
                        </table>
                    </p-tabPanel>
                    <p-tabPanel header="Payables" [hidden]="_IsFormHide">
                        <table>
                            <tr>
                                <td style="width:60%;vertical-align:top;" colspan="3">
                                    <form [formGroup]="_salesinvoicetaxform">
                                        <table class="normal-table">
                                            <tr>
                                                <td style="width:34%">
                                                    <span class="p-float-label">
                                                        <p-multiSelect [filter]="false" [options]="_advancereferences"
                                                            optionLabel="refNo" optionValue="refNo"
                                                            [virtualScroll]="true" itemSize="30" [filter]="true"
                                                            filterBy="voucherno"
                                                            (onChange)="OnChangeAdvanceReferences()"
                                                            [(ngModel)]="_advances"
                                                            [ngModelOptions]="{standalone: true}"
                                                            displaySelectedLabel="true"
                                                            [disabled]="_IsdisabledAdvanceRef || IsDisabledLayBuy  || _action != 'create'">
                                                        </p-multiSelect>
                                                        <label>Advance References</label>
                                                    </span>
                                                    <!-- </form> -->
                                                </td>
                                                <td style="width:33%">
                                                    <span class="p-float-label">
                                                        <input type="number" name="customeradvanceamount" disabled
                                                            formControlName="customeradvanceamount" pInputText>
                                                        <label for="customeradvanceamount">Advance Amount</label>
                                                    </span>
                                                </td>
                                                <td style="width:33%"></td>
                                            </tr>
                                            <tr>
                                                <td style="width:34%">
                                                    <div class="p-formgroup-inline">
                                                        <div class="p-field-checkbox">
                                                            <p-checkbox formControlName="applyrewardpoints"
                                                                (onChange)="OnChangeRewardPointApplicable($event)"
                                                                label="Apply Reward Points" binary="true"></p-checkbox>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style="width:33%">
                                                    <span class="p-float-label">
                                                        <input type="number" name="applicablerewardpoints"
                                                            formControlName="applicablerewardpoints"
                                                            [disabled]="_isDisabledpoints"
                                                            (onBlur)="allocateRewardPoints($event)"
                                                            (keyup.enter)="allocateRewardPoints($event)" pInputText>
                                                        <label for="applicablerewardpoints">Applicable Points</label>

                                                        <!--[attr.disabled]="_salesinvoicetaxform.get('applyrewardpoints').value == true"  -->
                                                    </span>
                                                </td>
                                                <td style="width:33%">
                                                    <span class="p-float-label">
                                                        <input type="number" name="remainingpoints"
                                                            formControlName="remainingpoints" disabled pInputText>
                                                        <label for="remainingpoints">Remaining Points</label>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="p-formgroup-inline">
                                                        <div class="p-field-checkbox">
                                                            <p-checkbox formControlName="creditnoteapplicable"
                                                                [disabled]="IsDisabledLayBuy" label="Apply Credit Note"
                                                                binary="true"
                                                                (onChange)="OnChangeCreditNoteApplicable()"
                                                                (onChange)="GetCreditNoteDetails()">
                                                            </p-checkbox>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <!-- <form [formGroup]="_salesinvoicetaxform"> -->
                                                    <!-- <span class="p-float-label">
                                                        <input type="text" name="creditnoteno"
                                                            formControlName="creditnoteno" pInputText>
                                                        <label for="creditnoteno">Credit Note No</label>
                                                    </span> -->
                                                    <span class="p-float-label">
                                                        <p-multiSelect [filter]="false" [options]="_CreditNoteRefernces"
                                                            optionLabel="voucherno" optionValue="creditnoteid"
                                                            [(ngModel)]="_creditnoteids" formControlName="creditnoteid"
                                                            displaySelectedLabel="true"
                                                            (onChange)="OnChangeCreditNote($event)"
                                                            [disabled]="!_salesinvoicetaxform.get('creditnoteapplicable').value || _action != 'create'">
                                                        </p-multiSelect>
                                                        <label for="creditnoteid">Credit Notes <span
                                                                class="hlt-txt">*</span></label>
                                                    </span>
                                                    <span class="text-danger"
                                                        *ngIf="_salesinvoicetaxform.get('creditnoteapplicable').value && (_submitted || _salesinvoicetaxform.get('creditnoteid').touched) && _salesinvoicetaxform.get('creditnoteid').errors?.SelectProductType">
                                                        Please Select Atleast One Credit Note
                                                    </span>
                                                    <!-- </form> -->
                                                </td>
                                                <td>
                                                    <span class="p-float-label">
                                                        <input type="number" name="creditnoteamount" disabled
                                                            formControlName="creditnoteamount" pInputText>
                                                        <label for="creditnoteamount">Credit Note Amount</label>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span class="p-float-label">
                                                        <input type="number" name="productamount"
                                                            formControlName="productamount" disabled pInputText>
                                                        <label for="productamount">Gross Amount</label>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span class="p-float-label">
                                                        <input type="number" name="taxamount"
                                                            formControlName="taxamount" disabled pInputText>
                                                        <label for="taxamount">Tax Amount</label>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span class="p-float-label">
                                                        <input type="number" name="otherchargesamount"
                                                            formControlName="otherchargesamount" disabled pInputText>
                                                        <label for="otherchargesamount">Other Deduction Amount</label>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span class="p-float-label">
                                                        <input type="number" name="amountinusd"
                                                            formControlName="amountinusd" disabled pInputText>
                                                        <label for="amountinusd">Net Amount in USD</label>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span class="p-float-label">
                                                        <input type="text" name="purchaseamount"
                                                            formControlName="purchaseamount" disabled pInputText>
                                                        <label for="purchaseamount">Purchase Amount</label>
                                                    </span>
                                                </td>
                                                <td>
                                                    <!-- [hidden]="Profit" -->
                                                    <span class="p-float-label" [hidden]="showpprofit">
                                                        <input type="text" name="profitamount"
                                                            formControlName="profitamount" disabled pInputText>
                                                        <label for="profitamount">Profit Amount</label>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span class="p-float-label">
                                                        <input type="number" name="netamountincustomercurrency"
                                                            formControlName="netamountincustomercurrency" disabled
                                                            pInputText>
                                                        <label for="netamountincustomercurrency">Net Amount in Customer
                                                            Currency</label>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span class="p-float-label">
                                                        <p-dropdown [autoDisplayFirst]="false" [options]="_Salesby"
                                                            optionLabel="username" optionValue="userid" filter="true"
                                                            filterBy="username" [showClear]="true"
                                                            formControlName="salesby">
                                                        </p-dropdown>
                                                        <label for="productid">Sales By <span
                                                                class="hlt-txt">*</span></label>
                                                    </span>
                                                    <span class="text-danger"
                                                        *ngIf="(_submitted || _salesinvoicetaxform.get('salesby').touched) && _salesinvoicetaxform.get('salesby').errors?.SelectProductType">
                                                        Please Select Sales by
                                                    </span>
                                                </td>
                                                <td>
                                                    <span class="p-float-label">
                                                        <input type="number" name="_customercreditlimit"
                                                            [ngModelOptions]="{standalone: true}"
                                                            [(ngModel)]="_customercreditlimit" disabled pInputText>
                                                        <label for="_customercreditlimit">Customer Credit Limit</label>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span class="p-float-label">
                                                        <input type="number" name="paidamount" disabled
                                                            formControlName="paidamount" pInputText>
                                                        <label for="paidamount">Customer Paid Amount</label>
                                                    </span>
                                                    <!-- <span class="text-danger"
                                                        *ngIf="(_submitted || _salesinvoicetaxform.get('paidamount').touched) && _salesinvoicetaxform.get('paidamount').errors?.SelectProductType">
                                                        Customer Paid amount Required.
                                                    </span> -->
                                                </td>
                                                <td>
                                                    <span class="p-float-label">
                                                        <input type="number" name="customerbalanceamount" disabled
                                                            formControlName="customerbalanceamount" pInputText>
                                                        <label for="customerbalanceamount">Customer Balance Amount
                                                        </label>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span class="p-float-label">
                                                        <input type="number" name="balanceamount"
                                                            formControlName="balanceamount" disabled pInputText>
                                                        <label for="balanceamount">Amount Payable to
                                                            Customer</label>
                                                    </span>
                                                </td>
                                                <td></td>
                                            </tr>
                                        </table>
                                    </form>
                                </td>
                                <td style="width:40%;vertical-align:top;" colspan="2">
                                    <table style="width: 100%;" class="normal-table">
                                        <tr>
                                            <td colspan="2">
                                                <div class="sub-title">
                                                    <div class="row">
                                                        <div class="col-md-5">
                                                            <h5>Other Deductions</h5>
                                                        </div>
                                                        <div class="col-md-7"></div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <p-table [value]="_OtherCharges" [rows]="10" [paginator]="true"
                                                        [rowsPerPageOptions]="[5,10,25,50,100]"
                                                        [globalFilterFields]="['otherchargesid','otherchargesamount']"
                                                        [rowHover]="true" dataKey="otherchargesid"
                                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                                        [showCurrentPageReport]="true"
                                                        [(selection)]="selectedOtherChargesRows">
                                                        <ng-template pTemplate="header">
                                        <tr>
                                            <th style="width:100px;text-align: center;">Action</th>
                                            <th>Description</th>
                                            <th>Value</th>
                                        </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-_othercharge>
                                            <tr>
                                                <td style="text-align:center">
                                                    <button pButton pRipple title="Add" icon="pi pi-plus"
                                                        [disabled]="_IsAddVisible" [disabled]="IsDisabledLayBuy"
                                                        class="p-button-success p-mr-2"
                                                        (click)="AddOtherCharges($event)"></button>
                                                    <button pButton pRipple title="Remove" icon="pi pi-trash"
                                                        [disabled]="IsDisabledLayBuy" [disabled]="_IsRemoveVisible"
                                                        class="p-button-danger"
                                                        (click)="RemoveOtherCharges(_othercharge)"></button>
                                                </td>
                                                <td pEditableColumn>
                                                    <p-cellEditor>
                                                        <ng-template pTemplate="input">
                                                            <p-dropdown #id [autoDisplayFirst]="false"
                                                                [options]="_Descriptions" optionLabel="otherchargename"
                                                                optionValue="otherchargeid"
                                                                [disabled]="IsDisabledLayBuy"
                                                                [(ngModel)]="_othercharge.otherchargesid"
                                                                *ngIf="_action != 'view'"
                                                                (onChange)="OnchangeOtherCharge(_othercharge,$event)">
                                                            </p-dropdown>
                                                        </ng-template>
                                                        <ng-template pTemplate="output">
                                                            <p-dropdown [disabled]="true" [autoDisplayFirst]="false"
                                                                [disabled]="IsDisabledLayBuy" [options]="_Descriptions"
                                                                optionLabel="otherchargename"
                                                                optionValue="otherchargeid"
                                                                [(ngModel)]="_othercharge.otherchargesid">
                                                            </p-dropdown>
                                                        </ng-template>
                                                    </p-cellEditor>
                                                </td>
                                                <td pEditableColumn>
                                                    <p-cellEditor>
                                                        <ng-template pTemplate="input">
                                                            <input type="number"
                                                                [(ngModel)]="_othercharge.otherchargesamount"
                                                                [disabled]="IsDisabledLayBuy" pInputText
                                                                (keyup)="Calculateothercharge(_othercharge,$event)"
                                                                *ngIf="_action != 'view'">
                                                        </ng-template>
                                                        <ng-template pTemplate="output">
                                                            {{_othercharge.otherchargesamount}}
                                                        </ng-template>
                                                    </p-cellEditor>
                                                </td>
                                            </tr>
                                        </ng-template>
                                        </p-table>
            </div>
            </td>
            </tr>
            <tr>
                <td colspan="2">
                    <div class="sub-title">
                        <div class="row">
                            <div class="col-md-5">
                                <h5>Terms & Condition</h5>
                            </div>
                            <div class="col-md-7"></div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td style="width: 50%;">
                    <span class="p-float-label">
                        <p-multiSelect [options]="_Paymentlist" optionLabel="termsdescription"
                            optionValue="termsandconditionid" [(ngModel)]="paymenttermsid" [filter]="true"
                            [disabled]="_action == 'view'" [virtualScroll]="true" itemSize="30"
                            [ngModelOptions]="{standalone: true}">
                            <ng-template let-account pTemplate="item">
                                {{account.termsdescription}}
                            </ng-template>
                        </p-multiSelect>
                        <label for="producttypeid">Payment Terms</label>
                    </span>
                </td>
                <td style="width: 50%;">
                    <span class="p-float-label">
                        <p-multiSelect [options]="_Deliverylist" optionLabel="termsdescription"
                            [disabled]="_action == 'view'" optionValue="termsandconditionid"
                            [(ngModel)]="deliverytermsid" [filter]="true" [virtualScroll]="true" itemSize="30"
                            [ngModelOptions]="{standalone: true}">
                            <ng-template let-account pTemplate="item">
                                {{account.termsdescription}}
                            </ng-template>
                        </p-multiSelect>
                        <label for="producttypeid">Delivery Terms</label>
                    </span>
                </td>
            </tr>
            <tr>
                <td style="width: 50%;">
                    <span class="p-float-label">
                        <p-multiSelect [options]="_Otherlist" optionLabel="termsdescription"
                            [disabled]="_action == 'view'" optionValue="termsandconditionid" [(ngModel)]="othertermsid"
                            [filter]="true" [virtualScroll]="true" itemSize="30" [ngModelOptions]="{standalone: true}">
                            <ng-template let-account pTemplate="item">
                                {{account.termsdescription}}
                            </ng-template>
                        </p-multiSelect>
                        <label for="producttypeid">Other Terms</label>
                    </span>
                </td>
                <td style="width: 50%;"></td>
            </tr>
            </table>
            </td>
            </tr>
            </table>
            <form [formGroup]="_salesinvoicetaxform">
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Amount Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <table class="normal-table">
                    <tr>
                        <td style="width:20%">
                            <span class="p-float-label">
                                <p-dropdown [autoDisplayFirst]="false" [options]="_Multicurrencies1"
                                    optionLabel="currencyname" optionValue="currencyid"
                                    (onChange)="onSelectCurrency($event)" formControlName="cuscurrencyid1">
                                </p-dropdown>
                                <label for="cuscurrencyid1">Currency 1</label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <input type="number" name="cuscurrencyamount1" formControlName="cuscurrencyamount1"
                                    (keyup)="CalculateCurrencyAmount1($event)"
                                    (focusout)="CalculateCurrencyAmount1($event)" pInputText>
                                <label for="cuscurrencyamount1">Customer Paid Amount 1
                                </label>
                            </span>
                        </td>
                        <td style="width:20%">
                            <span class="p-float-label">
                                <p-dropdown [autoDisplayFirst]="false" [options]="_Multicurrencies2"
                                    optionLabel="currencyname" optionValue="currencyid"
                                    (onChange)="onSelectCurrency2($event)" formControlName="cuscurrencyid2">
                                </p-dropdown>
                                <label for="cuscurrencyid2">Currency 2</label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <input type="number" name="cuscurrencyamount2" formControlName="cuscurrencyamount2"
                                    (keyup)="CalculateCurrencyAmount2($event)"
                                    (focusout)="CalculateCurrencyAmount2($event)" pInputText>
                                <label for="cuscurrencyamount2">Customer Paid Amount 2
                                </label>
                            </span>
                        </td>
                        <td style="width: 20%;"></td>
                    </tr>
                </table>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Return Amount details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <table class="normal-table">
                    <tr>
                        <td style="width:25%">
                            <span class="p-float-label">
                                <p-dropdown [autoDisplayFirst]="false" [options]="_Currencies"
                                    optionLabel="currencyname" optionValue="currencyid" [filter]="true"
                                    filterBy="currencyname" [showClear]="true" (onChange)="ChangeReturnCurrency()"
                                    formControlName="returncurrencyid">
                                </p-dropdown>
                                <label for="returncurrencyid">Return Currency<span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 25%;">
                            <span class="p-float-label">
                                <input type="number" name="returnamount" formControlName="returnamount" disabled
                                    pInputText>
                                <label for="returnamount">Return Amount
                                </label>
                            </span>
                        </td>
                        <td style="width: 25%;">
                            <span class="p-float-label">
                                <input type="number" name="creditnotebalanceamount"
                                    [ngClass]="{'hlt-color': _salesinvoicetaxform.get('creditnotebalanceamount').value > 0 }"
                                    formControlName="creditnotebalanceamount" disabled pInputText>
                                <label for="creditnotebalanceamount">Creditnote Balance Amount
                                </label>
                            </span>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </form>
            <div class="sub-title">
                <div class="row">
                    <div class="col-md-5">
                        <h5>Payment Details</h5>
                    </div>
                    <div class="col-md-7"></div>
                </div>
            </div>
            <form [formGroup]="_salesinvoicetaxform">
                <table class="normal-table">
                    <tr>
                        <td style="width:20%">
                            <div class="p-formgroup-inline">
                                <h6>Payment Mode</h6>
                                <div class="p-field-radiobutton" *ngFor="let PM of _PaymentModes">
                                    <p-radioButton label={{PM.metasubdescription}} value={{PM.metasubcode}}
                                        formControlName="paymenttype" (click)="OnChangePaymode()">
                                    </p-radioButton>
                                </div>
                            </div>
                        </td>
                        <td style="width:20%">
                            <span class="p-float-label">
                                <p-dropdown #id [autoDisplayFirst]="false" [options]="_toaccounts" optionLabel="name"
                                    optionValue="ledgerid" formControlName="bankledgerid">
                                </p-dropdown>
                                <label for="bankledgerid">Bank Accounts</label>
                            </span>
                        </td>
                        <td>
                            <span class="p-float-label">
                                <input type="text" name="paymentreferenceno" formControlName="paymentreferenceno"
                                    pInputText>
                                <label for="paymentreferenceno">Reference No</label>
                            </span>
                        </td>
                        <td style="width:20%"></td>
                        <td style="width:20%"></td>

                    </tr>
                </table>
            </form>
            </p-tabPanel>
            </p-tabView>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            <p-dialog #warning [style]="{width: '400px'}" header="Warning" [(visible)]="creditnotemodel.showdialog"
                [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="false">
                <ng-template pTemplate="content">
                    <label [innerHTML]="creditnotemodel.message"></label>
                </ng-template>
                <ng-template pTemplate="footer">
                    <button type="button" pButton icon="las la-check" label="Yes" autofocus
                        (click)="creditnotemodel.Yes()"></button>
                    <button type="button" pButton icon="pi pi-times" label="No" autofocus
                        (click)="creditnotemodel.Cancel()"></button>
                </ng-template>
            </p-dialog>
            <p-dialog header="Reward Details" [(visible)]="dispalyBasicReward" modal="true" class="popup-new"
                [style]="{width: '800px',height:'500px'}">
                <div class="row">
                    <div class="col-md-6">
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-6">
                                    <h5>Earned Reward Points</h5>
                                </div>
                                <div class="col-md-6 text-right">
                                    <h3>Total : {{totalearningpoints| number : '1.2'}}</h3>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <p-table #dtBranchWiseStocks [value]="earnedrewarddetails" selectionMode="single"
                                [rows]="15" [paginator]="true" [rowsPerPageOptions]="[15,25,50]"
                                [globalFilterFields]="['branchname','closingstock']" [rowHover]="true"
                                dataKey="branchid">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="earneddate">Earned Date
                                            <p-sortIcon field="earneddate">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="earnedpoints" style="width: 120px;">Earned Points
                                            <p-sortIcon field="earnedpoints">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="expiredate" style="width: 120px;">Expire Date
                                            <p-sortIcon field="expiredate">
                                            </p-sortIcon>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_earnedpoint>
                                    <tr>
                                        <td>
                                            {{_earnedpoint.earneddate | date:'dd-MMM-yyyy'}}
                                        </td>
                                        <td class="text-right">
                                            {{_earnedpoint.earnedpoints| number : '1.2'}}
                                        </td>
                                        <td>
                                            {{_earnedpoint.expiredate | date:'dd-MMM-yyyy'}}
                                        </td>

                                    </tr>
                                </ng-template>
                                <!-- <ng-template pTemplate="footer">
                                            <tr>
                                                <td colspan="2" class="text-right"></td>
                
                                            </tr>
                                        </ng-template> -->
                            </p-table>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-6">
                                    <h5>Used Reward Points</h5>
                                </div>
                                <div class="col-md-6  text-right">
                                    <h3>Total : {{totalusedpoints| number : '1.2'}}</h3>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <p-table #dtProductCategoryWiseStocks [value]="usedrewarddetails" [rows]="15"
                                [paginator]="true" [rowsPerPageOptions]="[15,25,50]"
                                [globalFilterFields]="['productcategoryname','closingstock']" [rowHover]="true"
                                dataKey="productcategoryid">
                                <ng-template pTemplate="header">
                                    <tr>

                                        <th pSortableColumn="useddate">Used Date
                                            <p-sortIcon field="useddate">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="usedpoints" style="width: 120px;">Used Points
                                            <p-sortIcon field="usedpoints">
                                            </p-sortIcon>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-_usedpoints let-i="rowIndex">
                                    <tr>
                                        <td>
                                            {{_usedpoints.useddate | date:'dd-MMM-yyyy'}}
                                        </td>
                                        <td class="text-right">
                                            {{_usedpoints.usedpoints| number : '1.2'}}
                                        </td>
                                    </tr>
                                </ng-template>
                                <!-- <ng-template pTemplate="footer">
                                            <tr>
                                              
                                                <td colspan="2" class="text-right"></td>
                
                                            </tr>
                                        </ng-template> -->
                            </p-table>
                        </div>


                    </div>
                    <div class="col-md-12 text-right">
                        <h5>Total Remaining Points : {{totalremainingpoints| number : '1.2'}}</h5>
                    </div>
                </div>

            </p-dialog>
        </div>
    </div>
</div>
</div>
<p-toast position="bottom-right"></p-toast>